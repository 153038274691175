import React from 'react';
import { appContext } from '../../../../AppContext';
import { DateTime, Interval } from 'luxon';
import { RouteComponentProps } from 'react-router';
import { Day, ScheduleComponent, ResourcesDirective, ResourceDirective, ViewsDirective, ViewDirective, Inject, GroupModel, EventRenderedArgs, PopupOpenEventArgs, SelectEventArgs, ResourceDetails } from '@syncfusion/ej2-react-schedule';
import "./ScheduleView.scss";
import { Box, Typography, Grid, SvgIcon } from '@mui/material';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import SpaceFilterDialog, { ISpaceFilter } from '../../../../Components/Dialogs/SpaceFilterDialog/SpaceFilterDialog';
import TablePagination from '@mui/material/TablePagination';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import Helper, { getBuildingNameUsingBuildingId } from '../../../../Common/Helper';
import { createPortal } from 'react-dom';
import BookingCriteria from './BookingCriteria';
import "./ScheduleStyles.scss";
import { ODataQuery } from '../../../../Providers.Api/ODataQuery';
import { Space } from '../../../../Providers.Api/Spaces/SpaceRepository';
import { ReactComponent as NoSpaceScheduleViewIcon } from './NoSpacesScheduleView.svg'
import { DateHelper } from '../../../../Common/DateHelper';
import { IUserPreferences } from '../../../../Providers.Api/UserPreferences/UserPreferenceRepository';
import { IFloor, ISearchConfigItem, PagedResponse } from '../../../../Providers.Api/Models';
import { ISpaceLayout } from '../../../Shared/Bookings/Edit/EditBooking';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import IbssIconButton from '../../../../Components/Buttons/IconButton/IbssIconButton';
import SpaceInfoDialogWithCustomInfo from '../../../../Components/Dialogs/SpaceInfoDialogWithCustomInfo/SpaceInfoDialogWithCustomInfo';
import { Booking, BuildingBookingPolicy, IBookingPolicy } from './DataModels';
import LoadingOverlay from '../../../../Components/Navigation/LoadingOverlay/LoadingOverlay';
import { BookingSlotHelper } from '../../../../Common/BookingSlotHelper';
import { QueryParams } from './QueryParams';
import { Constants } from '../../../../Common/Constants';
import { StringHelper } from '../../../../Common/StringHelper';
import IbssToolTip from '../../../../Components/Miscellaneous/Tooltip/IbssToolTip';

class ViewBookingsSchedule extends IbssPage<IProps, IState, QueryParams>
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get api() { return appContext().apiClient; }
    private get apiCache() { return appContext().apiCache; }
    private get bookingService() { return appContext().bookingService; }
    private helper = new Helper()
    private scheduleRef: React.RefObject<ScheduleComponent>;
    private get local() { return appContext().localStorageProvider; }
    private userPreferences = {} as IUserPreferences;
    private bookingSlotHelper = new BookingSlotHelper();
    private string = new StringHelper();

    private floors: IFloor[] = [];
    private workTypes: ISearchConfigItem[] = [];
    private spaceTypes: ISearchConfigItem[] = [];

    constructor(props: IProps)
    {
        super(props, new QueryParams());
        this.scheduleRef = React.createRef<ScheduleComponent>();
        this.resourceHeaderTemplate = this.resourceHeaderTemplate.bind(this);
        this.state =
        {
            bookings: [],
            buildingId: 0,
            floor: 'Any',
            floorName: this.labels.HubLabelAny,
            linkedSpacesIds: [],
            spaceType: 'Any',
            spaceWorkType: 'Any',
            capacity: '2',
            hasCatering: false,
            hasEquipment: false,
            zone: null,
            startTime: DateTime.now(),
            spaces: [],
            buildingStartHrs: '08:00',
            buildingEndHrs: '20:00',
            openFilterModal: false,
            spacePageIndex: 0,
            showButton: false,
            selectedCell: null,
            showCreateBooking: false,
            selectedSlot: null,
            selectedBooking: undefined,
            isLoading: false,
            sortSpacesOrder: ISortSpaces.Ascending,
            showSpaceInfoDialog: false,
            selectedSpaceId: '',
            bookingsSkipToken: '',
            highestViewedSpacePageIndex: 0,
            bookingPolicies: [],
        };
    }

    public componentDidMount(): void
    {
        this.userPreferences = this.local.getUserPreferences();
    }

    public async queryParamsDidUpdate(firstLoad: boolean, prevParams: QueryParams): Promise<void>
    {
        const queryParams = this.queryParams;
        const buildingId = queryParams.building;
        const lower = this.string.toConcatenatedLowercase.bind(this.string);

        if (!buildingId)
        {
            return this.pushQueryParams({ building: this.appState.buildingId }, true);
        }

        await this.setStateAsync({ buildingId: buildingId });
        if (firstLoad || queryParams.building !== prevParams.building)
        {
            this.floors = Helper.getFloorsByBuildingId(buildingId);
            this.workTypes = Helper.getWorkSpaceTypesByNodeId(buildingId);
            this.spaceTypes = Helper.getSpaceTypesByNodeId(buildingId).result;
        }

        const floor = this.floors.find(i => queryParams.floor && lower(i.Node_Name) == lower(queryParams.floor));
        const workType = this.workTypes.find(i => queryParams.workType && lower(i.Name) == lower(queryParams.workType));
        const spaceType = this.spaceTypes.find(i => queryParams.spaceType && lower(i.Name) == lower(queryParams.spaceType));
        const defaultFloor = this.defaultFloor;
        const defaultWorkType = this.defaultWorkType;
        const defaultSpaceType = this.defaultSpaceType;

        if (!queryParams.date)
        {
            return this.pushQueryParams({ date: DateHelper.today(buildingId) }, true);
        }
        else if (!floor && queryParams.floor != 'Any')
        {
            return this.pushQueryParams({ floor: defaultFloor?.Node_Name || 'Any' }, true);
        }
        else if (!workType && queryParams.workType != 'Any')
        {
            return this.pushQueryParams({ workType: defaultWorkType?.Name || 'Any' }, true);
        }
        else if (!spaceType && queryParams.spaceType != 'Any')
        {
            return this.pushQueryParams({ spaceType: defaultSpaceType?.Name || 'Any' }, true);
        }
        else if (workType && spaceType)
        {
            return this.pushQueryParams({ spaceType: 'Any' }, true);
        }
        else if ((queryParams.capacity || 0) < 2)
        {
            return this.pushQueryParams({ capacity: 2 }, true);
        }

        await this.setStateAsync({
            startTime: queryParams.date,
            floor: floor?.Node_Id.toString() || 'Any',
            floorName: floor?.Node_Name || this.labels.HubLabelAny,
            spaceWorkType: workType?.Name || 'Any',
            spaceType: spaceType?.Name || 'Any',
            capacity: (queryParams.capacity || 2).toString(),
            hasCatering: queryParams.hasCatering || false,
            hasEquipment: queryParams.hasEquipment || false,
            openFilterModal: false,
        });

        await this.refreshSpacesAndBookings();
        await this.getBuildingBookingPolicies();
    }

    private get defaultFloor(): IFloor | null
    {
        const floors = this.floors.sort((a, b) => a.Node_Name.localeCompare(b.Node_Name));
        const building = this.userPreferences.Nodes.find(building => building.NodeId === this.state.buildingId);
        const defaultFloorId = building?.DefaultFloor;
        const defaultFloor = floors.find(i => defaultFloorId && i.Node_Id == defaultFloorId) ?? floors[0];
        return defaultFloor ?? null;
    }

    private get defaultWorkType(): ISearchConfigItem | null
    {
        const workTypes = this.workTypes.sort((a, b) => a.Name.localeCompare(b.Name));
        const defaultWorkType = workTypes.find(i => i.Name === 'FormalMeeting') ?? workTypes[0];
        return defaultWorkType ?? null;
    }

    private get defaultSpaceType(): ISearchConfigItem | null
    {
        const spaceTypes = this.spaceTypes.sort((a, b) => a.Name.localeCompare(b.Name));
        const defaultSpaceType = spaceTypes.find(i => i.Name === 'MeetingRoom') ?? spaceTypes[0];
        return defaultSpaceType ?? null;
    }

    private async loadCachedSpaces(): Promise<void>
    {
        try
        {
            const response = await this.apiCache.getSpacesByBuilding(this.state.buildingId);
            const spaceView = response.map(i => SpaceView.fromSpace(i));
            const filteredSpaceView = this.filterByInputs(spaceView).sort((a, b) => this.compareSpaceNames(a, b)); // sort spaces by Ascending Order on loading cached spaces.
            await this.setStateAsync({
                spaces: filteredSpaceView,
                spacePageIndex: 0,
            });
        }
        catch
        {
            return;
        }
    }

    private async loadLinkedSpaces(): Promise<void>
    {
        // spaces cache does not have linked spaces ids.
        // this function acts on spaces, parse space.spaceLayout, writes the spaceIds into a linkedSpacesIds state.
        const spaceLayouts = this.state.spaces
            .filter(space => space.spaceLayout !== "")
            .flatMap(space => 
            {
                try
                {
                    const layouts: ISpaceLayout[] = JSON.parse(space.spaceLayout)?.Layouts ?? [];
                    if (layouts.every(layout => this.isSpaceLayoutType(layout)))
                    {
                        return layouts;
                    }
                    else
                    {
                        return null;
                    }

                }
                catch
                {
                    return null;
                }
            }
            ).filter(space => space !== null && space.Space_Id.includes(';')) as Array<ISpaceLayout>;

        const linkedSpaceIds = spaceLayouts.map(j => j.Space_Id);
        const uniqueLinkedSpaceIds = new Set(linkedSpaceIds);
        await this.setStateAsync({ linkedSpacesIds: Array.from(uniqueLinkedSpaceIds) });
    }

    private isSpaceLayoutType(obj: any): obj is ISpaceLayout
    {
        // the parsed JSON's type begins as any, this function puts some type guard on the returned obj.
        return "Name" in obj && "Space_Id" in obj && typeof obj['Name'] === 'string' && typeof obj['Space_Id'] === 'string';
    }

    private filterByInputs(spaces: SpaceView[]): SpaceView[]
    {
        const { capacity, floor, hasCatering, hasEquipment, spaceType, spaceWorkType } = this.state;

        const filteredSpaces = spaces.filter(space =>
        {

            return this.isEqualOrAny((space.nodeId).toString(), floor)
                && this.isEqualOrAny(space.spaceType, spaceType)
                && this.isEqualOrAny(space.spaceWorkType, spaceWorkType)
                && space.spaceCapacity >= parseInt(capacity)
                && (hasCatering ? !!space.metaServReqsCatering : true) // if user specifies space needs catering, test metaServReqsCatering value,
                && (hasEquipment ? (!!space.metaServReqsAV || !!space.metaServReqsHearing || !!space.metaServReqsPresentation) : true)
                && this.metaBookableIs1or3or4or5(space.metaBookable)
        });

        return filteredSpaces
    }

    private isEqualOrAny(a: string, b: string)
    {
        // function to return true if both strings are equal or if any of them are "any" after text transform to lowercase.
        return (a === b || (a.toLowerCase() === "any" || b.toLowerCase() === "any"))
    }

    private metaBookableIs1or3or4or5(spaceMetabookable: number)
    {
        return [1, 3, 4, 5].includes(spaceMetabookable);
    }


    private compareSpaceNames(a: SpaceView, b: SpaceView): number
    {
        const spaceNameA = a.spaceName.toLocaleLowerCase();
        const spaceNameB = b.spaceName.toLocaleLowerCase();

        if (spaceNameA < spaceNameB)
        {
            return this.state.sortSpacesOrder === ISortSpaces.Ascending ? -1 : 1;
        }
        if (spaceNameA > spaceNameB)
        {
            return this.state.sortSpacesOrder === ISortSpaces.Ascending ? 1 : -1;
        }
        return 0;
    }

    public handleResetFilters(): void
    {
        this.pushQueryParams({
            floor: undefined,
            workType: undefined,
            spaceType: undefined,
            capacity: undefined,
            hasCatering: undefined,
            hasEquipment: undefined,
        });
    }

    private linkedSpaceIdIncludesSpaceId(linkeSpaceId: string, spaceIds: string[]): boolean
    {
        return spaceIds.some(spaceId => linkeSpaceId.split(';').includes(spaceId));
    }

    private async loadBookings(): Promise<void>
    {
        await this.setStateAsync({ isLoading: true, bookingsSkipToken: '', spacePageIndex: 0, highestViewedSpacePageIndex: 0, bookings: [] });

        // keep startTime in local time, and when calculating the start and end of date for API interaction, convert to new building timezone, whilst keeping local time and dates. e.g. 15th of feb in the uk would return 15th of feb in Sydney
        const startOfTodayWithZone = this.state.startTime.startOf('day').toUtcByNode(this.state.buildingId);
        const endOfTodayWithZone = this.state.startTime.endOf('day').toUtcByNode(this.state.buildingId);

        try
        {
            const visibleSpaceIds = [...this.state.spaces.slice(0, (this.state.spacePageIndex + 1) * 10).map(i => i.spaceId)];
            const spaceIds = [...visibleSpaceIds, ...this.state.linkedSpacesIds.filter(id => this.linkedSpaceIdIncludesSpaceId(id, visibleSpaceIds))];
            if (spaceIds.length > 0)
            {
                const spaceIdsFilter = spaceIds.length > 0 ? `and (${spaceIds.map(x => `Space_Id eq '${x}'`).join(' or ')})` : ''
                let bookingsFetched = false
                while (this.state.bookingsSkipToken != '' || !bookingsFetched)
                {
                    const endpoint = {
                        name: 'ibssApiClientV2.v2.byNodeid.bookings.get',
                        options: {
                            nodeId: this.state.buildingId,
                            top: 50,
                            skipToken: this.state.bookingsSkipToken,
                            filter: `Booking_Start ge datetime'${startOfTodayWithZone}' and Booking_End lt datetime'${endOfTodayWithZone}' and Booking_Status ne 'Auto Cancelled' and Booking_Status ne 'Cancelled'  ${spaceIdsFilter}`,
                            select: Booking
                        }
                    };
                    const bookings = await appContext().inMemoryCache.lazyGetWithQuickExpiry(
                        JSON.stringify(endpoint),
                        () => appContext().ibssApiClientV2.v2.byNodeid.bookings.get<PagedResponse<Booking[]>>(endpoint.options)
                    );

                    const bookingsView = bookings.value.map(i => BookingView.fromBooking(i));

                    await this.setStateAsync({ bookings: this.state.bookings.concat(bookingsView), bookingsSkipToken: bookings.skipToken });
                    bookingsFetched = true
                }
            }
            this.setState({ bookingsSkipToken: '', isLoading: false });
        }
        catch (error)
        {
            this.setState({ isLoading: false });
            return;
        }
    }

    private async loadMoreBookings(): Promise<void>
    {
        // load more bookings when more spaces are loaded.
        this.setState({ isLoading: true });
        // keep startTime in local time, and when calculating the start and end of date for API interaction, convert to new building timezone, whilst keeping local time and dates. e.g. 15th of feb in the uk would return 15th of feb in Sydney
        const startOfTodayWithZone = this.state.startTime.startOf('day').toUtcByNode(this.state.buildingId);
        const endOfTodayWithZone = this.state.startTime.endOf('day').toUtcByNode(this.state.buildingId);

        // load the bookings for the next batch of spaceIds, append the returned bookings to existing bookings.
        try
        {
            const visibleSpaceIds = [...this.state.spaces.slice(this.state.spacePageIndex * 10, (this.state.spacePageIndex + 1) * 10).map(i => i.spaceId)];
            const spaceIds = [...visibleSpaceIds, ...this.state.linkedSpacesIds.filter(id => this.linkedSpaceIdIncludesSpaceId(id, visibleSpaceIds))];

            if (spaceIds.length > 0)
            {
                const spaceIdsFilter = spaceIds.length > 0 ? `and (${spaceIds.map(x => `Space_Id eq '${x}'`).join(' or ')})` : ''
                let bookingsFetched = false
                while (this.state.bookingsSkipToken != '' || !bookingsFetched)
                {
                    const endpoint = {
                        name: 'ibssApiClientV2.v2.byNodeid.bookings.get',
                        options: {
                            nodeId: this.state.buildingId,
                            top: 50,
                            skipToken: this.state.bookingsSkipToken,
                            filter: `Booking_Start ge datetime'${startOfTodayWithZone}' and Booking_End lt datetime'${endOfTodayWithZone}' and Booking_Status ne 'Auto Cancelled' and Booking_Status ne 'Cancelled' ${spaceIdsFilter}`,
                            select: Booking
                        }
                    };
                    const bookings = await appContext().inMemoryCache.lazyGetWithQuickExpiry(
                        JSON.stringify(endpoint),
                        () => appContext().ibssApiClientV2.v2.byNodeid.bookings.get<PagedResponse<Booking[]>>(endpoint.options)
                    );

                    const bookingsView = bookings.value.map(i => BookingView.fromBooking(i));

                    await this.setStateAsync({ bookings: this.state.bookings.concat(bookingsView), bookingsSkipToken: bookings.skipToken });
                    bookingsFetched = true
                }
            }
            this.setState({ bookingsSkipToken: '', isLoading: false });

        }
        catch
        {
            this.setState({ isLoading: false });
            return;
        }
    }

    private async refreshSpacesAndBookings(): Promise<void>
    {
        // get spaces, bookings and building hours data
        const buildingName = getBuildingNameUsingBuildingId(this.state.buildingId);
        this.pageTitle = this.labels.HubLabelBookings + ' - ' + buildingName;
        this.setState({ isLoading: true });
        await this.loadCachedSpaces();
        await this.loadLinkedSpaces();
        await this.loadBookings();
        await this.getBuildingHours();
        this.setState({ isLoading: false });
    }

    private transformBookings(): IScheduleDate[]
    {
        const scheduleDates = this.state.bookings.flatMap(booking =>
        {
            const scheduleDatesForBooking = booking.spaceId.split(';').map(spaceId =>
            {
                const scheduleDate: IScheduleDate =
                {
                    // Id, Subject, StartTime & EndTime fields (default action event data field names) cannot be written as camelCase strings without first mapping camelCase field names to ScheduleComponent.eventSettings.fields
                    // e.g. fields: { subject: { title: 'subject', name: 'subject'}}, make sure value of name property matches the camelCase field name.
                    id: booking.bookingId,
                    subject: booking.bookingName,
                    bookingOwnerEmail: booking.bookingOwnerEmail,
                    startTime: booking.bookingStart.toJSDate(),
                    endTime: booking.bookingEnd.toJSDate(),
                    bookingIsActive: booking.bookingIsActive,
                    bookingIsApproved: booking.bookingIsApproved,
                    bookingStatus: booking.bookingStatus,
                    spaceId: spaceId,
                    categoryColor: this.processBookingColour(booking),
                    cancelledBookingsInSameSpaceTimeSlot: this.cancelledBookingsInSpaceAndTimeSlot(booking.spaceId, booking.bookingStart.toISO(), booking.bookingEnd.toISO()), // when showing cancelled bookings alongside non-cancelled bookings.
                }
                return scheduleDate;
            });

            return scheduleDatesForBooking;
        });

        return scheduleDates;
    }

    private groupData: GroupModel = {
        // group bookings by spaces - in ResourceDirective with name="Spaces", matching on SpaceId field of bookings transformed by transformBookings() and id field of ResourceDirective's datasource.
        resources: ['Spaces']
    }

    private debounceTimer?: NodeJS.Timeout;
    private handleDateChange(newValue: DateTime | null): void
    {
        clearTimeout(this.debounceTimer);
        if (!newValue || !newValue.isValid)
        {
            return;
        }
        this.debounceTimer = setTimeout(() => this.pushQueryParams({ date: newValue }), Constants.longDebounceTimeInMilliseconds);
    }

    private async getBuildingHours(): Promise<void> 
    {
        // get a building's start and stop hours.
        const rootNode = this.local.getNodeData();
        const building = this.helper.getBuildingById(rootNode, this.state.buildingId);

        this.setStateAsync({
            buildingStartHrs: building?.Occ_Office_hrs_stt.slice(0, -3) ?? '07:00',
            buildingEndHrs: building?.Occ_Office_hrs_stp.slice(0, -3) ?? '19:00',
        });
    }

    private async handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): Promise<void>
    {
        if (newPage > this.state.highestViewedSpacePageIndex) //only need to fetch data on page that hasnt been visited
        {
            await this.setStateAsync({ spacePageIndex: newPage, highestViewedSpacePageIndex: newPage });
            await this.loadMoreBookings();
        }
        else
        {
            await this.setStateAsync({ spacePageIndex: newPage });
        }
    }

    private cancelledBookingsInSpaceAndTimeSlot(spaceId: string, startTime: string, endTime: string): number
    {
        // need to count cancelled bookings in space and time
        // startTime and endTime are from a specific BookingView object. They are string type.
        const bookingIntervalX = Interval.fromDateTimes(DateTime.fromISO(startTime), DateTime.fromISO(endTime));

        const countOfBookings = this.state.bookings.filter(i =>
        {
            const bookingIntervalY = Interval.fromDateTimes(i.bookingStart, i.bookingEnd);
            return (i.bookingStatus === "Cancelled" || i.bookingStatus === "Auto Cancelled") && i.spaceId === spaceId && bookingIntervalX.overlaps(bookingIntervalY);
        }).length;

        return countOfBookings;
    }

    private handleEventRendered(args: EventRenderedArgs): void
    {
        // before bookings are rendered on the scheduler, apply some styles to the bookings, but primarily to assign borderColor to the CategoryColor in the bookings data.

        // background color for Cancelled bookings is solid grey
        const backgroundColour = (args.data.bookingStatus === 'Cancelled' || args.data.bookingStatus === 'Auto Cancelled') ? '#DCE1E5' : 'transparent';
        // opacity for Cancelled bookings is less than 1.
        const opacity = (args.data.bookingStatus === 'Cancelled' || args.data.bookingStatus === 'Auto Cancelled') ? '0.6' : '1';
        // maxWidth for all Cancelled bookings combined is 70% of overall cell width. the appointment is inside a table, the number of cells per row is equal to the number of bookings in slot, regardless of whether tye are cacelled or not
        const maxWidth = (args.data.bookingStatus === 'Cancelled' || args.data.bookingStatus === 'Auto Cancelled') ? `${70 / args.data.cancelledBookingsInSameSpaceTimeSlot}%` : '100%';

        (args.element as HTMLElement).style.color = '#263238'; // this is equivalent to var(ui--text), change to this var if syncfusion scheduler has dark mode.
        (args.element as HTMLElement).style.backgroundColor = backgroundColour;
        (args.element as HTMLElement).style.borderColor = args.data.categoryColor;
        (args.element as HTMLElement).style.borderRadius = '5px';
        (args.element as HTMLElement).style.borderLeftWidth = 'thick';
        (args.element as HTMLElement).style.opacity = opacity;
        (args.element as HTMLElement).style.maxWidth = maxWidth;
    }

    private processBookingColour(booking: BookingView): string
    {
        if (booking.bookingStatus === 'Cancelled' || booking.bookingStatus === 'Auto Cancelled')
        {
            return '#DCE1E5'; // this is equivalent to uiMidTone.,
        }
        else if (booking.bookingStatus === 'Completed')
        {
            return 'grey';
        }
        else if (booking.bookingStatus === 'No Show')
        {
            return 'blue';
        }
        else if (booking.bookingStatus === 'In Progress')
        {
            return 'yellow';
        }
        else if (booking.bookingIsApproved === 0)
        {
            // new booking pending approval has Booking_IsApproved value of 0.
            return 'red';
        }
        else if (booking.bookingIsApproved === 3 || booking.bookingIsApproved === 4)
        {
            return 'green';
        }
        else
        {
            return 'black';
        }
    }

    private handlePopupOpen(args: PopupOpenEventArgs): void
    {
        // querySelector is onPopupOpen instead of onSelect because it reliably selects cells by className
        const element = document.querySelector(`.e-work-cells.e-work-hours.e-selected-cell`);
        this.setState({ selectedCell: element });
        if (args.data)
        {
            const { spaceId, startTime, endTime } = args.data;
            this.setState({
                selectedSlot: {
                    spaceId: spaceId,
                    startTime: startTime,
                    endTime: endTime,
                }
            });
        }

        // disables ALL popups
        args.cancel = true;

    }

    private handleSelectSchedule(args: SelectEventArgs): void
    {
        if (args.requestType === "eventSelect")
        {
            if (args?.data && !(args.data instanceof Array))
            {
                this.setState({
                    showCreateBooking: !this.state.showCreateBooking,
                    selectedBooking: this.state.bookings.find(i => 
                    {
                        const data = args.data as IScheduleDate;
                        return i.bookingId === data.id
                    }
                    )
                });
            }
        }
        else if (args.requestType === "mousemove")
        {
            return;
        }
        else
        {
            this.setState({ selectedBooking: undefined });
        }
    }

    public get filterText(): string
    {
        const spaceWorkTypeLabel = this.workTypes.find(i => i.Name === this.state.spaceWorkType)?.Label ?? this.labels.HubLabelAny;
        const spaceTypeLabel = this.spaceTypes.find(i => i.Name === this.state.spaceType)?.Label ?? this.labels.HubLabelAny;

        return `${this.labels.HubLabelworkType}: ${this.state.spaceWorkType === 'Any' ? this.labels.HubLabelAny : spaceWorkTypeLabel} | ` +
            `${this.labels.HubLabelSpaceType}: ${this.state.spaceType === 'Any' ? this.labels.HubLabelAny : spaceTypeLabel} | ` +
            `${this.labels.HubLabelFloor}: ${this.state.floor === 'Any' ? this.labels.HubLabelAny : this.state.floorName} | ` +
            `${this.labels.HubLabelCapacity}: ${this.state.capacity === '' ? this.labels.HubLabelAny : this.state.capacity} | ` +
            `${this.labels.HubLabelCateringTable}: ${this.state.hasCatering ? 'Has Catering' : this.labels.HubLabelAny} | ` +
            `${this.labels.funcScheduleViewResources_S}: ${this.state.hasEquipment ? 'Has Equipment' : this.labels.HubLabelAny}`;
    }

    private handleFilterSubmit(filter: ISpaceFilter): void
    {
        this.pushQueryParams({
            floor: filter.floor?.name || 'Any',
            workType: filter.workType?.name || 'Any',
            spaceType: filter.spaceType?.name || 'Any',
            capacity: filter.minCapacity || undefined,
            hasCatering: filter.requiresCatering || undefined,
            hasEquipment: filter.requiresEquipment || undefined,
        });
    }

    private async handleSpaceInfoClick(spaceId: string): Promise<void>
    {
        await this.setState({ selectedSpaceId: spaceId, showSpaceInfoDialog: true });
    }

    private resourceHeaderTemplate(spaceDetails: ResourceDetails): JSX.Element
    {
        return (
            <Box display="flex" flexDirection="column" alignItems='center'>
                <IbssToolTip
                    arrow={true}
                    title={spaceDetails.resourceData.Name}
                    placement='bottom'
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -14],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <div>{spaceDetails.resourceData.Name}</div>
                </IbssToolTip>
                <span> {`${this.labels.HubLabelCapacity}: ${spaceDetails.resourceData.Capacity}`} </span>
                <IbssIconButton
                    aria-label="info"
                    sx={{ padding: 0, color: '#6e6f77' }}
                    onClick={() => this.handleSpaceInfoClick(spaceDetails.resourceData.Id)}>
                    <SvgIcon fontSize={'small'} component={InfoIcon} color='inherit'></SvgIcon>
                </IbssIconButton>
            </Box>
        );
    }

    private async getBuildingBookingPolicies(): Promise<void>
    {
        const endpoint = {
            name: 'ibssApiClientV2.v2.byNodeid.bookingpolicies.get',
            options: {
                nodeId: this.state.buildingId,
                top: 200,
                select: BuildingBookingPolicy,
            }
        };
        const policies = await appContext().inMemoryCache.lazyGetWithQuickExpiry(
            JSON.stringify(endpoint),
            () => appContext().ibssApiClientV2.v2.byNodeid.bookingpolicies.get<BuildingBookingPolicy[]>(endpoint.options)
        );

        if (policies && policies.length > 0) 
        {
            const policy: IBookingPolicy[] = policies.map(policy => ({
                Node_Id: policy.Node_Id,
                Booking_Policy_Id: policy.Booking_Policy_Id,
                Booking_Policy_Name: policy.Booking_Policy_Name,
                Booking_Policy: {
                    PolicyType: policy.Booking_Policy.PolicyType,
                    BuildingId: policy.Booking_Policy.BuildingId,
                    FloorId: policy.Booking_Policy.FloorId,
                    Allowed_TimeRange: policy.Booking_Policy.Allowed_TimeRange,
                    Booking_Policy_Description: policy.Booking_Policy.Booking_Policy_Description,
                    BookingSlots: {
                        ExcludedDates: policy.Booking_Policy.BookingSlots.ExcludedDates,
                    },
                },
            }));

            this.setState({ bookingPolicies: policy });
        }
    }

    public render(): JSX.Element
    {
        return (
            <>
                <div className="page-height-exct-header">
                    <div className="rightPanel-main-content">
                        <div className="table-panel">
                            <>
                                {this.state.selectedCell && createPortal(
                                    <IbssButton
                                        variant="contained"
                                        style={{ justifyContent: "flex-end" }}
                                        fullWidth
                                        onClick={() => this.setState({ showCreateBooking: !this.state.showCreateBooking })}
                                    >
                                        {`+`}
                                    </IbssButton>,
                                    this.state.selectedCell
                                )}
                            </>
                            <BookingCriteria
                                open={this.state.showCreateBooking}
                                setOpen={(isOpen: boolean) => this.setState({ showCreateBooking: isOpen })}
                                buildingId={this.state.buildingId}
                                getBookings={() => this.loadBookings()}
                                layoutStyle={'scheduleView'}
                                unsetSelectedCell={() => this.setState({ selectedCell: null })}
                                selectedBooking={this.state.selectedBooking}
                                clearSelectedBooking={() => this.setState({ selectedBooking: undefined })}
                                selectedSlot={this.state.selectedSlot}
                                selectedSpace={this.state.spaces.filter(space => space.spaceId === this.state?.selectedSlot?.spaceId ?? '').map(space => ({
                                    spaceId: space.spaceId,
                                    floorId: space.nodeId,
                                    spaceName: space.spaceName,
                                    spaceType: space.spaceType,
                                    spaceWorkType: space.spaceWorkType,
                                    capacity: space.spaceCapacity.toString(),
                                    zone: space.metaLocZone,
                                    cateringReqs: space.metaServReqsCatering,
                                    presentationAidReqs: space.metaServReqsPresentation,
                                    hearingAidReqs: space.metaServReqsHearing,
                                    requiresAV: space.metaServReqsAV,
                                    spaceLayout: space.spaceLayout,
                                    spaceSetup: space.spaceSetup,
                                    spaceTypeLabel: space.spaceTypeLabel,
                                    bookingPolicyId: space.bookingPolicyId,
                                    meetingLinkAvailable: space.meetingLinkAvailable
                                }))}
                            />
                            <SpaceFilterDialog
                                open={this.state.openFilterModal}
                                buildingId={this.state.buildingId}
                                floorId={parseInt(this.state.floor.replace('Any', '0'))}
                                workTypeId={this.state.spaceWorkType}
                                spaceTypeId={this.state.spaceType}
                                minCapacity={parseInt(this.state.capacity)}
                                requiresCatering={this.state.hasCatering}
                                requiresEquipment={this.state.hasEquipment}
                                hideAudioVisual={true}
                                hidePresentationAids={true}
                                hideHearingAids={true}
                                onClose={() => this.setState({ openFilterModal: false })}
                                onSubmit={i => this.handleFilterSubmit(i)}
                                lowestMinCapacity={2}
                                swapSpace={false}
                            />
                            <Grid container rowSpacing={0} sx={{ display: 'flex', alignItems: 'center', mt: 0, ml: 0 }}>
                                <Grid item md={6} sx={{ pt: 0 }} >
                                    <Box className="table-panel-header" component="div" sx={{ ml: '0', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: "100%" }}> {this.labels.HubLabelGridScheduleView}</Box>
                                </Grid>
                                <Grid item md={12} sx={{ pt: 0 }} >
                                    <Typography sx={{ fontFamily: "Source Sans Pro", fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
                                        {this.labels.HubMessageScheduleView}
                                    </Typography>
                                </Grid>
                                <Grid item md={6} sx={{ pt: 0 }} >

                                </Grid>
                                <Grid item md={12} sx={{ pt: 1.5 }} >
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}>
                                        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={DateTime.now().getLocale()}>
                                            <DesktopDatePicker
                                                value={this.state.startTime}
                                                onChange={newValue => this.handleDateChange(newValue)}
                                                slotProps={{ textField: { error: false, size: 'small' } }}
                                                disabled={this.state.isLoading}
                                            />
                                        </LocalizationProvider>
                                        <IbssButton
                                            variant={'contained'}
                                            onClick={() => this.setState({ openFilterModal: !this.state.openFilterModal })}
                                            disabled={this.state.isLoading}
                                        >
                                            {this.labels.HubButtonSpaceTypeFilter}
                                        </IbssButton>
                                    </Box>
                                </Grid>
                                <Grid item md={12} sx={{ pt: 1, pb: 1 }} >
                                    <Typography display={'inline'} variant="h6" sx={{ fontFamily: "Source Sans Pro", fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>
                                        {`${this.labels.HubLabelFiltersApplied}: `}
                                    </Typography>
                                    <Typography display={'inline'} sx={{ fontFamily: "Source Sans Pro", fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>
                                        {this.filterText}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {this.state.spaces.length > 0 &&
                                <>
                                    <ScheduleComponent
                                        className='oneLensScheduleView'
                                        currentView="Day"
                                        height='62vh'
                                        width='100%'
                                        ref={this.scheduleRef}
                                        // show time line indicator of current time only if browser timezone is equal to building timezone.
                                        showTimeIndicator={Intl.DateTimeFormat().resolvedOptions().timeZone === DateHelper.getZoneByNode(this.state.buildingId)}
                                        eventSettings=
                                        {{
                                            dataSource: this.transformBookings(),
                                            fields:
                                            {
                                                id: { title: 'id', name: 'id' },
                                                subject: { title: 'subject', name: 'subject' },
                                                startTime: { title: 'start time', name: 'startTime' },
                                                endTime: { title: 'end time', name: 'endTime' },
                                            }
                                        }}
                                        group={this.groupData}
                                        showHeaderBar={false}
                                        startHour={`${this.state.buildingStartHrs.split(':')[0]}:00`} //ensure the start hour is a whole hour rather than 07:15.
                                        endHour={this.state.buildingEndHrs}
                                        workHours={{ highlight: true, start: this.state.buildingStartHrs, end: this.state.buildingEndHrs }}
                                        eventRendered={args => this.handleEventRendered(args)}
                                        selectedDate={this.state.startTime.toJSDate()}
                                        popupOpen={args => this.handlePopupOpen(args)}
                                        select={args => this.handleSelectSchedule(args)}
                                        readonly={this.bookingSlotHelper.disableExcludedDates(this.state.startTime, this.state.bookingPolicies, parseInt(this.state.floor) ?? 0)}
                                        resourceHeaderTemplate={this.resourceHeaderTemplate}
                                    >
                                        {
                                            this.state.isLoading &&
                                            <LoadingOverlay />
                                        }
                                        <ResourcesDirective>
                                            <ResourceDirective
                                                field='spaceId' // has to match to a field of the data passed to ScheduleComponent eventSettings DataSource.  
                                                title='Space Id' // string displayed when one clicks on the booking
                                                name='Spaces' // match to a string in the arrya of groupData.resources
                                                allowMultiple={true}
                                                idField='Id'
                                                textField='Name'
                                                colorField='Color'
                                                dataSource={this.state.spaces.slice(this.state.spacePageIndex * 10, (this.state.spacePageIndex + 1) * 10).map(room => ({ Id: room.spaceId, Name: room.spaceName, Capacity: room.spaceCapacity }))}
                                            />
                                        </ResourcesDirective>
                                        <Inject services={[Day]} />
                                        <ViewsDirective>
                                            <ViewDirective option='Day' />
                                        </ViewsDirective>
                                    </ScheduleComponent>
                                    <TablePagination
                                        component="div"
                                        count={this.state.spaces.length}
                                        page={this.state.spacePageIndex}
                                        onPageChange={(event, page) => this.handleChangePage(event, page)}
                                        rowsPerPage={10}
                                        rowsPerPageOptions={[10]}
                                        sx={theme => ({ borderStyle: 'solid', borderWidth: '0px 1px 1px 1px', borderColor: theme.palette.grey[400] })}
                                        slotProps={{ actions: { nextButton: { disabled: this.state.isLoading || parseInt((this.state.spaces.length / 10).toFixed(0)) <= this.state.spacePageIndex + 1 }, previousButton: { disabled: this.state.isLoading || this.state.spacePageIndex == 0 } } }}
                                    />
                                </>
                            }
                            <SpaceInfoDialogWithCustomInfo
                                isOpen={this.state.showSpaceInfoDialog}
                                onClose={() => this.setState({ showSpaceInfoDialog: false })}
                                spaceId={this.state.selectedSpaceId}
                                nodeId={this.state.spaces.find(space => space.spaceId === this.state.selectedSpaceId)?.nodeId ?? 0}
                                buildingId={this.state.buildingId}
                            />
                            {
                                this.state.spaces.length === 0 && this.state.isLoading === false &&
                                <Box sx={{ height: '62vh', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <NoSpaceScheduleViewIcon />
                                    <Typography variant="h5" sx={{ mt: 4, mb: 2, fontFamily: "Source Sans Pro", fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>
                                        {this.labels.funcScheduleNoSpaces_L}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "Source Sans Pro", fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>
                                        {this.labels.funcScheduleNoSpaces_D}
                                    </Typography>
                                    <IbssButton
                                        variant="contained"
                                        size='large'
                                        sx={{ fontFamily: "Source Sans Pro", mb: 3, mt: 3 }}
                                        onClick={() => this.setState({ openFilterModal: true })}
                                    >
                                        {this.labels.funcScheduleReturnToFilters_S}
                                    </IbssButton>
                                    <IbssButton
                                        size='large'
                                        sx={{ fontFamily: "Source Sans Pro", fontWeight: 'bold' }}
                                        onClick={() => this.handleResetFilters()}
                                    >
                                        {this.labels.funcScheduleResetFilters_S}
                                    </IbssButton>
                                </Box>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ViewBookingsSchedule;

export interface IProps
{
}

export interface IState
{
    bookings: BookingView[],
    buildingId: number,
    floor: string,
    floorName: string,
    linkedSpacesIds: Array<string>,
    spaceType: (string),
    spaceWorkType: (string),
    capacity: string,
    zone: (string | null),
    startTime: DateTime,
    spaces: SpaceView[],
    buildingStartHrs: string,
    buildingEndHrs: string,
    openFilterModal: boolean,
    spacePageIndex: number,

    hasCatering: boolean,
    hasEquipment: boolean,

    showButton: boolean,
    selectedCell: Element | null,

    showCreateBooking: boolean,
    selectedSlot: ISelectedSlot | null,

    selectedBooking: BookingView | undefined,
    isLoading: boolean,
    showSpaceInfoDialog: boolean;
    sortSpacesOrder: ISortSpaces,
    selectedSpaceId: string;
    bookingsSkipToken: string;
    highestViewedSpacePageIndex: number;
    bookingPolicies: IBookingPolicy[],
}

export interface ISelectedSlot
{
    spaceId: string,
    startTime: Date,
    endTime: Date,
}
export interface IScheduleDate
{
    id: string,
    subject: string,
    startTime: Date,
    endTime: Date,
    bookingIsActive: number,
    bookingIsApproved: number,
    bookingStatus: string,
    bookingOwnerEmail: string,
    spaceId: string, // singular, delimited spaceId, e.g.  spaceId: 1CC_03-NE-R005;1CC_03-SE-R006
    categoryColor: string,
    cancelledBookingsInSameSpaceTimeSlot: number, // total number of cancelled bookings in the same space and time
}

export class BookingView
{
    public nodeId = 0;
    public spaceId = "";
    public spaceName = "";
    public spaceLayout = ""
    public bookingId = "";
    public bookingName = "";
    public bookingStart = DateHelper.null();
    public bookingEarlyCheckin = DateHelper.null();
    public bookingEnd = DateHelper.null();
    public bookingStatus = "";
    public bookingIsActive = 0;
    public bookingIsApproved = 0;
    public bookingOwnerEmail = "";
    public bookingOwnerName = "";
    public bookingParties = "";
    public bookingShareLocation = 0;
    public createdAt = DateHelper.null();
    public createdBy = "";
    public bookingResources = [];

    public static fromBooking(booking: Booking): BookingView
    {
        return {
            nodeId: booking.Node_Id,
            spaceId: booking.Space_Id,
            spaceName: booking.Space_Name,
            spaceLayout: booking.Space_Layout,
            bookingId: booking.Booking_Id,
            bookingName: booking.Booking_Name,
            bookingStart: DateHelper.fromIsoByNode(booking.Booking_Start, booking.Node_Id),
            bookingEarlyCheckin: DateHelper.fromIsoByNode(booking.Booking_Early_Checkin, booking.Node_Id),
            bookingEnd: DateHelper.fromIsoByNode(booking.Booking_End, booking.Node_Id),
            bookingStatus: booking.Booking_Status,
            bookingIsActive: booking.Booking_IsActive,
            bookingIsApproved: booking.Booking_IsApproved,
            bookingOwnerEmail: booking.Booking_Owner_Email,
            bookingOwnerName: booking.Booking_Owner_Name,
            bookingParties: booking.Booking_Parties,
            bookingShareLocation: booking.Booking_Share_Loc,
            createdAt: DateHelper.fromIsoByNode(booking._CreatedAt, booking.Node_Id),
            createdBy: booking._CreatedBy,
            bookingResources: booking.Booking_Resources
        };
    }
}

export class SpaceView
{
    public nodeId = 0;
    public spaceId = "";
    public spaceName = "";
    public spaceCapacity = 0;
    public spaceClass = "";
    public spaceType = "";
    public spaceTypeLabel = "";
    public spaceLayout = "";
    public spaceSetup = 0;
    public spaceStatus = "";
    public imageURI = "";
    public bookingPolicyId = "";
    public metaBookable = 0;
    public metaOccType = 0;
    public metaLocZone = "";
    public metaServReqsCatering = 0;
    public metaServReqsAV = 0;
    public metaServReqsHearing = 0;
    public metaServReqsPresentation = 0;
    public spaceWorkType = "";
    public meetingLinkAvailable = 0;
    public spaceEnabled = 0;

    public static fromSpace(space: Space): SpaceView
    {
        return {
            nodeId: space.Node_Id,
            spaceId: space.Space_Id,
            spaceName: space.Space_Name,
            spaceCapacity: space.Space_Capacity,
            spaceClass: space.Space_Class,
            spaceType: space.Space_Type,
            spaceTypeLabel: space.Space_Type_Label,
            spaceLayout: space.Space_Layout,
            spaceSetup: space.Space_Setup,
            spaceStatus: space.Space_Status,
            imageURI: space.ImageURI,
            bookingPolicyId: space.Booking_Policy_Id,
            metaBookable: space.Meta_Bookable,
            metaOccType: space.Meta_Occ_Type,
            metaLocZone: space.Meta_Loc_Zone,
            metaServReqsCatering: space.Meta_Serv_Reqs_Presentation,
            metaServReqsAV: space.Meta_Serv_Reqs_AV,
            metaServReqsHearing: space.Meta_Serv_Reqs_Hearing,
            metaServReqsPresentation: space.Meta_Serv_Reqs_Presentation,
            spaceWorkType: space.Space_Work_Type,
            meetingLinkAvailable: space.Meta_Ext_Booking_System,
            spaceEnabled: space.Space_IsEnabled,
        };
    }
}

interface IListOption
{
    value: string;
    label: string;
}

enum ISortSpaces
{
    Ascending,
    Descending
}
