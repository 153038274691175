import { appContext } from '../../../../AppContext';
import { IFloor } from '../../../../Providers.Api/Models';
import { Box, Grid, Link, Paper, SelectChangeEvent, Typography } from '@mui/material';
import Helper from '../../../../Common/Helper';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssDatePicker from '../../../../Components/Inputs/DatePicker/IbssDatePicker';
import { DateHelper } from '../../../../Common/DateHelper';
import { DateTime } from 'luxon';
import IbssTimePicker from '../../../../Components/Inputs/TimePicker/IbssTimePicker';
import { BookingSlotHelper } from '../../../../Common/BookingSlotHelper';
import { BuildingBookingPolicy, FloorZones, IBookingPolicy } from './DataModels';
import { TimeValidationHelper } from './TimeValidationHelper';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import IbssDrawer from '../../../../Components/Drawer/IbssDrawer';
import IbssSwitchLabel, { LabelPlacementType } from '../../../../Components/Inputs/Switch/IbssSwitchLabel';
import IbssToolTip from '../../../../Components/Miscellaneous/Tooltip/IbssToolTip';
import InfoIcon from '../../../../Components/Icons/InfoIcon';

class SearchSpaceFilter extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get local() { return appContext().localStorageProvider; }
    private bookingSlotHelper = new BookingSlotHelper();

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            buildingOptions: [],
            floorOptions: [],
            workSpaceTypeOptions: [],
            spaceTypeOptions: [],
            zoneOptions: this.props.zoneOptions,
            startTimeMessage: "",
            endTimeMessage: "",
            bookingPolicies: [],
            startDateTime: this.props.startDateTime,
            endDateTime: this.props.endDateTime,
            date: this.props.startDateTime.date(),
            selectedBuilding: this.props.selectedBuilding,
            selectedFloor: this.props.selectedFloor,
            selectedZone: this.props.selectedZone,
            selectedWorkspaceType: this.props.selectedWorkspaceType,
            selectedSpaceType: this.props.selectedSpaceType,
            numberOfPeople: 1,
            audioVisual: false,
            presentationAids: false,
            hearingAids: false,
            catering: false,
            linkedSpace: false,
            layouts: false,
            openDrawer: false
        };
    }

    public async componentDidMount(): Promise<void>
    {        
        this.populateBuildings(this.state.selectedBuilding);
        this.populateWorkSpaces(this.state.selectedBuilding, this.state.selectedWorkspaceType);
        this.populateSpaceTypes(this.state.selectedBuilding, this.state.selectedSpaceType);
        this.populateFloors(this.state.selectedBuilding, this.state.selectedFloor);
        this.populateZones(this.state.selectedFloor);
        this.getBuildingBookingPolicies()
    }

    private populateBuildings(selectedValue: number): void
    {
        const buildings = Helper.getAllBuildingsData().sort((a, b) => a.Name.localeCompare(b.Name));
        const options = buildings.map(i => ({ label: i.Name, value: i.Node_Id }));
        this.setState({ buildingOptions: options, selectedBuilding: selectedValue });
    }

    private populateWorkSpaces(selectedBuildingId: number, selectedWorkSpace: string): void
    {
        const workSpaces = Helper.getWorkSpaceTypesByNodeId(selectedBuildingId);

        const options = workSpaces
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }));

        options.unshift({ label: this.labels.HubLabelAny, value: "Any" });
        this.setState({ workSpaceTypeOptions: options, selectedWorkspaceType: selectedWorkSpace });
    }

    private populateSpaceTypes(selectedBuildingId: number, selectedSpaceType: string): void
    {
        const spaceTypes = Helper.getSpaceTypesByNodeId(selectedBuildingId);

        const options = spaceTypes.result
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }))
            .sort((a, b) => a.label.localeCompare(b.label));

        options.unshift({ label: this.labels.HubLabelAny, value: "Any" });
        this.setState({ spaceTypeOptions: options, selectedSpaceType: selectedSpaceType });
    }

    private populateFloors(selectedBuildingId: number, selectedFloor: string): void
    {
        const floors: IFloor[] = Helper.getFloorsByBuildingId(selectedBuildingId);

        const options = floors
            .map(i => ({ label: i.Node_Name, value: i.Node_Id.toString() }))
            .sort((a, b) => (a.label < b.label ? - 1 : 1));

        options.unshift({ label: this.labels.HubLabelAny, value: "Any" });
        this.setState({ floorOptions: options, selectedFloor: selectedFloor });
    }

    private async populateZones(selectedFloor: string): Promise<void>
    {
        if (selectedFloor === "Any")
        {
            this.setState({ zoneOptions: [{ label: this.labels.HubLabelAny, value: "Any" }], selectedZone: "Any" });
            return;
        }

        const zonesResponse =  await appContext().ibssApiClientV1.v1.byNodeid.spaceZones.get<FloorZones[]>({
            nodeId: parseInt(selectedFloor),
            select: FloorZones
        })       
        const options = zonesResponse.map(i => ({ label: i.Meta_Loc_Zone, value: i.Meta_Loc_Zone }));

        options.unshift({ label: this.labels.HubLabelAny, value: "Any" });
        this.setState({ zoneOptions: options});
    }

    private async buildingChanged(event: SelectChangeEvent<number>): Promise<void> 
    {   
        const userPreferences = this.local.getUserPreferences();
        const selectedBuildingId = event.target.value as number;
        const spaceTypes = Helper.getSpaceTypesByNodeId(selectedBuildingId);
        const selectedSpaceType  = spaceTypes.result[0]?.Name;
        const selectedFloor = userPreferences.Nodes.find(building => building.NodeId === selectedBuildingId)?.DefaultFloor?.toString() ?? "Any";
        this.populateWorkSpaces(selectedBuildingId, "Any");
        this.populateSpaceTypes(selectedBuildingId, selectedSpaceType);
        this.populateFloors(selectedBuildingId, selectedFloor);
        this.populateZones(selectedFloor);

        this.setState({
            selectedBuilding: selectedBuildingId,
            selectedZone: "Any",
        });

    }

    private workSpaceTypeChanged(event: SelectChangeEvent): void
    {
        let spaceType = (event.target.value == "Any" ? this.state.spaceTypeOptions[1]?.value ?? "Any" : "Any");
        this.setState({ selectedWorkspaceType: event.target.value, selectedSpaceType: spaceType });
        
    }

    private spaceTypeChanged(event: SelectChangeEvent): void
    {
        let workSpaceType = (event.target.value == "Any" ? this.state.workSpaceTypeOptions[1]?.value ?? "Any" : "Any");
        this.setState({ selectedSpaceType: event.target.value, selectedWorkspaceType: workSpaceType });
    }

    private floorChanged(event: SelectChangeEvent): void
    {
        this.setState({ selectedFloor: event.target.value });
        this.populateZones(event.target.value);
    }

    private zoneChanged(event: SelectChangeEvent): void
    {
        this.setState({ selectedZone: event.target.value });
    }

    private startTimeChanged(value: DateTime | null): void
    {
        const startTime = (value ?? DateHelper.null());
        const endTime = this.state.endDateTime;
        const validator = new TimeValidationHelper();
        validator.validate(startTime, endTime, this.props.selectedBuilding, this.state.date);
        this.setState({ startTimeMessage: validator.toStartTimeMessage(), endTimeMessage: validator.toEndTimeMessage() });
        if(validator.isValid())
        {
            this.setState({ startDateTime: startTime });
        }
    }

    private endTimeChanged(value: DateTime | null): void
    {
        const startTime = this.state.startDateTime;
        const endTime = (value ?? DateHelper.null());
        const validator = new TimeValidationHelper();
        validator.validate(startTime, endTime, this.props.selectedBuilding, this.state.date);
        this.setState({ startTimeMessage: validator.toEndTimeMessage(), endTimeMessage: validator.toEndTimeMessage() });
        if(validator.isValid())
        {
            this.setState({ endDateTime: endTime });
        }
    }

    private dateChanged(date: DateTime | null): void
    {
        if(date != null)
        {
            this.setState({date: date});
        }
    }

    private linkedSpaceChanged(e: React.ChangeEvent<HTMLInputElement>): void
    {
        this.setState({ linkedSpace: e.target.checked });
        if (e.target.checked)
        {
            this.setState({ layouts: true });
        }
    }
    
    private async getBuildingBookingPolicies (): Promise<void>
    {
        const policies = await appContext().ibssApiClientV2.v2.byNodeid.bookingpolicies.get<BuildingBookingPolicy[]>({
            nodeId: this.props.selectedBuilding,
            top:200,
            select: BuildingBookingPolicy,
        })

        if (policies && policies.length > 0) 
        {
            const policy: IBookingPolicy[] = policies.map(policy => ({
                Node_Id: policy.Node_Id,
                Booking_Policy_Id: policy.Booking_Policy_Id,
                Booking_Policy_Name: policy.Booking_Policy_Name,
                Booking_Policy: {
                    PolicyType: policy.Booking_Policy.PolicyType,
                    BuildingId: policy.Booking_Policy.BuildingId,
                    FloorId: policy.Booking_Policy.FloorId,
                    Allowed_TimeRange: policy.Booking_Policy.Allowed_TimeRange,
                    Booking_Policy_Description: policy.Booking_Policy.Booking_Policy_Description,
                    BookingSlots: {
                        ExcludedDates: policy.Booking_Policy.BookingSlots.ExcludedDates,
                    },
                },
            }));

            this.setState({ bookingPolicies: policy });
        }
    }

    private createOptionsArray(values: number[])
    {
        return values.map((value: number) =>
        {
            return {
                label: value === 15 ? "15+" : value.toString(),
                value: value
            };
        });
    }

    private async updateSearchFilter():Promise<void>
    {
        const SearchFilter :ISearchFilterResult = 
        {
            startDateTime: this.state.startDateTime,
            endDateTime: this.state.endDateTime,
            date: this.state.date,
            selectedBuilding: this.state.selectedBuilding,
            selectedFloor: this.state.selectedFloor === "Any" ? null : this.state.selectedFloor,
            selectedWorkspaceType: this.state.selectedWorkspaceType === "Any" ? null : this.state.selectedWorkspaceType,
            selectedSpaceType: this.state.selectedSpaceType === "Any" ? null : this.state.selectedSpaceType,
            selectedZone: this.state.selectedZone === "Any" ? null : this.state.selectedZone,
            numberOfPeople: this.state.numberOfPeople,
            audioVisual: this.state.audioVisual,
            presentationAids: this.state.presentationAids,
            hearingAids: this.state.hearingAids,
            catering: this.state.catering,
            linkedSpace: this.state.linkedSpace,
            layouts: this.state.layouts,
        }
        await this.props.updateSearchFilter(SearchFilter)
        this.setState({ openDrawer: false });
    }

    private async clearClicked(): Promise<void>
    {
        this.setState({
            audioVisual: false,
            presentationAids: false,
            hearingAids: false,
            catering: false,
            linkedSpace: false,
            layouts: false,
        });
    }

    public render(): JSX.Element
    {
        return (
            <>
            <Grid container spacing={2} mb={2}>
                <Grid item xs={1.8}>
                    <IbssFormControl fullWidth={true} >
                        <IbssTimePicker
                            label={this.labels.HubLabelFrom}
                            value={this.state.startDateTime}
                            onChange={time => this.startTimeChanged(time)}
                            minTime={this.state.date.hasSame(DateTime.now(), 'day') ? DateTime.now() : undefined}
                            slotProps={{ textField: { error: this.state.startTimeMessage.length > 0, helperText: this.state.startTimeMessage } }}
                            minutesStep={1}
                            ampm={false}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.8}>
                    <IbssFormControl fullWidth={true} >
                        <IbssTimePicker
                            label={this.labels.HubLabelTo}
                            value={this.state.endDateTime}
                            onChange={time => this.endTimeChanged(time)}
                            minTime={this.state.startDateTime.isValid ? this.state.startDateTime : DateTime.now()}
                            slotProps={{ textField: { error: this.state.endTimeMessage.length > 0, helperText: this.state.endTimeMessage } }}
                            minutesStep={1}
                            ampm={false}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.8}>
                    <IbssFormControl fullWidth={true} >
                        <IbssDatePicker
                            label={this.labels.HubLabelDate}
                            minDate={DateHelper.now()}
                            value={this.state.startDateTime.date()}
                            onChange={event => this.dateChanged(event)}
                            shouldDisableDate={(date) => this.bookingSlotHelper.disableExcludedDates(date, this.state.bookingPolicies, parseInt(this.props.selectedFloor) ?? 0)}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={6.6}>
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <IbssButton sx={{ whiteSpace: 'nowrap' }} variant="contained" color="primary" onClick={() => this.setState({ openDrawer: true })}> {this.labels.funcAdditionalCriteria_S} </IbssButton>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={1.8}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="buildingSelection"
                            inputLabel={this.labels.HubLabelBuilding}
                            fullWidth={true}
                            options={this.state.buildingOptions}
                            value={this.state.selectedBuilding}
                            onChange={(e: SelectChangeEvent<number>) => this.buildingChanged(e)}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.8}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="floorSelection"
                            inputLabel={this.labels.HubLabelFloor}
                            fullWidth={true}
                            options={this.state.floorOptions}
                            value={this.state.selectedFloor}
                            onChange={(e: SelectChangeEvent) => this.floorChanged(e)}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.8}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="zoneSelection"
                            inputLabel={this.labels.HubLabelZone}
                            fullWidth={true}
                            options={this.state.zoneOptions}
                            value={this.state.selectedZone}
                            onChange={(e: SelectChangeEvent) => this.zoneChanged(e)}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.8}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="workTypeSelection"
                            inputLabel={this.labels.HubLabelworkType}
                            fullWidth={true}
                            options={this.state.workSpaceTypeOptions}
                            value={this.state.selectedWorkspaceType}
                            onChange={(e: SelectChangeEvent) => this.workSpaceTypeChanged(e)}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.8}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="spaceTypeSelection"
                            inputLabel={this.labels.HubLabelSpaceType}
                            fullWidth={true}
                            options={this.state.spaceTypeOptions}
                            value={this.state.selectedSpaceType}
                            onChange={(e: SelectChangeEvent) => this.spaceTypeChanged(e)}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.5}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="capacitySelection"
                            inputLabel={this.labels.HubLabelCapacity}
                            fullWidth={true}
                            options={this.createOptionsArray([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15])}
                            value={this.state.numberOfPeople}
                            onChange={(e: SelectChangeEvent<number>) => this.setState({ numberOfPeople: e.target.value as number })}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.5}>
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <IbssButton sx={{ whiteSpace: 'nowrap' }} variant="contained" color="primary" onClick={() => this.updateSearchFilter()}> {this.labels.HubLabelMySearchLabel}</IbssButton>
                    </Box>
                </Grid>
            </Grid>

            <IbssDrawer open={this.state.openDrawer} onClose={() => this.setState({ openDrawer: false })} anchor='right' className='flex-search-filter-criteria' style={{ backgroundColor: "var(--ui-background-alternate)" }}>
                <Box className="flexMySearch-filter-criteria">
                    <Box className="flexMySearch-filter-criteria-header">
                        <span className="flexMySearch-filter-criteria-icon"><img className="flexMySearch-filter-criteria-img " src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Filter-2.svg`} alt="icon" /></span>
                        <span className="flexMySearch-filter-criteria-title">{this.labels.funcAdditionalSearchCriteria_S}</span>
                        <span className="flexMySearch-filter-criteria-close" onClick={() => this.setState({ openDrawer: false })}>&times;</span>
                    </Box>
                    <Box className="flexMySearch-filter-criteria-content">
                        <fieldset>
                            <Typography variant="body2" className="flexMySearch-filter-criteria-firstLabel">
                                {this.labels.HubLabelSpaceFeatures}
                            </Typography>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Paper elevation={0}>
                                    <IbssSwitchLabel
                                        id="need-AV"
                                        label={
                                            <Typography sx={{ color: (theme) => theme.palette.text.primary }} variant='body2'>
                                                {this.labels.HubLabelNeedAudioVisual}
                                            </Typography>
                                        }
                                        checked={this.state.audioVisual}
                                        onChange={e => this.setState({ audioVisual: e.target.checked })}
                                        labelPlacement={LabelPlacementType.start}
                                    />
                                    <Box className="flexMySearch-filter-criteria-border" />
                                    <IbssSwitchLabel
                                        id="need-presentation-aids"
                                        checked={this.state.presentationAids}
                                        onChange={e => this.setState({ presentationAids: e.target.checked })}
                                        label={
                                            <Box display={"flex"} alignItems={'center'}>
                                                <Typography sx={{ color: (theme) => theme.palette.text.primary }} variant='body2'>
                                                    {this.labels.HubLabelNeedPresentationAids}
                                                </Typography>
                                                <IbssToolTip title={this.labels.funcNeedPresentationAids_D} placement="top" arrow>
                                                    <Box component="span" ml={1} mt={1}>
                                                        <InfoIcon />
                                                    </Box>
                                                </IbssToolTip>
                                            </Box>
                                        }
                                        labelPlacement={LabelPlacementType.start}
                                    />
                                    <Box className="flexMySearch-filter-criteria-border" />
                                    <IbssSwitchLabel
                                        id="need-hearing-aids"
                                        checked={this.state.hearingAids}
                                        onChange={e => this.setState({ hearingAids: e.target.checked })}
                                        label={
                                            <Box display={"flex"} alignItems={'center'}>
                                                <Typography sx={{ color: (theme) => theme.palette.text.primary }} variant='body2'>
                                                    {this.labels.HubLabelNeedHearingAids}
                                                </Typography>
                                                <IbssToolTip title={this.labels.funcNeedHearingAids_D} placement="top" arrow>
                                                    <Box component="span" ml={1} mt={1}>
                                                        <InfoIcon />
                                                    </Box>
                                                </IbssToolTip>
                                            </Box>
                                        }
                                        labelPlacement={LabelPlacementType.start}
                                    />
                                    <Box className="flexMySearch-filter-criteria-border" />
                                    <IbssSwitchLabel
                                        id="need-catering"
                                        checked={this.state.catering}
                                        onChange={e => this.setState({ catering: e.target.checked })}
                                        label={
                                            <Typography sx={{ color: (theme) => theme.palette.text.primary }} variant='body2'>
                                                {this.labels.HubLabelNeedCatering}
                                            </Typography>
                                        }
                                        labelPlacement={LabelPlacementType.start}
                                    />
                                    <Box className="flexMySearch-filter-criteria-border" />
                                    <IbssSwitchLabel
                                        id="need-linked-space"
                                        checked={this.state.linkedSpace}
                                        onChange={e => this.linkedSpaceChanged(e)}
                                        label={
                                            <Box display={"flex"} alignItems={'center'}>
                                                <Typography sx={{ color: (theme) => theme.palette.text.primary }} variant='body2'>
                                                    {this.labels.HubLabelNeedLinkedSpace}
                                                </Typography>
                                                <IbssToolTip title={this.labels.funcNeedLinkedSpace_D} placement="top" arrow>
                                                    <Box component="span" ml={1} mt={1}>
                                                        <InfoIcon />
                                                    </Box>
                                                </IbssToolTip>
                                            </Box>
                                        }
                                        labelPlacement={LabelPlacementType.start}
                                    />
                                    <Box className="flexMySearch-filter-criteria-border" />
                                    <IbssSwitchLabel
                                        id="need-layouts"
                                        checked={this.state.layouts}
                                        onChange={e => this.setState({ layouts: e.target.checked })}
                                        label={
                                            <Box display={"flex"} alignItems={'center'}>
                                                <Typography sx={{ color: (theme) => theme.palette.text.primary }} variant='body2'>
                                                    {this.labels.HubLabelNeedLayouts}
                                                </Typography>
                                                <IbssToolTip title={this.labels.funcNeedLayouts_D} placement="top" arrow>
                                                    <Box component="span" ml={1} mt={1}>
                                                        <InfoIcon />
                                                    </Box>
                                                </IbssToolTip>
                                            </Box>
                                        }
                                        labelPlacement={LabelPlacementType.start}
                                    />
                                    <Box className="flexMySearch-filter-criteria-border" />
                                </Paper>
                            </Box>
                        </fieldset>
                        <Box className="right-space-box-cont">
                            <Box className="d-flex justify-content-center">
                                <IbssButton variant="contained" color="primary" onClick={() => this.updateSearchFilter()}>{this.labels.HubButtonUpdate}</IbssButton>
                            </Box>
                            <Box className="d-flex justify-content-center">
                                <Link color="secondary" mt={2} onClick={() => this.clearClicked()}>{this.labels.HubLabelClearSelections}</Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </IbssDrawer>
            </>       
        )
    }
}
export default SearchSpaceFilter;

export interface IProps
{
    startDateTime: DateTime;
    endDateTime: DateTime;
    selectedBuilding: number;
    selectedFloor: string;
    selectedZone: string;
    selectedWorkspaceType: string;
    selectedSpaceType: string;
    numberOfPeople: number;
    presentationAids: boolean;
    hearingAids: boolean;
    catering: boolean;
    linkedSpace: boolean;
    layouts: boolean;
    av: boolean;
    lightModeTheme: boolean;
    zoneOptions: Array<IListOption<string>>;
    showSearchCriteria: () => void;
    updateSearchFilter: (result: ISearchFilterResult) => Promise<void>;
}

export interface IState
{
    buildingOptions: Array<IListOption<number>>;
    floorOptions: Array<IListOption<string>>;
    workSpaceTypeOptions: Array<IListOption<string>>;
    spaceTypeOptions: Array<IListOption<string>>;
    zoneOptions: Array<IListOption<string>>;
    startTimeMessage: string;
    endTimeMessage: string;
    bookingPolicies: IBookingPolicy[];
    startDateTime: DateTime;
    endDateTime: DateTime;
    date: DateTime;
    selectedBuilding: number;
    selectedFloor: string;
    selectedZone: string;
    selectedWorkspaceType: string;
    selectedSpaceType: string;
    numberOfPeople: number;
    audioVisual: boolean;
    presentationAids: boolean;
    hearingAids: boolean;
    catering: boolean;
    linkedSpace: boolean;
    layouts: boolean;
    openDrawer: boolean;
}

export interface IListOption<TValue>
{
    label: string,
    value: TValue,
}

export interface ISearchFilterResult
{
    startDateTime: DateTime;
    endDateTime: DateTime;
    date: DateTime;
    selectedBuilding: number;
    selectedFloor: (string | null);
    selectedZone: (string | null);
    selectedWorkspaceType: (string | null);
    selectedSpaceType: (string | null);
    numberOfPeople: number;
    audioVisual: boolean;
    presentationAids: boolean;
    hearingAids: boolean;
    catering: boolean;
    linkedSpace: boolean;
    layouts: boolean;
}