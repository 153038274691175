import { DateTime } from "luxon";
import { IBookingSlots } from "../Providers.Api/BookingPolicies/BookingPolicyRepository";
import { TimeView } from "@mui/x-date-pickers";
import { IBookingPolicy } from "../Providers.Api/Models";

export class BookingSlotHelper
{
    public static isDateUnavailable(date: DateTime, bookingSlotsPolicy: IBookingSlots): boolean
    {
        // pass in a luxon dateTime with a timezone (or else local timezone will be used). find out whether the space is available on the date according to the space's bookingPolicy

        // Slots check
        const activeSlotDays = bookingSlotsPolicy.BookableTime?.map(x => x.Days).flat(1);
        // Datetime.weekday's values range from 1 (Monday) to 7 (Sunday).
        const daySlotAvailable = activeSlotDays?.includes((date.weekday).toString());

        // check excluded dates
        let dateExcluded = false;
        const excludedDates = bookingSlotsPolicy?.ExcludedDates;
        let excludedDateIndex = 0;

        // get timezone from input date
        const timezone = date.zoneName;

        while (excludedDateIndex < excludedDates?.length)
        {
            const endOfDay = date.endOf('day');
            if (date > DateTime.fromISO(`${excludedDates[excludedDateIndex].StartDate}T${excludedDates[excludedDateIndex].StartTime}`).setZone(timezone) && endOfDay <= DateTime.fromISO(`${excludedDates[excludedDateIndex].EndDate}T${excludedDates[excludedDateIndex].EndTime}`).setZone(timezone))
            {
                dateExcluded = true;
            }
            excludedDateIndex += 1;
        }

        return !daySlotAvailable || dateExcluded;
    }

    public static getMinutesStepFromPolicy(values: string[]): number
    {
        /***
         * returns a list of minutes that the timepicker allow the user to select, based on the booking policy.
         * input is a list of minutes allowed by the booking policy
         * e.g. startIntervalMinutes: JSON.parse(bookingPolicy.Booking_Policy).BookingSlots.BookingStart.SpecificMinutes,
         * endIntervalMinutes: JSON.parse(bookingPolicy.Booking_Policy).BookingSlots.BookingEnd.SpecificMinutes
        ***/

        if (values.length === 0)
        {
            return 1; //Any
        }
        if (values[0] === '0' && values.length === 1)
        {
            return 60;
        }
        if (values[0] !== '0' && values.length > 0)
        {
            return parseInt(values[0]);
        }
        else
        {
            return parseInt(values[1]);
        }
    }

    public static isTimeUnavailable(time: Date, type: TimeView, pickerType: ('start' | 'end'), bookingDate: Date, start: Date, end: Date, bookingSlotsPolicy: IBookingSlots): boolean
    {
        //check the available slot time
        const selectedDayNumber = new Date(bookingDate).getDay() == 0 ? '7' : new Date(bookingDate).getDay().toString();
        const pickerStartDateTime = new Date(bookingDate.getFullYear(),bookingDate.getMonth(),bookingDate.getDate(),start.getHours(),time.getMinutes(),0,0);
        const pickerEndDateTime = new Date(bookingDate.getFullYear(),bookingDate.getMonth(),bookingDate.getDate(),end.getHours(),time.getMinutes(),0,0);
        
        let selectedDaySlotTimes = bookingSlotsPolicy.BookableTime?.filter(x => pickerType == 'start' ?
            x.Days.includes(selectedDayNumber) :
            x.Days.includes(selectedDayNumber) && pickerStartDateTime.getHours() >= parseInt(x.StartTime.substring(0, 2)));
        let validSlotTime = false;
        if (selectedDaySlotTimes?.length == 0)
        {
            validSlotTime = false;
        }
        else
        {
            let selectedDaySlotIndex = 0;
            while (selectedDaySlotIndex < selectedDaySlotTimes?.length)
            {
                const startTimeHours = selectedDaySlotTimes[selectedDaySlotIndex].StartTime.substring(0, 2);
                const endTimeHours = selectedDaySlotTimes[selectedDaySlotIndex].EndTime.substring(0, 2);
                const startTimeMinutes = selectedDaySlotTimes[selectedDaySlotIndex].StartTime.substring(3, 5);
                const endTimeMinutes = selectedDaySlotTimes[selectedDaySlotIndex].EndTime.substring(3, 5);
                const startTime = new Date(bookingDate.getFullYear(),bookingDate.getMonth(),bookingDate.getDate(),parseInt(startTimeHours),parseInt(startTimeMinutes),0,0);
                const endTime = new Date(bookingDate.getFullYear(),bookingDate.getMonth(),bookingDate.getDate(),parseInt(endTimeHours),parseInt(endTimeMinutes),0,0);
                if (pickerType == 'start')
                {
                    if ((type == 'hours' && (time.getHours() >= parseInt(startTimeHours)) && (time.getHours() <= parseInt(endTimeHours))))
                    {
                        validSlotTime = true;
                    }
                    if (type == 'minutes' && pickerStartDateTime >= startTime && pickerStartDateTime <= endTime)
                    {
                        validSlotTime = true;
                    }
                }
                if (pickerType == 'end')
                {
                    if ((type == 'hours' && (time.getHours() >= parseInt(startTimeHours)) && (time.getHours() <= parseInt(endTimeHours))))
                    {
                        validSlotTime = true;
                    }
                    if (type == 'minutes' && pickerType == 'end' && pickerEndDateTime <= endTime)
                    {
                        validSlotTime = true;
                    }
                }
                selectedDaySlotIndex += 1;
            }
        }
        // check the excluded date start/end boundary time
        let timeExcluded = false
        if (bookingSlotsPolicy?.ExcludedDates?.length > 0)
        {
            let excludedDateIndex = 0;
            while (excludedDateIndex < bookingSlotsPolicy?.ExcludedDates.length)
            {
                const excludedDateStart = new Date(`${bookingSlotsPolicy?.ExcludedDates[excludedDateIndex]?.StartDate}T${bookingSlotsPolicy?.ExcludedDates[excludedDateIndex]?.StartTime}`);
                const excludedDateEnd = new Date(`${bookingSlotsPolicy?.ExcludedDates[excludedDateIndex]?.EndDate}T${bookingSlotsPolicy?.ExcludedDates[excludedDateIndex]?.EndTime}`);
                const pickerDay = new Date(pickerStartDateTime.getFullYear(), pickerStartDateTime.getMonth(), pickerStartDateTime.getDate(), 0, 0, 0, 0);
                const pickerEndDay = new Date(pickerEndDateTime.getFullYear(), pickerEndDateTime.getMonth(), pickerEndDateTime.getDate(), 0, 0, 0, 0);
                const startDay = new Date(excludedDateStart.getFullYear(), excludedDateStart.getMonth(), excludedDateStart.getDate(), 0, 0, 0, 0);
                const endDay = new Date(excludedDateEnd.getFullYear(), excludedDateEnd.getMonth(), excludedDateEnd.getDate(), 0, 0, 0, 0);
                if (type == 'hours')
                {
                    if ((pickerDay.toString() == startDay.toString() && startDay.toString() == endDay.toString() && (time.getHours() > excludedDateStart.getHours() && time.getHours() < excludedDateEnd.getHours())))
                    {
                        timeExcluded = true;
                    }
                    if (startDay.toString() != endDay.toString() && (pickerDay.toString() == startDay.toString() && (time.getHours() > excludedDateStart.getHours())))
                    {
                        timeExcluded = true;
                    }
                    if (startDay.toString() != endDay.toString() && (pickerDay.toString() == endDay.toString() && (time.getHours() < excludedDateEnd.getHours())))
                    {
                        timeExcluded = true;
                    }
                }
                if (type == 'minutes')
                {
                    if ((pickerDay.toString() == startDay.toString() && startDay.toString() == endDay.toString() && excludedDateStart.getHours() == start.getHours() && time.getMinutes() > excludedDateStart.getMinutes()))
                    {
                        timeExcluded = true;
                    }
                    if ((pickerDay.toString() == startDay.toString() && startDay.toString() == endDay.toString() && excludedDateEnd.getHours() == start.getHours() && time.getMinutes() < excludedDateEnd.getMinutes()))
                    {
                        timeExcluded = true;
                    }
                    if (pickerType == 'start')
                    {
                        if (startDay.toString() != endDay.toString() && pickerDay.toString() == startDay.toString() && (time.getMinutes() > excludedDateStart.getMinutes()) && excludedDateStart.getHours() == start.getHours())
                        {
                            timeExcluded = true;
                        }
                        if (startDay.toString() != endDay.toString() && (pickerDay.toString() == endDay.toString() && (time.getMinutes() < excludedDateEnd.getMinutes()) && excludedDateEnd.getHours() == start.getHours()))
                        {
                            timeExcluded = true;
                        }
                    }
                    if (pickerType == 'end')
                    {
                        if (startDay.toString() != endDay.toString() && pickerEndDay.toString() == endDay.toString() && (time.getMinutes() > excludedDateStart.getMinutes()) && excludedDateStart.getHours() == end.getHours())
                        {
                            timeExcluded = true;
                        }
                        if (startDay.toString() != endDay.toString() && (pickerEndDay.toString() == endDay.toString() && (time.getMinutes() < excludedDateEnd.getMinutes()) && excludedDateEnd.getHours() == end.getHours()))
                        {
                            timeExcluded = true;
                        }
                    }
                }
                excludedDateIndex += 1;
            }
        }
        let exceedingMaxDuration = false;
        let belowMinDuration = false;
        let fixLimitInvalid = false
        if (pickerType == 'end')
        {
             //check fixed duration
            if(bookingSlotsPolicy?.BookingDuration?.Fixed.length > 0){
                const fixedLimitList = bookingSlotsPolicy?.BookingDuration?.Fixed;
                const validTimes = fixedLimitList.map((limit)=>new Date(new Date(start).setMinutes(start.getMinutes() + parseInt(limit))))
                if (type == 'hours')
                {
                    const validHours = fixedLimitList.map((limit)=>new Date(new Date(start).setMinutes(start.getMinutes() + parseInt(limit))).getHours())
                    fixLimitInvalid = !validHours?.includes(time.getHours());
                }
                if (type == 'minutes')
                {
                    const selectedHour = validTimes.filter((value)=> {
                        if(value.getHours() == end.getHours())
                        {
                            return value
                        }
                    })
                    const validMins = selectedHour.map((value)=>value.getMinutes())
                    fixLimitInvalid = !(validMins?.includes(time.getMinutes()));
                }
            }
            else
            {
                //check max/min duration
                let maxTime = new Date(new Date(start).setMinutes(start.getMinutes() + bookingSlotsPolicy?.BookingDuration?.Maximum));
                if (maxTime.toString() != 'Invalid Date' && maxTime?.getDate() != start.getDate())
                {
                    maxTime = new Date(new Date(new Date(start).setMinutes(59)).setHours(23));
                }
                if (type == 'hours' && time.getHours() > maxTime.getHours())
                {
                    exceedingMaxDuration = true;
                }
                if (type == 'minutes' && end.getHours() == maxTime.getHours() && time.getMinutes() > maxTime.getMinutes())
                {
                    exceedingMaxDuration = true;
                }
                const minTime = new Date(new Date(start).setMinutes(start.getMinutes() + bookingSlotsPolicy?.BookingDuration?.Minimum));
                if (type == 'hours' && time.getHours() < minTime.getHours())
                {
                    belowMinDuration = true;
                }
                if (type == 'minutes' && end.getHours() == minTime.getHours() && time.getMinutes() < minTime.getMinutes())
                {
                    belowMinDuration = true;
                }
            }
        }
    
      
        //specific start intervals check
        let specificStartTimeInvalid = false;
        if (pickerType == 'start' && bookingSlotsPolicy?.BookingStart?.SpecificTimes.length > 0)
        {
            if (type == 'hours')
            {
                const validHours = bookingSlotsPolicy?.BookingStart?.SpecificTimes.map(x => parseInt(x.substring(0, 2)));
                specificStartTimeInvalid = !validHours?.includes(time.getHours());
            }
            if (type == 'minutes')
            {
                const validMinutes = bookingSlotsPolicy?.BookingStart?.SpecificTimes.filter(x => parseInt(x.substring(0, 2)) == start.getHours()).map(x => parseInt(x.substring(3, 5)));
                specificStartTimeInvalid = !validMinutes?.includes(time.getMinutes());
            }
        }
        //specific end intervals check
        let specificEndTimeInvalid = false;
        if (pickerType == 'end' && bookingSlotsPolicy?.BookingEnd?.SpecificTimes.length > 0)
        {
            if (type == 'hours')
            {
                const validHours = bookingSlotsPolicy?.BookingEnd?.SpecificTimes.map(x => parseInt(x.substring(0, 2)));
                specificEndTimeInvalid = !validHours?.includes(time.getHours());
            }
            if (type == 'minutes')
            {
                const validMinutes = bookingSlotsPolicy?.BookingEnd?.SpecificTimes.filter(x => parseInt(x.substring(0, 2)) == end.getHours()).map(x => parseInt(x.substring(3, 5)));
                specificEndTimeInvalid = !validMinutes?.includes(time.getMinutes());
            }
        }
        return timeExcluded || !validSlotTime || exceedingMaxDuration || belowMinDuration || specificStartTimeInvalid || specificEndTimeInvalid || fixLimitInvalid;
    }

    public disableExcludedDates(date: DateTime, bookingPolicy: IBookingPolicy[], FloorId: number ): boolean 
    {
        const bookingPolicies = bookingPolicy ?? []
        const excludedDates = bookingPolicies
            .filter(policy => {
                const isBuildingPolicy = policy.Booking_Policy.PolicyType === "Building";
                const isFloorPolicy = FloorId !== 0 && policy.Booking_Policy.FloorId === FloorId.toString();
                return isBuildingPolicy || isFloorPolicy;
            })
            .flatMap(policy => 
                policy.Booking_Policy.BookingSlots?.ExcludedDates.map(
                    ({ StartDate, EndDate }) => `${StartDate} - ${EndDate}`
                ) || []
            );
    
        if (excludedDates.length === 0) 
        {
            return false;
        }
    
        return excludedDates.some(disabledDate => 
        {
            const [startDateStr, endDateStr] = disabledDate.split(" - ");
            const startDate = DateTime.fromISO(startDateStr).date();
            const endDate = DateTime.fromISO(endDateStr).date();
    
            if (startDate.hasSame(endDate, 'day')) 
            {
                return startDate.hasSame(date, 'day');
            }
            
            return date.date() >= startDate && date.date() <= endDate;
        });
    } 

}