import { Component } from "react";
import { Modal } from 'react-bootstrap';
import { appContext } from "../../../AppContext";
import IbssButton from "../../Buttons/Button/IbssButton";
import moment from "moment";
import { DateTime } from "luxon";
import { DateHelper } from "../../../Common/DateHelper";
import { MenuItem, TextField } from "@mui/material";
import IbssFormControl from "../../Forms/FormControl/IbssFormControl";
import IbssInputDropDown from "../../Inputs/SelectList/IbssInputDropDown";
import { IbssLuxonDateTimePicker } from "../../Inputs/LuxonDateTimePicker/IbssLuxonDateTimePicker";

export default class DateSelectModal extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            start: props.start ?? DateTime.now(),
            end: props.end ?? DateTime.now(),
            selectedReason: "",
            reasons: 
            [
                {
                    label:this.labels.HubLabelOutOfService,
                    value:"Out Of Service"
                },
                {
                    label:this.labels.HubLabelOfficeShutDown,
                    value:"Office shut down"
                },
                {
                    label:this.labels.HubLabelMaintenance,
                    value: "Maintenance"
                }
            ],
        };
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState, snapshot?: IState): void
    {
        if (this.props.start != prevProps.start)
        {
            this.setState({ start: this.props.start ?? DateTime.now() });
        }
        if (this.props.end != prevProps.end)
        {
            this.setState({ end: this.props.end ?? DateTime.now() });
        }
    }

    private startChanged(value: DateTime | null): void
    {
        if (value)
        {
            this.setState({ start: value });
        }
}

    private endChanged(value: DateTime | null): void
    {
        if (value)
        {
            this.setState({ end: value });
        }
    }

    private okButtonClicked(): void
    {
        this.props.okButtonClicked(this.state.start, this.state.end, this.state.selectedReason);
    }

    public render(): JSX.Element
    {
        const { closeClicked, title, message } = this.props;
        const okButtonDisabled = (!this.state.start.isValid || !this.state.end.isValid || this.state.start > this.state.end);

        return (
            <Modal show={this.props.show} onHide={() => closeClicked()}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                    <button type="button" className="close" onClick={() => closeClicked()} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <div style={{ paddingTop: '5px', paddingLeft: '58px' }} className="mb-4">{message}
                    <IbssLuxonDateTimePicker
                        className="d-block mb-4 mt-4"
                        label={this.labels.HubLabelFrom}
                        value={this.state.start}
                        onChange={newValue => this.startChanged(newValue)}
                        format={"yyyy-MM-dd HH:mm"}
                        minDateTime={DateTime.now().date()}
                        minutesStep={1}
                        ampm={false}
                    />
                    <div style={{ marginBottom: '20px' }}>
                        <IbssLuxonDateTimePicker
                            label={this.labels.HubLabelTo}
                            value={this.state.end}
                            onChange={newValue => this.endChanged(newValue)}
                            format={"yyyy-MM-dd HH:mm"}
                            minDateTime={DateTime.now().date()}
                            minutesStep={1}
                            ampm={false}
                        />
                    </div>
                    <div className="mb-4" style={{paddingRight: "50px"}}>
                        <IbssFormControl fullWidth size="small" >
                        <IbssInputDropDown
                            onChange={e => this.setState({ selectedReason: e.target.value })}
                            value={this.state.selectedReason}
                            inputLabel={this.labels.HubLabelReason}
                            required
                            id='classification'
                            options = {this.state.reasons}
                        />
                        </IbssFormControl>
                    </div>
                </div>
                <Modal.Footer>
                    <div className="text-center">
                        <IbssButton
                            style={{ height: '45px', minWidth: '100px' }}
                            variant="contained"
                            disabled={okButtonDisabled}
                            onClick={() => this.okButtonClicked()}
                        >
                            {this.labels.HubLabelOk}
                        </IbssButton>
                        {okButtonDisabled && <p>{this.labels.HubLabelStartDateMustBeBeforeTheEndDate}</p>}
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export interface IProps
{
    show: boolean;
    title: string;
    message: string;
    start?: DateTime;
    end?: DateTime;
    closeClicked: () => void;
    okButtonClicked: (start: DateTime, end: DateTime, selectedReason: string) => void;
};

interface IReasonOptions 
{
    label: string;
    value: string;
}
export interface IState
{
    start: DateTime;
    end: DateTime;
    selectedReason: string;
    reasons: IReasonOptions[];
}

