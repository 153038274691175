import React, { Component } from 'react';
import { Space } from '../../../Providers.Api/Spaces/SpaceRepository';
import IbssSvgIcon from '../../Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../Common/AllsvgIcons';
import IbssDialog from '../BaseDialog/IbssDialog';
import { appContext } from '../../../AppContext';
import { IBookingPolicyData } from '../../../Providers.Api/BookingPolicies/BookingPolicyRepository';
import './SpaceInfoDialog.scss';

export default class SpaceInfoDialog extends Component<IProps, IState>
{

    private get apiCache() { return appContext().apiCache; }
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            bookingPolicyDescription: "",
            additionalInformation: "",
            spaceName: "",
            hasPresentationAids: false,
            hasHearingAids: false,
            hasCatering: false,
            hasLayout: false,
        };
    }

    public async componentDidMount(): Promise<void>
    {
        await this.initialise();
    }

    public async componentDidUpdate(prevProps: IProps): Promise<void>
    {
        if (prevProps.buildingId !== this.props.buildingId || prevProps.spaceId !== this.props.spaceId || prevProps.isOpen !== this.props.isOpen )
        {
            if(this.props.isOpen)
            {
                await this.initialise();
            }
        }
    }

    private async initialise(): Promise<void>
    {
        if (!this.props.isOpen)
        {
            return;
        }

        if (this.props.description)
        {
            await this.setState({ bookingPolicyDescription: this.props.description ?? '', spaceName: this.labels.funcAdvancedBookingPolicyDescription_L });
        }
        else
        {
            this.reset();
            await this.loadCachedSpaces();
            await this.loadSpaceBookingPolicy();
        }

    }

    private reset(): void
    {
        this.setState({
            bookingPolicyDescription: "",
            additionalInformation: "",
            spaceName: "",
            hasPresentationAids: false,
            hasHearingAids: false,
            hasCatering: false,
            hasLayout: false,
        });
    }

    private async loadCachedSpaces(): Promise<void>
    {
        try
        {
            const response = await this.apiCache.getSpacesByBuilding(this.props.buildingId ?? 0);
            const spacesView = response.map(space => SpaceView.fromSpace(space));
            const spaceView = spacesView.find(space => space.spaceId === this.props.spaceId);

            if(spaceView)
            {
                this.setState({
                    additionalInformation: spaceView.spaceCustomInfo,
                    spaceName: spaceView.spaceId,
                    hasPresentationAids: !!spaceView.metaServReqsPresentation,
                    hasHearingAids: !!spaceView.metaServReqsHearing,
                    hasCatering: !!spaceView.metaServReqsCatering,
                    hasLayout: !!spaceView.spaceLayout,
                });
            }
        }
        catch
        {
            return;
        }
    }

    private async loadSpaceBookingPolicy(): Promise<void>
    {
        try
        {
            const bookingPolicy = await appContext().ibssApiClientV2.v2.byNodeid.spaces.bySpaceid.bookingPolicy.get<IBookingPolicyData>({
                nodeId: this.props.nodeId ?? 0,
                spaceid: this.props.spaceId ?? '',
            });

            const bookingPolicyView = BookingPolicyView.fromBookingPolicy(bookingPolicy);

            this.setState({ bookingPolicyDescription: bookingPolicyView.bookingPolicyDescription || this.labels.HubLabelBookingPolicyDescriptionNotAvailable });
        }
        catch
        {
            return;
        }
    }

    private dialogClosed(): void
    {
        this.props.onClose();
    }

    public render(): JSX.Element
    {
        const { isOpen, description } = this.props;
        const { bookingPolicyDescription, additionalInformation, spaceName, hasCatering, hasHearingAids, hasPresentationAids, hasLayout } = this.state;

        return (
            <IbssDialog
                open={isOpen}
                onClose={() => this.dialogClosed()}
                BackdropComponent={React.Fragment}
                header={<div className="space-info-modal-header">{spaceName}</div>}
                dialogContent={
                    <div className="space-info-content">
                        <div className="row pb-1">
                            <div className="col-12 mt-8 d-flex" style={{ padding: 0, paddingBottom: '3px' }}>
                                {
                                    hasCatering &&
                                    <IbssSvgIcon
                                        sx={{ marginTop: '3px', color: (theme) => theme.palette.text.secondary, fontSize: '2rem' }}
                                        className="mr-2"
                                    >
                                        {Icons.CateringIcon}
                                    </IbssSvgIcon>
                                }
                                {
                                    hasHearingAids &&
                                    <IbssSvgIcon
                                        sx={{ marginTop: '3px', color: (theme) => theme.palette.text.secondary, fontSize: '2rem' }}
                                        className="mr-2"
                                    >
                                        {Icons.HearingAidIcon}
                                    </IbssSvgIcon>
                                }
                                {
                                    hasPresentationAids &&
                                    <IbssSvgIcon
                                        sx={{ marginTop: '3px', color: (theme) => theme.palette.text.secondary, fontSize: '2rem' }}
                                        className="mr-2"
                                    >
                                        {Icons.PresentationAidIcon}
                                    </IbssSvgIcon>
                                }
                                {
                                    hasLayout &&
                                    <IbssSvgIcon
                                        sx={{ marginTop: '1px', color: (theme) => theme.palette.text.secondary, fontSize: '1.8rem' }}
                                        className="mr-2"
                                    >
                                        {Icons.SpaceLayoutIcon}
                                    </IbssSvgIcon>
                                }
                            </div>
                        </div>

                        <h3>{!description ?? this.labels.funcAdvancedBookingPolicyDescription_L}</h3>
                        <p>{bookingPolicyDescription}</p>

                        {
                            additionalInformation &&
                            <>
                                <h3>{this.labels.funcAdvancedBookingAdditionalInformation_S}</h3>
                                <p>{additionalInformation}</p>
                            </>
                        }
                    </div>
                }
            />
        );
    }
}

interface IProps
{
    isOpen: boolean;
    buildingId?: number;
    nodeId?: number;
    spaceId?: string;
    onClose: () => void;
    description?: string;
}

interface IState
{
    bookingPolicyDescription: string;
    additionalInformation: string;
    spaceName: string;
    hasPresentationAids: boolean;
    hasHearingAids: boolean;
    hasCatering: boolean;
    hasLayout: boolean;
}

class SpaceView
{
    public nodeId = 0;
    public spaceId = "";
    public spaceName = "";
    public spaceCapacity = 0;
    public spaceClass = "";
    public spaceType = "";
    public spaceTypeLabel = "";
    public spaceLayout = "";
    public bookingPolicyId = "";
    public metaServReqsCatering = 0;
    public metaServReqsAV = 0;
    public metaServReqsHearing = 0;
    public metaServReqsPresentation = 0;
    public spaceNameLabel = "";
    public spaceCustomInfo = "";

    public static fromSpace(space: Space): SpaceView
    {
        return{
            nodeId: space.Node_Id,
            spaceId: space.Space_Id,
            spaceName: space.Space_Name,
            spaceCapacity: space.Space_Capacity,
            spaceClass: space.Space_Class,
            spaceType: space.Space_Type,
            spaceTypeLabel: space.Space_Type_Label,
            spaceLayout: space.Space_Layout,
            bookingPolicyId: space.Booking_Policy_Id,
            metaServReqsCatering: space.Meta_Serv_Reqs_Catering, 
            metaServReqsAV: space.Meta_Serv_Reqs_AV,
            metaServReqsHearing: space.Meta_Serv_Reqs_Hearing,
            metaServReqsPresentation: space.Meta_Serv_Reqs_Presentation,
            spaceNameLabel: space.Space_Name_Label,
            spaceCustomInfo: space.Space_Custom_Info,
        }
    }
}

class BookingPolicyView
{
    public bookingPolicyDescription = '';

    public static fromBookingPolicy(bookingPolicy: IBookingPolicyData): BookingPolicyView
    {
        return{
            bookingPolicyDescription: bookingPolicy.Booking_Policy_Description,
        }
    }
}