import { Button, Grid, Skeleton, TextField, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { Component } from "react";
import "../../../../styles/css/searchspace.scss";
import { appContext } from "../../../../AppContext";
import { Card, Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import IbssFormControl from "../../../../Components/Forms/FormControl/IbssFormControl";
import IbssInputDropDown from "../../../../Components/Inputs/SelectList/IbssInputDropDown";
import IbssTextField from "../../../../Components/Inputs/TextField/IbssTextField";
import { IFloor, INode, PagedResponse } from "../../../../Providers.Api/Models";
import Helper, { getAllBuildingsData, getBuildingNodeIdUsingFloorNodeId, getFloorNameUsingFloorAndBuildingId } from "../../../../Common/Helper";
import IbssTimePicker from "../../../../Components/Inputs/TimePicker/IbssTimePicker";
import { IBuildingConfig } from "../../../../Common/ConfigHelper";
import { IUserPreferences } from "../../../../Providers.Api/UserPreferences/UserPreferenceRepository";
import IbssDatePicker from "../../../../Components/Inputs/DatePicker/IbssDatePicker";
import IbssRadioButton from "../../../../Components/Inputs/RadioButton/IbssRadioButton";
import IbssCheckBox from "../../../../Components/Inputs/CheckBox/IbssCheckBox";
import IbssSwitchLabel from "../../../../Components/Inputs/Switch/IbssSwitchLabel";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import './RecurringBooking.scss'
import RecurringSpaceCard from "../../../../Components/Cards/RecurringSpaceCard/RecurringSpaceCard";
import EmployeeOrVisitorPicker, { IFavouriteUser, IOnBehalfOf } from "../../../../Components/DialogLaunchers/EmployeeOrVisitorPicker/EmployeeOrVisitorPicker";
import BookingPartiesPicker, { Attendee } from "../../../../Components/DialogLaunchers/BookingPartiesPicker/BookingPartiesPicker";
import CostCodesPicker from "../../../../Components/DialogLaunchers/CostCodesPicker/CostCodesPicker";
import { Icons } from "../../../../Common/AllsvgIcons";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import IbssButtonRedo from "../../../../Components/Buttons/Button/IbssButton";
import { Modal } from 'react-bootstrap';
import SpaceCard from "../../../../Components/Cards/SpaceCard/SpaceCard";
import { Space } from "../../../../Providers.Api/Spaces/SpaceRepository";
import { ICreateV2BookingRequest } from "../../../../Providers.Api/Bookings/CreateV2BookingEndpoint";
import { RouteComponentProps } from "react-router-dom";
import "../../../../styles/css/searchspace.scss";
import Alert from "../../../../Components/Miscellaneous/Alert/Alert";
import SpaceArrangementsDialog, { ISpaceArrangement } from "../../../../Components/Dialogs/SpaceArrangementsDialog/SpaceArrangementsDialog";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DateTime } from 'luxon';
import { ISpaceSearch } from "../../../../Providers.Api/Spaces/SearchSpacesEndpoint";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DateHelper } from "../../../../Common/DateHelper";
import Guid from "../../../../Common/Guid";
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import FloorPlan, { IMapUrl, IPagedFloorPlanSpaces } from "../../../../Components/Data/FloorPlan/FloorPlan";
import { CostCodeWithAllocation } from "../../../../Components/Dialogs/CostCodesDialog/CostCodesDialog";
import React from "react";
import IbssToolTip from "../../../../Components/Miscellaneous/Tooltip/IbssToolTip";
import InfoIcon from "../../../../Components/Icons/InfoIcon";
import SelectMultipleDatesModal from "./SelectMultipleDatesModal";
import IbssIconButton from "../../../../Components/Buttons/IconButton/IbssIconButton";
import { Add } from "@mui/icons-material";
import CloseIcon from "../../../../Components/Icons/CloseIcon";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import LoadingOverlay from "../../../../Components/Navigation/LoadingOverlay/LoadingOverlay";
import dayjs, { Dayjs } from "dayjs";
import ViewSeries, { ISeriesBooking, ISeriesBookingDetails } from "../ViewSeries/ViewSeries";
import ConfirmBookingDialog from "../../../../Components/Dialogs/ConfirmBookingDialog/ConfirmBookingDialog";
import { IDelegate } from "../../../../Providers.Api/Delegates/GetManyByDelegatorEndpoint";
import IbssChip from "../../../../Components/Navigation/Chip/IbssChip";
import SpaceInfo from "../../../Shared/Spaces/Search/SpaceInfo";
import { BuildingBookingPolicy, IBookingPolicy, ISelectedDates, ISpaceInfo } from "./DataModels";
import { InputOption } from "../../../../Components/Inputs/SelectList2/InputSelectBox";
import CreateVisitorDialog from "../../../../Components/Dialogs/CreateVisitorDialog/CreateVisitorDialog";
import { BookingSlotHelper } from "../../../../Common/BookingSlotHelper";
import { BookingAttendanceControl } from "../../../Admin/BookingPolicies/Edit/EditBookingPolicy";

class CreateRecurringBookings extends IbssPage<RouteComponentProps, IState>
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private config = appContext().config;
    private apiCache = appContext().apiCache;
    private localStorage = appContext().localStorageProvider;
    private buildingConfig = {} as IBuildingConfig;
    private userPreferences = {} as IUserPreferences;
    private get bookingService() { return appContext().bookingService; }
    private get apiClient() { return appContext().apiClient; }
    private spaceResultsContainer = React.createRef<HTMLDivElement>();
    private alternativeSpaceResultsContainer = React.createRef<HTMLDivElement>();
    private bookingSlotHelper = new BookingSlotHelper();

    private userHasRecurringBookingRights: boolean;
    private userHasOneLensBookingRights: boolean;
    private userHasBookOnBehalfOfRights: boolean;
    private userHasBookOnBehalfAutoCheckinOptions: boolean;
    private userCanAddTags: boolean;
    private userCanReadTags: boolean;

    constructor(props: RouteComponentProps)
    {
        super(props);
        this.userHasRecurringBookingRights = this.localStorage.hasRight('API.Bookings.BookRecurring');
        this.userHasBookOnBehalfAutoCheckinOptions = this.localStorage.hasRight('API.Bookings.BookOnBehalfAutoCheckinOption');
        this.userHasOneLensBookingRights = this.localStorage.hasRight('ONELENS360.OperationalServices.Bookings');
        this.userHasBookOnBehalfOfRights = this.localStorage.hasRight('API.Bookings.BookOnBehalfOf');
        this.userCanAddTags = this.localStorage.hasRight("DATAMODEL.Tag2EventType.Create");
        this.userCanReadTags = this.localStorage.hasRight("DATAMODEL.Tag2EventType.Read");
        this.state =
        {
            buildingOptions: [],
            selectedBuildingOption: 0,
            selectedHoursLength: 1,
            floorTypeOptions: [],
            selectedFloor: 0,
            zoneOptions: [],
            selectedZone: 'any',
            workTypeOptions: [],
            selectedWorkType: 'any',
            spaceTypeOptions: [],
            selectedSpaceType: '',
            capacity: '1',
            fromTime: DateHelper.now().plus({ minutes: 5 }),
            toTime: DateHelper.null(),
            startDate: DateHelper.null(),
            recurrencePattern: '',
            endType: '',
            endDate: DateHelper.null(),
            numberOfOccurences: 0,
            daysSelected: {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
            },
            showMoreSearchOptions: false,
            dailyRecurrenceFrequency: '',
            dailyRecurrenceFrequencyValue: 1,
            monthlyRecurrenceFrequencyOption: '',
            monthlyRecurrenceDayValue: 0,
            monthlyRecurrenceMonthValue: 0,
            monthlyRecurrenceFrequencyDropdownValue: '',
            monthlyRecurrenceDayDropdownValue: '',
            monthlyRecurrenceEveryMonthValue: 0,
            bookSoonAsPossibleChecked: false,
            avChecked: false,
            cateringChecked: false,
            hearingAidChecked: false,
            presentationAidChecked: false,
            showSearchResultsColumn: true,
            fetchingSpaces: false,
            availableSpaces: [],
            mapViewSpaces: [],
            selectedSpace: {
                nodeId: 0,
                spaceId: '',
                imageUrl: '',
                spaceName: '',
                spaceType: '',
                capacity: '',
                floor: '',
                spaceLayout: '',
                zone: '',
                dateRange: '',
                timeRange: '',
                requestedOccurrence: '',
                availableOccurrences: '',
                cateringAvailable: 0,
                presentationAidAvailable: 0,
                hearingAidAvailable: 0,
                requiresAV: 0,
                spaceSetup: 0,
                spaceTypeLabel: '',
                datesFound: [],
                recurringSpace: false,
                spaceCustomInfo: '',
                bookingPolicyId: '',
                meetingLinkAvailable: 0
            },
            showConfirmBookingColumn: false,
            bookingName: '',
            bookingDescription: '',
            onlineMeetingLink: false,
            recurrenceOptions: [],
            showAlternativeSpaceModal: '',
            alternativeSpaceHighlighted: '',
            spaces: [],
            bookingParties: [],
            bookingPartyVisitors: [],
            costCodes: [],
            onBehalfOf: '',
            onBehalfOfData: { email: "", firstName: "", lastName: "", company: "", isVisitor: false },
            availableAlternativeSpaces: [],
            fetchingAlternativeSpaces: false,
            selectedSpaceConfig: {
                showAV: false,
                showCatering: false,
                showHearingAid: false,
                showNoise: false,
                showPresentationAid: false,
                showTemperature: false,
                showLinkedSpace: false
            },
            userEndOfDayPref: '',
            buildingSelectError: false,
            startGreaterThanRecurringEnd: false,
            noEndTypeSelected: false,
            spaceTypeError: false,
            noRecurrencePatternSelected: false,
            noDailyRecurrenceFrequencySelected: false,
            weeklyRecurrenceError: false,
            noMonthlyRecurrenceOptionSelected: false,
            noMonthlyRecurrenceFrequencyValueSelected: false,
            noMonthlyRecurrenceDayValueSelected: false,
            monthlyRecurrenceDayValueError: false,
            monthlyRecurrenceMonthValueError: false,
            monthlyRecurrenceEveryMonthValueError: false,
            selectedWorkTypeError: false,
            mapUrls: [],
            showMap: false,
            mapFailedToLoad: false,
            loadMap: Guid.empty,
            userHasSearched: false,
            searchedFromTime: '',
            searchedToTime: '',
            searchedStartDate: DateTime.now(),
            searchedRecurring: false,
            creatingBookings: false,
            linkedSpacesSelected: false,
            showLayoutSeatingModal: false,
            showSpaceArrangement: false,
            showSpaceLayout: false,
            selectedSeatingArrangement: { breakdown: 0, capacity: 0, setup: 0, style: '' },
            selectedLayout: '',
            isLinkedSpace: false,
            spaceAvailabilityStatus: '',
            showSetupResetUnavailableModal: false,
            checkingSpaceAvailability: false,
            showBookingOptionsCard: false,
            selectedLayoutName: '',
            autoCheckin: false,
            useOnBehalfOfCostCodes: 0,
            accordionBookingOptionsExpanded: true,
            accordionBookingDetailsExpanded: false,
            accordionSelectedSpaceDetailsExpanded: false,
            selectedSpaceBookingPolicy: {
                policyId: '',
                description: '',
                setup: 0,
                teardown: 0,
                bookingAttendanceControl: BookingAttendanceControl.showAttendanceOptional,
            },
            fetchingSpacePolicy: false,
            skipToken: null,
            alternativeSpacesSkipToken: null,
            lazyLoading: false,
            lazyLoadingAlternativeSpaces: false,
            selectedAlternativeDate: { from: '', to: '' },
            showAddRecurrenceSwitch: false,
            showLinkedSpaceSwitch: false,
            showBookAsapSwitch: false,
            showCapacityField: true,
            showBookMultipleSwitch: false,
            blockBookingDates: [],
            showMultipleDateSelectModal: false,
            creatingSeries: false,
            seriesBookingDetails: {
                bookingName: '',
                bookingDescription: '',
                bookingOwner: ''
            },
            successfulSeriesBookings: [],
            seriesCreationCompleted: false,
            failedSeriesBookings: [],
            showSingleBookingConfirmationModal: false,
            createdBookingResponse: { id: '', start: '', end: '' },
            delegatedBy: [],
            favourites: [],
            showLocationFormFields: false,
            bookingType: 'singular',
            selectedSpaceInfo: [],
            fetchingSpaceInfo: false,
            selectedOwnerType: '',
            ownerTypeOptions: [],
            showAddVisitorModal: false,
            userName: '',
            isVisitorSubmitted: false,
            bookingAttendance: 1,
            selectedOwner: null,
            displayMapFloorDropdown: false,
            selectedDates: null,
            hostEmail: '',
            visitorPayload: '',
            userBypassingPolicies: false,
            bookingPolicies: []
        };
    }

    private get selectedDaysOfWeek(): number[]
    {
        const dayNames: (keyof ISelectedWeekDays)[] = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

        const selectedDayNumbers = dayNames
            .map((day, index) => (this.state.daysSelected[day] ? index + 1 : null))
            .filter((number) => number !== null) as number[];

        return selectedDayNumbers;
    }

    public async componentDidMount(): Promise<void>
    {
        const allFloors = appContext().localStorageProvider.getNodeData().Regions.flatMap(region => region.Buildings).flatMap(building => building.Floors);
        const mapUrls = allFloors.map(i => ({ floorId: i.Node_Id, url: i.Floor_MapURI }));
        this.setState({ mapUrls: mapUrls });

        this.populateBuildings(this.state.selectedBuildingOption);
        const displayName = this.localStorage.getUserDetails().displayName;
        this.userPreferences = await this.localStorage.getUserPreferences();
        let rootNode = this.localStorage.getNodeData();
        this.buildingConfig = this.config.getBuildingConfig(rootNode, this.state.selectedBuildingOption);
        await this.getDefaultCostCode()
        await this.getDelegateList();
        let updatedOwnerOptions = [
            new InputOption('Self', "-1"),
            ...this.state.delegatedBy.map(i => new InputOption(i.PrimaryDisplayName ?? '', i.Delegate_Id ?? ''))
        ];
        if(this.userHasBookOnBehalfOfRights)
        {
            updatedOwnerOptions.splice(1, 0, new InputOption('Visitor', "0"))
        }
        this.setState({ ownerTypeOptions: updatedOwnerOptions, userName: displayName, selectedOwnerType: "-1" });
        if (this.buildingConfig && this.userPreferences.WorkingHoursPrefs)
        {
            if (this.userPreferences.Nodes?.length > 0)
            {
                const buildingId = this.userPreferences?.SearchPrefs?.DefaultBuilding;
                const floorId = this.userPreferences?.Nodes?.find(i => i.NodeId === buildingId)?.DefaultFloor;
                const zone = this.userPreferences?.Nodes?.find(i => i.NodeId === buildingId)?.DefaultZone;
                if (buildingId != null)
                {
                    this.populateFloors(buildingId);
                    this.populateSpaceTypes(buildingId);
                    //this.populateWorkSpaces(buildingId);
                    await this.setStateAsync({ selectedBuildingOption: buildingId });
                }
                if (floorId != null)
                {
                    await this.populateZones(floorId, this.state.selectedZone);
                    this.setState({ selectedFloor: floorId });
                }
                if (zone != null)
                {
                    this.setState({ selectedZone: this.state.zoneOptions.filter(x => x.zoneId == zone)[0]?.value });
                }
            }

            this.buildingConfig = this.config.getBuildingConfig(rootNode, this.state.selectedBuildingOption);
            let defaultTimes = this.config.getDefaultTimes(this.buildingConfig, this.userPreferences.WorkingHoursPrefs, false, true);
            this.setState({
                fromTime: defaultTimes.start,
                toTime: defaultTimes.end,
                startDate: defaultTimes.start,
                endDate: defaultTimes.end,
                userBypassingPolicies: (this.localStorage.hasRight("API.Bookings.BookOutsideWorkingHours") && this.localStorage.permissionAppliesToBuilding('API.Bookings.BookOutsideWorkingHours', this.state.selectedBuildingOption)) ||
                (this.localStorage.hasRight("API.Bookings.BookOutsideOfficeHours") && this.localStorage.permissionAppliesToBuilding('API.Bookings.BookOutsideOfficeHours', this.state.selectedBuildingOption)) ||
                (this.localStorage.hasRight("API.Bookings.IgnoreTimeHorizon") && this.localStorage.permissionAppliesToBuilding('API.Bookings.IgnoreTimeHorizon', this.state.selectedBuildingOption)) ||
                (this.localStorage.hasRight("API.Bookings.OverrideSetupTeardown") && this.localStorage.permissionAppliesToBuilding('API.Bookings.OverrideSetupTeardown', this.state.selectedBuildingOption)) ||
                (this.localStorage.hasRight("API.Bookings.BookOutsidePolicySlots") && this.localStorage.permissionAppliesToBuilding('API.Bookings.BookOutsidePolicySlots', this.state.selectedBuildingOption))
            });

            if (defaultTimes.end)
            {
                if (DateTime.now().set({ hour: defaultTimes.end.hour, minute: defaultTimes.end.minute }) > DateTime.now())
                {
                    this.setState({ userEndOfDayPref: defaultTimes.end.toISO(), selectedHoursLength: 0 });
                }
                else
                {
                    this.setState({ selectedHoursLength: 1, bookSoonAsPossibleChecked: false, startDate: this.state.startDate });
                }
            }
        }
        if (this.state.selectedSpaceType == 'Desk')
        {
            this.search();
        }

       this. getBuildingBookingPolicies(this.state.selectedBuildingOption)
    }          

    private async getDelegateList(): Promise<void>
    {
        const favouriteList: IFavouriteUser[] = [];
        const allDelegates = await this.apiClient.delegates.getPrimaryDelegators();
        const allfavourites = this.localStorage.getUserPreferences();
        const { FavouriteColleagues } = allfavourites;
        FavouriteColleagues.forEach((item) =>
        {
            favouriteList.push({
                email: item.Email,
                firstName: item.FirstName,
                lastName: item.LastName,
            })
        })
        this.setState({
            delegatedBy: allDelegates.value,
            favourites: favouriteList
        })
    }

    private async getDefaultCostCode(): Promise<void>
    {
        const buildingId = this.appState.buildingId;
        const parameters = await this.apiClient.parameters.getParameterByName(buildingId, "CostCode_Allow_Freeform");
        const costCodeParameter = parameters.filter(item => item.Node_Id === buildingId);
        const freeFormCostCode = costCodeParameter.some(i => i.Parameter_Value === "1");
        if (freeFormCostCode === false)
        {
            const { DefaultBookingCostCodes } = this.localStorage.getUserPreferences()
            this.setState({
                costCodes: DefaultBookingCostCodes.map((defaultCostCode) => ({
                    costCode: defaultCostCode.CostCodeName,
                    costCodeDescription: defaultCostCode.CostCodeDescription,
                    costCodeId: defaultCostCode.CostCodeID.toString(),
                    allocation: parseInt(defaultCostCode.CostCodeApportionment),
                }))
            });

        }
    }

    public populateBuildings(selectedValue: number): void
    {
        const buildings: INode[] = getAllBuildingsData();
        const options = buildings.map(i => ({ label: i.Name, value: i.Node_Id }));
        this.setState({ buildingOptions: options.sort((a, b) => a.label > b.label ? 1 : -1), selectedBuildingOption: selectedValue });
    }

    public buildingSelected(selectedBuildingId: number): void
    {
        this.searchFormUpdated();
        this.setState({
            selectedBuildingOption: selectedBuildingId,
            selectedFloor: 0,
            selectedZone: 'any',
            selectedWorkType: 'any',
            selectedSpaceType: 'any',
            linkedSpacesSelected: false,
            bookingType: 'singular',
            userBypassingPolicies: (this.localStorage.hasRight("API.Bookings.BookOutsideWorkingHours") && this.localStorage.permissionAppliesToBuilding('API.Bookings.BookOutsideWorkingHours', selectedBuildingId)) ||
            (this.localStorage.hasRight("API.Bookings.BookOutsideOfficeHours") && this.localStorage.permissionAppliesToBuilding('API.Bookings.BookOutsideOfficeHours', selectedBuildingId)) ||
            (this.localStorage.hasRight("API.Bookings.IgnoreTimeHorizon") && this.localStorage.permissionAppliesToBuilding('API.Bookings.IgnoreTimeHorizon', selectedBuildingId)) ||
            (this.localStorage.hasRight("API.Bookings.OverrideSetupTeardown") && this.localStorage.permissionAppliesToBuilding('API.Bookings.OverrideSetupTeardown', selectedBuildingId)) ||
            (this.localStorage.hasRight("API.Bookings.BookOutsidePolicySlots") && this.localStorage.permissionAppliesToBuilding('API.Bookings.BookOutsidePolicySlots', selectedBuildingId))
        });
        this.populateFloors(selectedBuildingId);
        //this.populateWorkSpaces(selectedBuildingId);
        this.populateSpaceTypes(selectedBuildingId);
        this. getBuildingBookingPolicies(selectedBuildingId);
    }

    public populateFloors(selectedBuildingId: number): void
    {
        const floors = Helper.getFloorsByBuildingId(selectedBuildingId);
        const options = floors
            .map(i => ({ label: i.Node_Name, value: i.Node_Id }))
            .sort((a, b) => (a.label < b.label ? - 1 : 1));
        this.setState({ floorTypeOptions: options, selectedZone: 'any' });
    }

    public floorSelected(id: number): void
    {
        this.searchFormUpdated();
        this.setState({ selectedFloor: id })

        if (id == 0)
        {
            this.setState({ zoneOptions: [], selectedZone: 'any' });
        }
        else
        {
            this.populateZones(id, this.state.selectedZone);
        }
    }

    public async populateZones(selectedFloor: number, selectedZone: string): Promise<void>
    {
        const zonesResponse = await this.apiClient.spaceZones.getMultiple(selectedFloor, true);
        const zones = zonesResponse;
        const options = zones.map(i => ({ label: i.Meta_Loc_Zone, value: i.Meta_Loc_Zone, zoneId: i.Space_Zone_Id }));
        this.setState({ zoneOptions: options, selectedZone: selectedZone });
    }

    public zoneChanged(zoneId: string)
    {
        this.searchFormUpdated();
        this.setState({ selectedZone: zoneId });
    }

    public populateWorkSpaces(selectedBuildingId: number): void
    {
        const workSpaces = Helper.getWorkSpaceTypesByNodeId(selectedBuildingId);
        const selectedWorkType = workSpaces.filter(x => x.Name == 'DeskWork')[0];
        if (selectedWorkType?.Config && this.state.bookingType != 'recurring')
        {
            this.updateFilterVisibility(
                {
                    showAV: selectedWorkType.Config.PageExtraShowAV == 1 ? true : false,
                    showCatering: selectedWorkType.Config.PageExtraShowCatering == 1 ? true : false,
                    showHearingAid: selectedWorkType.Config.PageExtraShowHearingAid == 1 ? true : false,
                    showNoise: selectedWorkType.Config.PageExtraShowNoise == 1 ? true : false,
                    showPresentationAid: selectedWorkType.Config.PageExtraShowPresentationAid == 1 ? true : false,
                    showTemperature: selectedWorkType.Config.PageExtraShowTemperature == 1 ? true : false,
                    showLinkedSpace: this.state.showLinkedSpaceSwitch
                }
            )
        }
        const options = workSpaces
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }));
        if (options.filter(x => x.value === 'DeskWork').length > 0)
        {
            this.setState({ selectedWorkType: 'DeskWork' });
        }

        this.setState({ workTypeOptions: [{ label: 'Any', value: 'any' }, ...options] });
    }

    public workTypeChanged(workType: string): void
    {
        const workSpaces = Helper.getWorkSpaceTypesByNodeId(this.state.selectedBuildingOption);
        const selectedWorkType = workSpaces.filter(x => x.Name == workType)[0];
        if (selectedWorkType?.Config)
        {
            this.updateFilterVisibility(
                {
                    showAV: selectedWorkType.Config.PageExtraShowAV == 1 ? true : false,
                    showCatering: selectedWorkType.Config.PageExtraShowCatering == 1 ? true : false,
                    showHearingAid: selectedWorkType.Config.PageExtraShowHearingAid == 1 ? true : false,
                    showNoise: selectedWorkType.Config.PageExtraShowNoise == 1 ? true : false,
                    showPresentationAid: selectedWorkType.Config.PageExtraShowPresentationAid == 1 ? true : false,
                    showTemperature: selectedWorkType.Config.PageExtraShowTemperature == 1 ? true : false,
                    showLinkedSpace: this.state.bookingType != 'recurring' && this.state.showLinkedSpaceSwitch
                }
            )
        }
        this.setState({ selectedWorkType: workType, selectedSpaceType: '' });
    }

    public updateFilterVisibility(config: ISelectedSpaceConfig): void
    {
        this.setState({
            selectedSpaceConfig: {
                showAV: config.showAV,
                showCatering: config.showCatering,
                showHearingAid: config.showHearingAid,
                showNoise: false, //config.showNoise,
                showPresentationAid: config.showPresentationAid,
                showTemperature: false, //config.showTemperature,
                showLinkedSpace: this.state.bookingType != 'recurring' && this.state.showLinkedSpaceSwitch
            }
        });
    }

    public populateSpaceTypes(selectedBuildingId: number): void
    {
        const spaceTypes = Helper.getSpaceTypesByNodeId(selectedBuildingId);
        const options = spaceTypes.result.filter(i => i.Name != null && i.Config.ShowOnBookingPage == 1);
        const spaceTypeDesk = options.filter(x => x.Name === 'Desk');
        if (spaceTypeDesk.length > 0)
        {
            this.setState({
                selectedSpaceType: 'Desk',
                showAddRecurrenceSwitch: spaceTypeDesk[0].Config.ShowAddRecurrence == 1 || this.userHasRecurringBookingRights ? true : false,
                showLinkedSpaceSwitch: spaceTypeDesk[0].Config.ShowLinkedSpaces == 1 || this.userHasOneLensBookingRights ? true : false,
                showBookAsapSwitch: spaceTypeDesk[0].Config.ShowBookAsap == 1 || this.userHasOneLensBookingRights ? true : false,
                showBookMultipleSwitch: spaceTypeDesk[0].Config.ShowAddMultiple == 1 || this.userHasRecurringBookingRights ? true : false,
                showCapacityField: spaceTypeDesk[0].Config.PageExtraShowCapacity == 0 ? false : true,
            });
            if (spaceTypeDesk[0].Config)
            {
                this.updateFilterVisibility(
                    {
                        showAV: spaceTypeDesk[0].Config.PageExtraShowAV == 1 ? true : false,
                        showCatering: spaceTypeDesk[0].Config.PageExtraShowCatering == 1 ? true : false,
                        showHearingAid: spaceTypeDesk[0].Config.PageExtraShowHearingAid == 1 ? true : false,
                        showNoise: spaceTypeDesk[0].Config.PageExtraShowNoise == 1 ? true : false,
                        showPresentationAid: spaceTypeDesk[0].Config.PageExtraShowPresentationAid == 1 ? true : false,
                        showTemperature: spaceTypeDesk[0].Config.PageExtraShowTemperature == 1 ? true : false,
                        showLinkedSpace: this.state.bookingType != 'recurring' && this.state.showLinkedSpaceSwitch
                    }
                )
            }
        }
        else
        {
            this.setState({
                selectedSpaceType: options[0]?.Name,
                showAddRecurrenceSwitch: options[0]?.Config.ShowAddRecurrence == 1 || this.userHasRecurringBookingRights ? true : false,
                showLinkedSpaceSwitch: options[0]?.Config.ShowLinkedSpaces == 1 || this.userHasOneLensBookingRights ? true : false,
                showBookAsapSwitch: options[0]?.Config.ShowBookAsap == 1 || this.userHasOneLensBookingRights ? true : false,
                showBookMultipleSwitch: options[0]?.Config.ShowAddMultiple == 1 || this.userHasRecurringBookingRights ? true : false,
                showCapacityField: options[0]?.Config.PageExtraShowCapacity == 0 ? false : true,
            });
            if (options.length > 0 && options[0].Config)
            {
                this.updateFilterVisibility(
                    {
                        showAV: options[0].Config.PageExtraShowAV == 1 ? true : false,
                        showCatering: options[0].Config.PageExtraShowCatering == 1 ? true : false,
                        showHearingAid: options[0].Config.PageExtraShowHearingAid == 1 ? true : false,
                        showNoise: options[0].Config.PageExtraShowNoise == 1 ? true : false,
                        showPresentationAid: options[0].Config.PageExtraShowPresentationAid == 1 ? true : false,
                        showTemperature: options[0].Config.PageExtraShowTemperature == 1 ? true : false,
                        showLinkedSpace: this.state.bookingType != 'recurring' && this.state.showLinkedSpaceSwitch
                    }
                )
            }
        }

        this.setState({ spaceTypeOptions: options.map(i => ({ label: i.Label, value: i.Name })).sort((a, b) => a.label.localeCompare(b.label)) });
    }
    
    public async ownerTypeChanged(ownerType: string): Promise<void>
    {
        let activeUserName = '';
        if (ownerType === '-1') 
        {
            activeUserName = this.labels.HubLabeluserLabel.toLowerCase();
            this.setState({selectedOwner: null});
        } 
        else if (ownerType === '0') 
        {
            activeUserName = this.labels.HubLabelvisitor.toLowerCase();
            await this.setState({ hostEmail: this.localStorage.getUserDetails().email, selectedOwner: null });
        } 
        else 
        {
            const ownerDetails = await this.state.delegatedBy.find((option) => option.Delegate_Id === ownerType);
            activeUserName = ownerDetails?.PrimaryDisplayName ?? '';
            this.setState({selectedOwner: ownerDetails ?? null});
        }

        await this.setState({ selectedOwnerType: ownerType, userName: activeUserName, autoCheckin: ownerType === '0' });
        this.searchFormUpdated();
    }

    public spaceTypeChanged(spaceType: string): void
    {
        this.searchFormUpdated();
        if (spaceType == 'Desk')
        {
            this.setState({ capacity: '1' })
        }

        const spaceTypes = Helper.getSpaceTypesByNodeId(this.state.selectedBuildingOption);
        const selectedWorkType = spaceTypes.result.filter(x => x.Name == spaceType)[0];
        this.setState({
            selectedSpaceType: spaceType,
            showAddRecurrenceSwitch: selectedWorkType.Config.ShowAddRecurrence == 1 || this.userHasRecurringBookingRights ? true : false,
            showLinkedSpaceSwitch: selectedWorkType.Config.ShowLinkedSpaces == 1 || this.userHasOneLensBookingRights ? true : false,
            showBookAsapSwitch: selectedWorkType.Config.ShowBookAsap == 1 || this.userHasOneLensBookingRights ? true : false,
            showBookMultipleSwitch: selectedWorkType.Config.ShowAddMultiple == 1 || this.userHasRecurringBookingRights ? true : false,
            showCapacityField: selectedWorkType.Config.PageExtraShowCapacity == 0 ? false : true,
            capacity: '1',
            linkedSpacesSelected: false,
            bookingType: 'singular',
            bookingAttendance: 1
        });

        if (selectedWorkType.Config)
        {
            this.updateFilterVisibility(
                {
                    showAV: selectedWorkType.Config.PageExtraShowAV == 1 ? true : false,
                    showCatering: selectedWorkType.Config.PageExtraShowCatering == 1 ? true : false,
                    showHearingAid: selectedWorkType.Config.PageExtraShowHearingAid == 1 ? true : false,
                    showNoise: selectedWorkType.Config.PageExtraShowNoise == 1 ? true : false,
                    showPresentationAid: selectedWorkType.Config.PageExtraShowPresentationAid == 1 ? true : false,
                    showTemperature: selectedWorkType.Config.PageExtraShowTemperature == 1 ? true : false,
                    showLinkedSpace: this.state.bookingType != 'recurring' && this.state.showLinkedSpaceSwitch
                }
            )
        }
    }

    public capacityUpdated(capacity: string): void
    {
        this.searchFormUpdated();
        this.setState({
            capacity: parseInt(capacity) < 0 ? '0' : capacity,
            bookingAttendance: parseInt(capacity) < 0 ? 0 : parseInt(capacity)
        })
    }

    public getDates(): IOccurrenceDates[]
    {
        const startFromDateTime = this.state.startDate.set({ hour: this.state.fromTime.hour, minute: this.state.fromTime.minute });
        const startToDateTime = this.state.startDate.set({ hour: this.state.toTime.hour, minute: this.state.toTime.minute });

        let dates: IOccurrenceDates[] = [{ start: startFromDateTime.toISO(), end: startToDateTime.toISO() }];

        const endDate = this.state.endDate.toJSDate();
        endDate.setHours(23);
        endDate.setMinutes(59);

        let limitExceeded = false;
        if (this.state.bookingType == 'multiple')
        {
            return this.state.blockBookingDates.map(x => ({ start: x.from.toISO(), end: x.to.toISO() }))
        }

        if (this.state.recurrencePattern == 'daily')
        {
            if (this.state.dailyRecurrenceFrequency == 'every')
            {
                while (!limitExceeded)
                {
                    const newStartOccurence = DateTime.fromISO(dates[dates.length - 1].start).plus({ days: this.state.dailyRecurrenceFrequencyValue }).toString();
                    const newEndOccurence = DateTime.fromISO(dates[dates.length - 1].end).plus({ days: this.state.dailyRecurrenceFrequencyValue }).toString();
                    dates.push({
                        start: newStartOccurence,
                        end: newEndOccurence,
                    })
                    limitExceeded = this.state.endType == 'On' ? endDate <= new Date(newStartOccurence) : dates.length > this.state.numberOfOccurences;
                    if (limitExceeded)
                    {
                        dates.pop()
                    }
                }
            }
            if (this.state.dailyRecurrenceFrequency == 'everyWeekday')
            {
                let increment = 1;
                while (!limitExceeded)
                {
                    const newStartOccurence = DateTime.fromISO(dates[dates.length - 1].start).plus({ days: increment }).toString();
                    const newEndOccurence = DateTime.fromISO(dates[dates.length - 1].end).plus({ days: increment }).toString();
                    if (DateTime.fromISO(newStartOccurence).weekday !== 6 && DateTime.fromISO(newStartOccurence).weekday !== 7)
                    {
                        dates.push({
                            start: newStartOccurence,
                            end: newEndOccurence,
                        })
                        increment = 1;
                    }
                    else
                    {
                        increment += 1;
                    }
                    limitExceeded = this.state.endType == 'On' ? endDate <= new Date(DateTime.fromISO(dates[dates.length - 1].start).plus({ days: this.state.dailyRecurrenceFrequencyValue }).toString()) : dates.length > this.state.numberOfOccurences;
                    if (limitExceeded)
                    {
                        dates.pop()
                    }
                }
            }
        }

        if (this.state.recurrencePattern == 'weekly')
        {
            let increment = 1;
            while (!limitExceeded)
            {
                const newStartOccurence = DateTime.fromISO(dates[dates.length - 1].start).plus({ days: increment }).toString();
                const newEndOccurence = DateTime.fromISO(dates[dates.length - 1].end).plus({ days: increment }).toString();

                if (this.selectedDaysOfWeek.includes(DateTime.fromISO(newStartOccurence).weekday))
                {
                    dates.push({
                        start: newStartOccurence,
                        end: newEndOccurence,
                    })
                    increment = 1;
                }
                else
                {
                    increment += 1;
                }
                limitExceeded = this.state.endType == 'On' ? endDate <= new Date(newStartOccurence) : dates.length > this.state.numberOfOccurences;
                if (limitExceeded && this.state.endType == 'After')
                {
                    dates.pop()
                }
            }
        }

        if (this.state.recurrencePattern == 'monthly')
        {

            if (this.state.monthlyRecurrenceFrequencyOption == 'multipleDays')
            {
                while (!limitExceeded)
                {
                    const newStartOccurence = DateTime.fromISO(dates[dates.length - 1].start).plus({ month: this.state.monthlyRecurrenceMonthValue }).toString();
                    const newEndOccurence = DateTime.fromISO(dates[dates.length - 1].end).plus({ month: this.state.monthlyRecurrenceMonthValue }).toString();

                    const totalDaysInMonth = new Date(new Date(newStartOccurence).getFullYear(), new Date(newStartOccurence).getMonth() + 1, 0).getDate()

                    const updatedStartOccurence = DateTime.fromISO(newStartOccurence).set({ day: this.state.monthlyRecurrenceDayValue > totalDaysInMonth ? totalDaysInMonth : this.state.monthlyRecurrenceDayValue })
                    const updatedEndOccurence = DateTime.fromISO(newEndOccurence).set({ day: this.state.monthlyRecurrenceDayValue > totalDaysInMonth ? totalDaysInMonth : this.state.monthlyRecurrenceDayValue })


                    dates.push({
                        start: updatedStartOccurence.toISO(),
                        end: updatedEndOccurence.toISO(),
                    })

                    limitExceeded = this.state.endType == 'On' ? endDate <= new Date(newStartOccurence) : dates.length > this.state.numberOfOccurences;
                    if (limitExceeded)
                    {
                        dates.pop()
                    }
                }
            }
            if (this.state.monthlyRecurrenceFrequencyOption == 'specificDays')
            {
                let datesOfDayInMonth = [];

                while (!limitExceeded)
                {
                    let index = 1;
                    const newStartOccurence = DateTime.fromISO(dates[dates.length - 1].start).plus({ month: this.state.monthlyRecurrenceEveryMonthValue * index }).toString();
                    const newEndOccurence = DateTime.fromISO(dates[dates.length - 1].end).plus({ month: this.state.monthlyRecurrenceEveryMonthValue * index }).toString();

                    const totalDaysInMonth = new Date(new Date(newStartOccurence).getFullYear(), new Date(newStartOccurence).getMonth() + 1, 0).getDate()

                    let dayNum = 1;

                    const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

                    while (dayNum < totalDaysInMonth)
                    {
                        if (weekdays[new Date(new Date(newStartOccurence).setDate(dayNum)).getDay()] == this.state.monthlyRecurrenceDayDropdownValue)
                        {
                            datesOfDayInMonth.push({ start: DateTime.fromISO(newStartOccurence).set({ day: dayNum }).toISO(), end: DateTime.fromISO(newEndOccurence).set({ day: dayNum }).toISO() })
                        }
                        dayNum += 1;
                    }
                    if (this.state.monthlyRecurrenceFrequencyDropdownValue == 'first')
                    {
                        dates.push({
                            start: datesOfDayInMonth[0].start,
                            end: datesOfDayInMonth[0].end,
                        })
                    }
                    if (this.state.monthlyRecurrenceFrequencyDropdownValue == 'second')
                    {
                        dates.push({
                            start: datesOfDayInMonth[1]?.start,
                            end: datesOfDayInMonth[1]?.end,
                        })
                    }
                    if (this.state.monthlyRecurrenceFrequencyDropdownValue == 'third')
                    {
                        dates.push({
                            start: datesOfDayInMonth[2]?.start,
                            end: datesOfDayInMonth[2]?.end,
                        })
                    }
                    if (this.state.monthlyRecurrenceFrequencyDropdownValue == 'last')
                    {
                        dates.push({
                            start: datesOfDayInMonth[datesOfDayInMonth.length - 1]?.start,
                            end: datesOfDayInMonth[datesOfDayInMonth.length - 1]?.end,
                        })
                    }
                    limitExceeded = this.state.endType == 'On' ? endDate <= new Date(newStartOccurence) : dates.length > this.state.numberOfOccurences;
                    if (limitExceeded)
                    {
                        dates.pop()
                    }
                    datesOfDayInMonth = [];
                    index += 1;
                }
            }

        }

        return dates;
    }

    public async searchSpaces(date?: IOccurrenceDates, lazyLoad?: boolean, loadForMap?: boolean): Promise<void>
    {
        // !date refers to the first columns space search. When a date is passed in it is for alternative spaces.
        if (!date)
        {
            this.setState({
                showSearchResultsColumn: true,
                fetchingSpaces: !lazyLoad ? true : false,
            })

            if (!lazyLoad && !loadForMap)
            {
                await this.setStateAsync({
                    selectedSpace: {
                        nodeId: 0,
                        spaceId: '',
                        imageUrl: '',
                        spaceName: '',
                        spaceType: '',
                        capacity: '',
                        floor: '',
                        spaceLayout: '',
                        zone: '',
                        dateRange: '',
                        timeRange: '',
                        requestedOccurrence: '',
                        availableOccurrences: '',
                        cateringAvailable: 0,
                        presentationAidAvailable: 0,
                        hearingAidAvailable: 0,
                        requiresAV: 0,
                        spaceSetup: 0,
                        spaceTypeLabel: '',
                        datesFound: [],
                        recurringSpace: false,
                        spaceCustomInfo: '',
                        bookingPolicyId: '',
                        meetingLinkAvailable: 0
                    },
                    showConfirmBookingColumn: false,
                    isLinkedSpace: false,
                    onBehalfOf: '',
                    onBehalfOfData: { email: "", firstName: "", lastName: "", company: "", isVisitor: false },
                    bookingParties: [],
                    bookingPartyVisitors: [],
                    skipToken: null
                });
            }
        }

        let dates: IOccurrenceDates[] = []
        if (date)
        {
            dates = [date];
        }
        else if (this.state.bookSoonAsPossibleChecked) // asap search
        {
            const start = DateTime.now().plus({ minutes: 5 });
            this.setState({ searchedStartDate: start, searchedFromTime: start.toISO(), searchedToTime: this.state.toTime.toISO() });
            dates = [{ start: start.toISO(), end: this.state.toTime.toISO() }];
        }
        else if (!this.state.bookSoonAsPossibleChecked && this.state.bookingType != 'recurring' && this.state.bookingType != 'multiple') //single space search
        {
            const from = this.state.fromTime;
            const start = this.state.startDate.set({ hour: from.hour, minute: from.minute });
            const to = this.state.toTime;
            const end = this.state.startDate.set({ hour: to.hour, minute: to.minute });
            this.setState({ searchedStartDate: start, searchedFromTime: start.toISO(), searchedToTime: end.toISO() })
            dates = [{ start: start.toISO(), end: end.toISO() }]
        }
        else
        {
            this.setState({ searchedStartDate: this.state.searchedStartDate, searchedFromTime: this.state.fromTime.toISO(), searchedToTime: this.state.toTime.toISO() });
            dates = this.getDates();
        }

        const bookingDates = dates.map(date => ({
            Start_Time: DateTime.fromISO(date.start).setZoneByNode(this.state.selectedBuildingOption),
            End_Time: DateTime.fromISO(date.end).setZoneByNode(this.state.selectedBuildingOption),
        }));

        await this.setState({ selectedDates : bookingDates[0] });

        let payload: ISpaceSearch =
        {
            Booking_Dates: bookingDates,
            Space_Capacity: parseInt(this.state.capacity),
            Space_Type: this.state.selectedSpaceType,
            Meta_Serv_Reqs_AV: this.state.selectedSpaceConfig.showAV && this.state.avChecked ? 1 : 0,
            Meta_Serv_Reqs_Catering: this.state.selectedSpaceConfig.showCatering && this.state.cateringChecked ? 1 : 0,
            Meta_Serv_Reqs_Hearing: this.state.selectedSpaceConfig.showHearingAid && this.state.hearingAidChecked ? 1 : 0,
            Meta_Serv_Reqs_Presentation: this.state.selectedSpaceConfig.showPresentationAid && this.state.presentationAidChecked ? 1 : 0,
            Floor_Id: this.state.selectedFloor,
            Meta_Loc_Zone: this.state.selectedZone,
            Space_Setup: 5,
            Primary: this.state.selectedOwner ? {
                Primary_Email: this.state.selectedOwner?.PrimaryEmail ?? '',
                Primary_User_Id: this.state.selectedOwner?.PrimaryUser_Id ?? ''
            } : null,
        };

        if (!this.state.avChecked || !this.state.selectedSpaceConfig.showAV)
        {
            payload = Object.fromEntries(Object.entries(payload).filter(([key]) => key != 'Meta_Serv_Reqs_AV')) as ISpaceSearch;
        }
        if (!this.state.cateringChecked || !this.state.selectedSpaceConfig.showCatering)
        {
            payload = Object.fromEntries(Object.entries(payload).filter(([key]) => key != 'Meta_Serv_Reqs_Catering')) as ISpaceSearch;
        }
        if (!this.state.hearingAidChecked || !this.state.selectedSpaceConfig.showHearingAid)
        {
            payload = Object.fromEntries(Object.entries(payload).filter(([key]) => key != 'Meta_Serv_Reqs_Hearing')) as ISpaceSearch;
        }
        if (!this.state.presentationAidChecked || !this.state.selectedSpaceConfig.showPresentationAid)
        {
            payload = Object.fromEntries(Object.entries(payload).filter(([key]) => key != 'Meta_Serv_Reqs_Presentation')) as ISpaceSearch;
        }
        if (this.state.selectedFloor == 0)
        {
            payload = Object.fromEntries(Object.entries(payload).filter(([key]) => key != 'Floor_Id')) as ISpaceSearch;
        }
        if (!this.state.linkedSpacesSelected || this.state.bookingType == 'recurring')
        {
            payload = Object.fromEntries(Object.entries(payload).filter(([key]) => key != 'Space_Setup')) as ISpaceSearch;
        }
        if (this.state.selectedZone == 'any')
        {
            payload = Object.fromEntries(Object.entries(payload).filter(([key]) => key != 'Meta_Loc_Zone')) as ISpaceSearch;
        }

        try
        {
            const skipToken = !date ? this.state.skipToken : this.state.alternativeSpacesSkipToken;
            const availableSpaces = await this.apiClient.spaces.searchSpaces(this.state.selectedBuildingOption, payload, skipToken, 25);

            if (!date)
            {
                this.setState({ skipToken: availableSpaces.skipToken });
            }
            else
            {
                this.setState({ alternativeSpacesSkipToken: availableSpaces.skipToken });
            }
            const spaceList = await this.apiCache.getSpacesByBuilding(this.state.selectedBuildingOption);
            this.setState({ spaces: spaceList });

            const spaces = availableSpaces.value.map(space =>
            {
                // map the start dates from the payload into utc and check if it's one of the api returned dates. Then store foundDates in building time
                // note: yyyy-MM-dd is used as that is the format the api returns the dates
                const datesFoundInBuildingDateTime = payload.Booking_Dates.filter(payloadDates => space.FoundDates.includes(payloadDates.Start_Time.toUTC().toISO())).map(x => x.Start_Time.toFormat('yyyy-MM-dd'));

                const spaceData = spaceList.filter(x => x.Space_Id == space.Space_Id);
                return ({
                    nodeId: spaceData[0].Node_Id,
                    spaceId: space.Space_Id,
                    imageUrl: spaceData[0]?.ImageURI,
                    spaceName: spaceData[0]?.Space_Name,
                    spaceType: spaceData[0]?.Space_Type,
                    capacity: spaceData[0]?.Space_Capacity.toString(),
                    floor: getFloorNameUsingFloorAndBuildingId(this.state.selectedBuildingOption, spaceData[0]?.Node_Id),
                    spaceLayout: spaceData[0]?.Space_Layout,
                    zone: spaceData[0]?.Meta_Loc_Zone,
                    dateRange: this.state.bookSoonAsPossibleChecked ? DateTime.now().plus({ minutes: 5 }).toLocaleDateString() : this.state.startDate.toLocaleDateString(),
                    timeRange: `${DateTime.fromISO(this.state.searchedFromTime).toLocaleTimeString()} - ${DateTime.fromISO(this.state.searchedToTime).toLocaleTimeString()}`,
                    requestedOccurrence: payload.Booking_Dates.length.toString(),
                    availableOccurrences: space.Found.toString(),
                    cateringAvailable: spaceData[0]?.Meta_Serv_Reqs_Catering,
                    presentationAidAvailable: spaceData[0]?.Meta_Serv_Reqs_Presentation,
                    hearingAidAvailable: spaceData[0]?.Meta_Serv_Reqs_Hearing,
                    requiresAV: spaceData[0]?.Meta_Serv_Reqs_AV,
                    spaceSetup: spaceData[0]?.Space_Setup,
                    spaceTypeLabel: spaceData[0]?.Space_Type_Label,
                    datesFound: datesFoundInBuildingDateTime,
                    recurringSpace: this.state.bookingType == 'recurring' || this.state.bookingType == 'multiple',
                    spaceCustomInfo: spaceData[0]?.Space_Custom_Info,
                    bookingPolicyId: spaceData[0]?.Booking_Policy_Id,
                    meetingLinkAvailable: spaceData[0]?.Meta_Ext_Booking_System
                });
            }).sort((a, b) => parseInt(a.availableOccurrences) == parseInt(b.availableOccurrences) ? 0 : parseInt(a.availableOccurrences) < parseInt(b.availableOccurrences) ? 1 : -1);

            if (date)
            {
                //Alternative spaces
                const availableAlternativeSpaces = lazyLoad ? this.state.availableAlternativeSpaces.concat(spaces) : spaces;
                this.setState({ availableAlternativeSpaces: availableAlternativeSpaces, fetchingAlternativeSpaces: false });
            }
            else
            {
                // column 2 space results
                const availableSpaces = lazyLoad || loadForMap ? this.state.availableSpaces.concat(spaces) : spaces;
                await this.setStateAsync({ availableSpaces: availableSpaces, fetchingSpaces: false, mapViewSpaces: spaces});
            }
        }
        catch (error)
        {
            this.setState({ fetchingSpaces: false });
        }
    }

    private async floorPlanSpacesRequested(): Promise<IPagedFloorPlanSpaces>
    {
        let spaces: IPagedFloorPlanSpaces;
        await this.searchSpaces(undefined, false, true);
        spaces = {
            skipToken: this.state.skipToken ?? '',
            spaces: this.state.mapViewSpaces.map(i => ({
                id: i.spaceId,
                colour: "",
                getColourFromData: true,
                periodCurrentSpaceValue: 0,
            }))
        };
        return spaces;
    }

    public recurrenceOptionSelected(date: string): void
    {
        const options = this.state.recurrenceOptions;

        const index = options.findIndex(x => x.from == date);
        options[index].selected = !options[index].selected;

        this.setState({ recurrenceOptions: options })

    }

    public getSpaceById(spaceId: string): IAvailableSpace
    {
        const space = this.state.availableSpaces.filter(x => x.spaceId == spaceId)[0];
        return space;
    }

    public async spaceSelected(space: IAvailableSpace): Promise<void>
    {
        const jsonSpaceLayout = space.spaceLayout ? JSON.parse(space.spaceLayout) : ""
        const spaceArrangementOption = jsonSpaceLayout && jsonSpaceLayout?.Layouts[0].SeatingArrangements;
        const dates = this.getDates();
        await this.setStateAsync({
            spaceAvailabilityStatus: '',
            selectedSeatingArrangement: { breakdown: spaceArrangementOption[0]?.Breakdown, capacity: spaceArrangementOption[0]?.Capacity, setup: spaceArrangementOption[0]?.Setup, style: spaceArrangementOption[0]?.Style },
            selectedLayout: spaceArrangementOption ? jsonSpaceLayout.Layouts[0]?.Space_Id : "",
            selectedLayoutName: jsonSpaceLayout ? jsonSpaceLayout.Layouts[0]?.Name : "",
            selectedSpace: space,
            recurrenceOptions: dates.map(date => { return ({ from: date.start, to: date.end, selected: space.datesFound.includes(date.start.split('T')[0]), alternativeSpace: '' }) }),
            accordionSelectedSpaceDetailsExpanded: false,
            selectedSpaceInfo: []
        });

        if (space.spaceSetup == 1)
        {
            await this.getSelectedSpacePolicyAndInfoDetails();
            const spaceHasDefaultSetupTeardown = this.state.selectedSpaceBookingPolicy.setup > 0 || this.state.selectedSpaceBookingPolicy.setup > 0;
            this.setState({
                spaceAvailabilityStatus: spaceHasDefaultSetupTeardown ? 'success' : '',
                accordionBookingDetailsExpanded: spaceHasDefaultSetupTeardown ? true : false,
                accordionBookingOptionsExpanded: spaceHasDefaultSetupTeardown ? false : true,
                showSpaceArrangement: true,
                showSpaceLayout: false,
                isLinkedSpace: true,
                showConfirmBookingColumn: true,
                showBookingOptionsCard: this.state.bookingType != 'recurring' && this.state.bookingType != 'multiple'
            });
        }
        else if (space.spaceSetup == 5)
        {
            await this.getSelectedSpacePolicyAndInfoDetails();
            const spaceHasDefaultSetupTeardown = this.state.selectedSpaceBookingPolicy.setup > 0 || this.state.selectedSpaceBookingPolicy.setup > 0;
            this.setState({
                spaceAvailabilityStatus: spaceHasDefaultSetupTeardown ? 'success' : '',
                accordionBookingDetailsExpanded: spaceHasDefaultSetupTeardown ? true : false,
                accordionBookingOptionsExpanded: spaceHasDefaultSetupTeardown ? false : true,
                showSpaceArrangement: true,
                showSpaceLayout: true,
                isLinkedSpace: true,
                showConfirmBookingColumn: true,
                showBookingOptionsCard: this.state.bookingType != 'recurring' && this.state.bookingType != 'multiple'
            });
        }
        else
        {
            this.getSelectedSpacePolicyAndInfoDetails();
            this.setState({
                showSpaceArrangement: false,
                showSpaceLayout: false,
                isLinkedSpace: false,
                showConfirmBookingColumn: true,
                showBookingOptionsCard: false
            });
        }
    }

    public alternativeSpaceSelected(): void
    {
        const spaces = this.state.recurrenceOptions;
        const index = spaces.findIndex(x => x.from == this.state.showAlternativeSpaceModal);
        spaces[index].alternativeSpace = this.state.alternativeSpaceHighlighted;
        spaces[index].selected = true;
        this.setState({ recurrenceOptions: spaces, alternativeSpaceHighlighted: '', showAlternativeSpaceModal: '', alternativeSpacesSkipToken: null, selectedAlternativeDate: { from: '', to: '' } });
    }

    private spaceNamesById: (Map<string, Space> | null) = null;
    private getSpaceDetailsById(spaceId: string): Space | null
    {
        this.spaceNamesById = new Map(this.state.spaces.map(i => [i.Space_Id, i]));
        return this.spaceNamesById.get(spaceId) ?? null;
    }

    private startDateChanged(date: Date | null): void
    {
        this.searchFormUpdated();
        this.setState({ startDate: DateHelper.fromPicker(date) });
    }

    private endDateChanged(date: Date | null): void
    {
        this.searchFormUpdated();
        this.setState({ endDate: DateHelper.fromPicker(date) });
    }

    private fromTimeUpdated(time: (Dayjs | null)): void
    {
        if (time?.isValid())
        {
            this.searchFormUpdated();
            const luxonTime = DateHelper.fromPicker(time);
            this.setState({ fromTime: luxonTime });

            if (luxonTime > this.state.toTime)
            {
                this.setState({ toTime: luxonTime });
            }
        }
    }

    private toTimeUpdated(time: (Dayjs | null)): void
    {
        if (time?.isValid())
        {
            this.searchFormUpdated();
            const luxonTime = DateHelper.fromPicker(time);
            this.setState({ toTime: luxonTime });

            if (luxonTime < this.state.fromTime)
            {
                this.setState({ fromTime: luxonTime });
            }
        }
    }

    public getPayload(options?: IRecurrenceOption[]): ICreateV2BookingRequest
    {
        const costCodePayload = this.state.costCodes.map(costCode =>
        ({
            Cost_Code: costCode.costCode,
            Cost_Code_Id: costCode.costCodeId,
            Allocation: costCode.allocation,
        }))

        // option param used when creating recurring bookings with option being one of the recurrance instances
        let payload: ICreateV2BookingRequest =
        {
            _CreatedAt: "",
            _CreatedBy: "",
            SpaceId: this.state.selectedSpace.spaceId,
            Booking_Start: DateTime.fromISO(this.state.searchedStartDate.toISO()).set({ hour: DateTime.fromISO(this.state.searchedFromTime).hour, minute: DateTime.fromISO(this.state.searchedFromTime).minute, second: 0 }).setZoneByNode(this.state.selectedBuildingOption, true).toUTC().toISO(),
            Booking_End: DateTime.fromISO(this.state.searchedStartDate.toISO()).set({ hour: DateTime.fromISO(this.state.searchedToTime).hour, minute: DateTime.fromISO(this.state.searchedToTime).minute, second: 0 }).setZoneByNode(this.state.selectedBuildingOption, true).toUTC().toISO(),
            Booking_Setup_Time: this.state.selectedSeatingArrangement.setup ? this.state.selectedSeatingArrangement.setup : 0,
            Booking_Tear_Down_Time: this.state.selectedSeatingArrangement.breakdown ? this.state.selectedSeatingArrangement.breakdown : 0,
            Booking_Name: this.state.bookingName,
            Booking_Description: this.state.bookingDescription,
            BookingAutoCheckin: this.state.autoCheckin ? 1 : 0,
            DisableExtUpdate: false,
            UseOnBehalfOfCostCodes: this.state.useOnBehalfOfCostCodes,
            Cost_Code_Allocation: this.state.useOnBehalfOfCostCodes === 1 ? [] : costCodePayload,
            Booking_Parties: this.state.bookingParties.concat(this.state.bookingPartyVisitors).map(x =>
            ({
                Booking_Participant_Email: x.email,
                Booking_Participant_Name: x.name,
                Booking_Participant_Organisation: x.organisation,
                Booking_Participant_Type: x.type,
                Booking_Visitor: x.visitor,
                Booking_Resource_Id: '',
            })),
            AddOnlineMeetingLink: this.state.onlineMeetingLink ? 1 : 0,
            Booking_Attendance: isNaN(this.bookingAttendance()) ? 0 : this.bookingAttendance(),
        };

        if (options)
        {
            payload = {
                ...payload,
                Booking_Dates: options.map(x => ({
                    Node_Id: x.alternativeSpace.length > 0 ? this.getSpaceDetailsById(x.alternativeSpace)?.Node_Id : this.state.selectedSpace.nodeId,
                    Booking_Start: DateTime.fromISO(x.from).setZoneByNode(this.state.selectedBuildingOption, true).toUTC().toISO(),
                    Booking_End: DateTime.fromISO(x.to).setZoneByNode(this.state.selectedBuildingOption, true).toUTC().toISO(),
                    Space_Id: x.alternativeSpace != '' ? x.alternativeSpace : this.state.selectedSpace.spaceId,
                    // Space_Layout: this.state.selectedLayout,
                    Booking_Setup_Time: this.state.selectedSeatingArrangement.setup ? this.state.selectedSeatingArrangement.setup.toString() : '0',
                    Booking_Tear_Down_Time: this.state.selectedSeatingArrangement.breakdown ? this.state.selectedSeatingArrangement.breakdown.toString() : '0',
                }))
            }

            //remove the below keys from payload as the values from Booking_Dates should be used.
            payload = Object.fromEntries(Object.entries(payload).filter(([key]) => key != "Booking_Start" && key != "Booking_End" && key != "Booking_Setup_Time" && key != "Booking_Tear_Down_Time" && key != "SpaceId")) as ICreateV2BookingRequest;
        }

        if (this.state.onBehalfOf)
        {
            const onBehalfOfPayload =
            {
                Email: this.state.onBehalfOfData.email,
                First_Name: this.state.onBehalfOfData.firstName,
                Last_Name: this.state.onBehalfOfData.lastName,
                Display_Name: null,
                Company: this.state.onBehalfOfData.company,
                Visit_Id: null,
                IsVisitor: this.state.onBehalfOfData.isVisitor ? true : false
            }
            payload.OnBehalfOf = JSON.stringify(onBehalfOfPayload);
            if (this.state.onBehalfOfData.isVisitor)
            {
                payload.Booking_Parties.push(
                    {
                        Booking_Participant_Email: this.state.onBehalfOfData.email,
                        Booking_Participant_Name: [this.state.onBehalfOfData.firstName, this.state.onBehalfOfData.lastName].join(" "),
                        Booking_Participant_Organisation: this.state.onBehalfOfData.company,
                        Booking_Participant_Type: 2,
                        Booking_Visitor: this.state.onBehalfOfData.isVisitor,
                        Booking_Resource_Id: '',
                    });
            }
        }
        else if (this.state.visitorPayload !== '')
        {
            payload.OnBehalfOf = this.state.selectedOwnerType == '0' && this.state.visitorPayload ? this.state.visitorPayload : undefined;
        }

        if (this.state.selectedOwner)
        {
            const separatedNames = this.state.selectedOwner.PrimaryDisplayName?.split(' ') ?? [];
            const onBehalfOfPayload =
            {
                Email: this.state.selectedOwner.PrimaryEmail,
                First_Name: separatedNames[0],
                Last_Name: separatedNames.at(-1) == separatedNames[0] ? '' : separatedNames.at(-1),
                Display_Name: this.state.selectedOwner.PrimaryDisplayName,
                Company: '',
                Visit_Id: null,
                IsVisitor: false
            }
            payload.OnBehalfOf = JSON.stringify(onBehalfOfPayload);
        }

        if (this.state.isLinkedSpace)
        {
            payload.Space_Layout = this.state.selectedSeatingArrangement.style;
        }

        return payload;
    }

    public create(): void
    {
        if (this.state.bookingType == 'recurring' || this.state.bookingType == 'multiple')
        {
            this.createMultipleBookings();
        }
        else if (this.state.isLinkedSpace)
        {
            this.createLinkedSpaceBooking();
        }
        else
        {
            this.createSingleBooking();
        }
    }

    public async createMultipleBookings(): Promise<void>
    {
        const selectedOptions = this.state.recurrenceOptions.filter(x => x.selected == true);
        const payload = this.getPayload(selectedOptions);
        const currentUser = this.localStorage.getUserDetails();

        this.setState({ creatingSeries: true, seriesBookingDetails: { bookingName: payload.Booking_Name, bookingDescription: payload.Booking_Description, bookingOwner: this.state.onBehalfOfData.email != "" ? this.state.onBehalfOfData.email : currentUser.email } });
        try
        {
            const bookingCreateResponse = await this.bookingService.create(this.state.selectedSpace.nodeId, payload, false);
            this.setState({ seriesCreationCompleted: true });
            const attendeedCount = this.state.bookingParties.filter(x => x.type == 1 || x.type == 2 || x.type == 4).length;

            if (bookingCreateResponse.Booking_Details)
            {
                this.setState({
                    successfulSeriesBookings: bookingCreateResponse.Booking_Details.map(x =>
                    ({
                        nodeId: x.Node_Id,
                        start: DateHelper.fromIsoByNode(x.StartDate, bookingCreateResponse.Node_Id),
                        end: DateHelper.fromIsoByNode(x.EndDate, bookingCreateResponse.Node_Id),
                        bookingId: x.BookingId,
                        spaceId: x.Space_Id,
                        bookedFor: this.state.onBehalfOf.length > 0 ? this.state.onBehalfOf : currentUser.email,
                        attendeedCount: attendeedCount > 0 ? attendeedCount : 1
                    })
                    )
                });
            }
            if (bookingCreateResponse.Failed_Bookings_Details && bookingCreateResponse.Failed_Bookings_Details.length > 0)
            {
                this.setState({
                    failedSeriesBookings: bookingCreateResponse.Failed_Bookings_Details.map(x =>
                    ({
                        nodeId: x.Node_Id,
                        start: DateHelper.fromIsoByNode(x.Booking_Start, bookingCreateResponse.Node_Id),
                        end: DateHelper.fromIsoByNode(x.Booking_End, bookingCreateResponse.Node_Id),
                        spaceId: x.Space_Id,
                        bookingId: '',
                        bookedFor: this.state.onBehalfOf.length > 0 ? this.state.onBehalfOf : currentUser.email,
                        attendeedCount: attendeedCount > 0 ? attendeedCount : 1
                    })
                    )
                });
            }
        }
        catch (error)
        {
            this.setState({ creatingSeries: false, seriesCreationCompleted: false });
        }
    }

    public async createSingleBooking(): Promise<void>
    {
        const payload = this.getPayload();
        this.setState({ creatingBookings: true, createdBookingResponse: { id: '', start: '', end: '' } });

        try
        {
            const bookingResponse = await this.bookingService.create(this.state.selectedSpace.nodeId, payload);
            this.setState({ creatingBookings: false, showSingleBookingConfirmationModal: true, createdBookingResponse: { id: bookingResponse.Booking_Id, start: DateHelper.fromIsoByNode(bookingResponse.Booking_Start, bookingResponse.Node_Id, true).toISO(), end: DateHelper.fromIsoByNode(bookingResponse.Booking_End, bookingResponse.Node_Id, true).toISO()} })
        } catch (error)
        {
            this.setState({ creatingBookings: false });
        }
    }

    public async createLinkedSpaceBooking(): Promise<void>
    {
        const { history } = this.props;
        this.setState({ creatingBookings: true });
        let payload = this.getPayload();
        payload.SpaceId = this.state.selectedLayout;
        this.setState({ creatingBookings: true, createdBookingResponse: { id: '', start: '', end: '' } });

        try
        {
            const bookingResponse = await this.bookingService.create(this.state.selectedSpace.nodeId, payload, true);
            this.setState({ creatingBookings: false, showSingleBookingConfirmationModal: true, createdBookingResponse: { id: bookingResponse.Booking_Id, start: bookingResponse.Booking_Start, end: bookingResponse.Booking_End } })
        }
        catch (error)
        {
            this.setState({ creatingBookings: false });
        }
    }

    public getNumberOfBookingsCreating(): number
    {
        if (this.state.bookingType == 'recurring')
        {
            return this.state.recurrenceOptions.filter(x => x.selected == true).length;
        }
        else
        {
            //Linked space creation
            let total = 1;
            if (this.state.selectedSeatingArrangement?.setup > 0)
            {
                total = total + 1;
            }
            if (this.state.selectedSeatingArrangement?.breakdown > 0)
            {
                total = total + 1;
            }
            return total;
        }
    }

    private get meetBookingAttendanceRequirement(): boolean
    {
        // either bookingAttendance field is not mandatory or if it is, this.state.bookingAttendance > 0
        return (this.state.selectedSpaceBookingPolicy.bookingAttendanceControl !== BookingAttendanceControl.showAttendanceMandatory || (this.state.selectedSpaceBookingPolicy.bookingAttendanceControl === BookingAttendanceControl.showAttendanceMandatory && this.state.bookingAttendance > 0))
    }

    public disableCreateBooking(): boolean
    {
        if (this.state.bookingType == 'recurring')
        {
            return this.state.recurrenceOptions.filter(x => x.selected == true).length == 0 || this.state.bookingName == '' || !this.meetBookingAttendanceRequirement;
        }
        else 
        {
            return this.state.bookingName === '' || this.state.creatingBookings || (this.state.selectedOwnerType === '0' && !this.state.isVisitorSubmitted) || !this.meetBookingAttendanceRequirement;
        }
    }

    public async showAlternativeSpaces(from: string, to: string): Promise<void>
    {
        this.setState({ showAlternativeSpaceModal: from, fetchingAlternativeSpaces: true, selectedAlternativeDate: { from: from, to: to } });
        await this.searchSpaces({ start: from, end: to }, true);
    }

    public bookingTypeUpdated(type: 'singular' | 'multiple' | 'recurring'): void
    {
        this.setState({ bookingType: type });
        if (type == 'multiple' || type == 'recurring')
        {
            this.setState({ bookSoonAsPossibleChecked: false })
        }
        this.searchFormUpdated();
    }

    public validateSearchFields(): boolean
    {
        const { endType, endDate, startDate, recurrencePattern, dailyRecurrenceFrequency, selectedWorkType } = this.state;

        let searchValid = true;
        this.setState({
            buildingSelectError: false,
            startGreaterThanRecurringEnd: false,
            noEndTypeSelected: false,
            spaceTypeError: false,
            noRecurrencePatternSelected: false,
            noDailyRecurrenceFrequencySelected: false,
            weeklyRecurrenceError: false,
            noMonthlyRecurrenceOptionSelected: false,
            noMonthlyRecurrenceFrequencyValueSelected: false,
            noMonthlyRecurrenceDayValueSelected: false,
            monthlyRecurrenceDayValueError: false,
            monthlyRecurrenceMonthValueError: false,
            monthlyRecurrenceEveryMonthValueError: false,
            selectedWorkTypeError: false
        });
        if (this.state.selectedBuildingOption == 0)
        {
            this.setState({ buildingSelectError: true });
            searchValid = false;
        }
        if (this.state.selectedSpaceType == '')
        {
            this.setState({ spaceTypeError: true });
            searchValid = false;
        }
        if (this.state.bookingType == 'recurring')
        {
            if (endType == 'On' && startDate > endDate)
            {
                this.setState({ startGreaterThanRecurringEnd: true });
                searchValid = false;
            }
            if (endType == '')
            {
                this.setState({ noEndTypeSelected: true });
                searchValid = false;
            }
            if (recurrencePattern == '')
            {
                this.setState({ noRecurrencePatternSelected: true });
                searchValid = false;
            }
            if (recurrencePattern == 'daily' && dailyRecurrenceFrequency == '')
            {
                this.setState({ noDailyRecurrenceFrequencySelected: true });
                searchValid = false;
            }
            if (recurrencePattern == 'weekly' && Object.values(this.state.daysSelected).indexOf(true) == -1)
            {
                this.setState({ weeklyRecurrenceError: true });
                searchValid = false;
            }
            if (recurrencePattern == 'monthly' && this.state.monthlyRecurrenceFrequencyOption == '')
            {
                this.setState({ noMonthlyRecurrenceOptionSelected: true });
                searchValid = false;
            }
            if (recurrencePattern == 'monthly' && this.state.monthlyRecurrenceFrequencyOption == 'specificDays' && this.state.monthlyRecurrenceFrequencyDropdownValue == '')
            {
                this.setState({ noMonthlyRecurrenceFrequencyValueSelected: true });
                searchValid = false;
            }
            if (recurrencePattern == 'monthly' && this.state.monthlyRecurrenceFrequencyOption == 'specificDays' && this.state.monthlyRecurrenceDayDropdownValue == '')
            {
                this.setState({ noMonthlyRecurrenceDayValueSelected: true });
                searchValid = false;
            }
            if (recurrencePattern == 'monthly' && this.state.monthlyRecurrenceFrequencyOption == 'specificDays' && this.state.monthlyRecurrenceEveryMonthValue == 0)
            {
                this.setState({ monthlyRecurrenceEveryMonthValueError: true });
                searchValid = false;
            }
            if (recurrencePattern == 'monthly' && this.state.monthlyRecurrenceFrequencyOption == 'multipleDays' && this.state.monthlyRecurrenceDayValue == 0)
            {
                this.setState({ monthlyRecurrenceDayValueError: true });
                searchValid = false;
            }
            if (recurrencePattern == 'monthly' && this.state.monthlyRecurrenceFrequencyOption == 'multipleDays' && this.state.monthlyRecurrenceMonthValue == 0)
            {
                this.setState({ monthlyRecurrenceMonthValueError: true });
                searchValid = false;
            }
        }
        return searchValid;
    }

    public async search(): Promise<void>
    {
        this.setState({ userHasSearched: true, searchedRecurring: this.state.bookingType == 'recurring', loadMap: Guid.new() });
        if (!this.validateSearchFields())
        {
            return;
        }

        this.setState({ availableSpaces: [], displayMapFloorDropdown: this.state.selectedFloor == 0 });
        this.searchSpaces();
        this.setState({ showMap: false, mapFailedToLoad: false });
    }

    public mapSpaceModalSelected(spaceId: string): void
    {
        this.spaceSelected(this.getSpaceById(spaceId));
        document.getElementById(`space-card-${spaceId}`)?.scrollIntoView();
        this.setState({ showMap: false })
    }

    public getHoursLengthOptions(): IListOption<number>[]
    {
        let options =
            [
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 },
                { label: '5', value: 5 },
                { label: '6', value: 6 },
                { label: '7', value: 7 },
                { label: '8', value: 8 },
            ]

        if (this.state.userEndOfDayPref != '')
        {
            options = [{ label: this.labels.HubLabelEndOfWorkingDay, value: 0 }, ...options]
        }
        return options
    }

    public getResultsColumnMessage(): React.ReactNode
    {
        if (this.state.userHasSearched)
        {
            return (
                <div style={{ textAlign: 'center' }}>
                    <img className="mt-2" alt="NoSpacesImage" src="/images/NoSearchResults.svg" />
                    <div className="noResultsHeading">{this.labels.HubLabelNoSpaceResults}</div>
                    <div className="noResultsSubText mt-1">{this.labels.HubLabelNoSpaceResultsSubText}</div>
                </div>
            )
        }
        else
        {
            return (
                <div style={{ textAlign: 'center' }}>
                    <img className="mt-2" alt="NoSpacesImage" src="/images/NoSpaceSearchReults.svg" />
                    <div className="noResultsHeading">{this.labels.HubLabelEnterYourCriteria}</div>
                    <div className="noResultsSubText">{this.labels.HubLabelPickYourCriteria}</div>
                    <div className="noResultsSubText">{this.labels.HubLabelPickYourCriteriaAdditionalInfo}</div>
                </div>
            )
        }
    }

    public getCreateBookingColumnMessage(): React.ReactNode
    {
        if (this.state.availableSpaces.length == 0)
        {
            return (
                <div style={{ border: '1px dashed darkgrey', borderRadius: '8px', height: 'calc(100vh - 196px)', textAlign: 'center' }}>
                    <div style={{ position: 'relative', top: '42%', color: 'darkgrey', fontSize: '80px' }}>3</div>
                </div>
            )
        }
        else
        {
            return (
                <Card style={{ height: '100vh', maxHeight: 'calc(100vh - 196px)', overflow: 'auto', borderRadius: '8px' }}>
                    <div style={{ textAlign: 'center' }}>
                        <img className="mt-2" alt="NoSpacesImage" src="/images/NoSpaceSearchReults.svg" />
                        <div className="noResultsHeading">{this.labels.HubLabelEnterYourCriteria}</div>
                        <div className="noResultsSubText">{this.labels.HubLabelSelectSpaceInfoMessage}</div>
                        <div className="noResultsSubText">{this.labels.HubLabelSelectSpaceAdditionalInfoMessage}</div>
                    </div>
                </Card>
            )
        }
    }

    public updateLayoutSeating(seating: ISpaceArrangement, layout: string, layoutName: string): void
    {
        this.setState({ selectedSeatingArrangement: seating, selectedLayout: layout, selectedLayoutName: layoutName, showLayoutSeatingModal: false, spaceAvailabilityStatus: '' });
    }

    public async checkLinkedSpaceAvailability(): Promise<void>
    {
        if (this.state.selectedSeatingArrangement.setup <= this.state.selectedSpaceBookingPolicy.setup && this.state.selectedSeatingArrangement.breakdown <= this.state.selectedSpaceBookingPolicy.teardown)
        {
            this.setState({ spaceAvailabilityStatus: 'success', accordionBookingOptionsExpanded: false, accordionBookingDetailsExpanded: true });
            return;
        }

        this.setState({ spaceAvailabilityStatus: '', accordionBookingOptionsExpanded: true, accordionBookingDetailsExpanded: false, checkingSpaceAvailability: true })

        let payload: ISpaceSearch = {
            Booking_Dates:
                [
                    {
                        Start_Time: DateTime.fromISO(this.state.searchedFromTime).minus({ minutes: this.state.selectedSeatingArrangement.setup }).setZoneByNode(this.state.selectedBuildingOption),
                        End_Time: DateTime.fromISO(this.state.searchedToTime).plus({ minutes: this.state.selectedSeatingArrangement.breakdown }).setZoneByNode(this.state.selectedBuildingOption),
                    }
                ],
            Space_Type: this.state.selectedSpaceType,
        }

        payload.TimeInclusive = true;
        const availableSpaces = await this.apiClient.spaces.searchSpaces(this.state.selectedBuildingOption, payload, null, 1000);
        const availableSpaceIds = availableSpaces.value.map(space => space.Space_Id);
        const selectedSpaceIds = this.state.selectedLayout.split(';');

        let spaceNotAvailable = false;
        for (let ele of selectedSpaceIds)
        {
            if (!availableSpaceIds.includes(ele))
            {
                spaceNotAvailable = true;
            }
        }

        if (spaceNotAvailable)
        {
            this.setState({ spaceAvailabilityStatus: 'error', showSetupResetUnavailableModal: true, accordionBookingOptionsExpanded: true, accordionBookingDetailsExpanded: false });
        }
        else
        {
            this.setState({ spaceAvailabilityStatus: 'success', accordionBookingOptionsExpanded: false, accordionBookingDetailsExpanded: true });
        }
        this.setState({ checkingSpaceAvailability: false });
    }
    public async getSpaceInfo(nodeId: number): Promise<void>
    {
        const spaceInfo = await appContext().ibssApiClientV1.v1.byNodeid.spaces.bySpaceid.spaceInfo.get<ISpaceInfo[]>({
            nodeId: nodeId,
            spaceid: this.state.selectedSpace?.spaceId
        });

        this.setState({
            selectedSpaceInfo: spaceInfo.map(x => ({
                imageUrl: x.ImageURI,
                spaceInfoMessage: x.Space_Info_Message,
                title: x.Space_Info_Title,
                actionUrl: x.ActionURI,
                availableFrom: DateHelper.fromIsoByNode(x.Available_From, nodeId),
                expiryDate: DateHelper.fromIsoByNode(x.Expiry_Date, nodeId),
            })),
        });
    }

    public async getSelectedSpacePolicyAndInfoDetails(): Promise<void>
    {
        const buildingId = parseInt(getBuildingNodeIdUsingFloorNodeId(this.state.selectedSpace.nodeId.toString()));

        this.setState({ fetchingSpaceInfo: true });

        if (this.state.selectedSpace.bookingPolicyId != this.state.selectedSpaceBookingPolicy.policyId)
        {
            this.setState({ fetchingSpacePolicy: true });

            const response = await appContext().ibssApiClientV2.v2.byNodeid.bookingpolicies.get<BuildingBookingPolicy[]>({
                nodeId: buildingId,
                select: BuildingBookingPolicy,
                filter: `Booking_Policy_Id eq '${this.state.selectedSpace.bookingPolicyId}'`
            });


            if (response.length === 0)
            {
                return;
            }

            const spacePolicy = response[0];

            this.setState({
                fetchingSpacePolicy: false,
                selectedSpaceBookingPolicy: {
                    policyId: spacePolicy.Booking_Policy_Id ?? '',
                    description: spacePolicy.Booking_Policy.Booking_Policy_Description,
                    setup: spacePolicy.Booking_Policy.BookingSlots?.BookingSetupTime ?? 0,
                    teardown: spacePolicy.Booking_Policy.BookingSlots?.BookingTearDownTime ?? 0,
                    bookingAttendanceControl: spacePolicy.Booking_Policy?.BookingAttendanceControl ?? BookingAttendanceControl.showAttendanceOptional,
                }
            });
        }
        if (this.state.selectedSpaceInfo.length == 0)
        {
            await this.getSpaceInfo(buildingId);
        }
        this.setState({ fetchingSpaceInfo: false });
    }

    public async spaceSearchScroll(): Promise<void>
    {
        {
            if (!this.spaceResultsContainer?.current)
            {
                return;
            }
            const scrollAmount = this.spaceResultsContainer.current.scrollTop;
            const elementHeight = this.spaceResultsContainer.current.scrollHeight;
            const clientHeight = this.spaceResultsContainer.current.clientHeight;
            if (!this.state.lazyLoading && this.state.skipToken != null && this.state.skipToken.length > 0 && scrollAmount && elementHeight && clientHeight && (scrollAmount > (elementHeight - (clientHeight * 2))))
            {
                await this.setStateAsync({ lazyLoading: true });
                await this.searchSpaces(undefined, true);
                this.setState({ lazyLoading: false });
            }
        }
    }

    public async alternativeSpaceSearchScroll(): Promise<void>
    {
        {
            if (!this.alternativeSpaceResultsContainer?.current)
            {
                return;
            }
            const scrollAmount = this.alternativeSpaceResultsContainer.current.scrollLeft;
            const elementWidth = this.alternativeSpaceResultsContainer.current.scrollWidth;
            const clientWidth = this.alternativeSpaceResultsContainer.current.clientWidth;

            if (!this.state.lazyLoadingAlternativeSpaces && this.state.alternativeSpacesSkipToken != null && this.state.alternativeSpacesSkipToken.length > 0 && scrollAmount && elementWidth && clientWidth && (scrollAmount > (elementWidth - (clientWidth * 2))))
            {
                await this.setStateAsync({ lazyLoadingAlternativeSpaces: true });
                await this.showAlternativeSpaces(this.state.selectedAlternativeDate.from, this.state.selectedAlternativeDate.to);
                this.setState({ lazyLoadingAlternativeSpaces: false });
            }
        }
    }

    public closeMultipleDateSelectModal(): void
    {
        this.setState({ showMultipleDateSelectModal: false });
    }

    public searchFormUpdated(): void
    {
        this.setState({
            selectedSpace: {
                nodeId: 0,
                spaceId: '',
                imageUrl: '',
                spaceName: '',
                spaceType: '',
                capacity: '',
                floor: '',
                spaceLayout: '',
                zone: '',
                dateRange: '',
                timeRange: '',
                requestedOccurrence: '',
                availableOccurrences: '',
                cateringAvailable: 0,
                presentationAidAvailable: 0,
                hearingAidAvailable: 0,
                requiresAV: 0,
                spaceSetup: 0,
                spaceTypeLabel: '',
                datesFound: [],
                recurringSpace: false,
                spaceCustomInfo: '',
                bookingPolicyId: '',
                meetingLinkAvailable: 0
            },
            bookingName: '',
            bookingDescription: '',
            showSearchResultsColumn: false,
            showConfirmBookingColumn: false,
            availableSpaces: [],
            showMap: false,
            isVisitorSubmitted: false
        });
    }

    public hoursLengthValueUpdated(value: number): void
    {
        this.searchFormUpdated();
        this.setState({ selectedHoursLength: value });
    }

    public linkedSpaceToggleSelected(checked: boolean): void
    {
        this.searchFormUpdated();
        this.setState({ linkedSpacesSelected: checked });
    }

    public recurrencePatternUpdated(value: string): void
    {
        this.searchFormUpdated();
        this.setState({ recurrencePattern: value });
    }

    public addDateToBlockBookings(from: DateTime, to: DateTime): void
    {
        this.setState({ blockBookingDates: [...this.state.blockBookingDates, { from: from, to: to }], showMultipleDateSelectModal: false })
    }

    public removeBlockBookingDate(fromDate: DateTime): void
    {
        const updatedBlockBookingDates = this.state.blockBookingDates.filter(x => x.from != fromDate);
        this.setState({ blockBookingDates: updatedBlockBookingDates });
    }

    public resetStatesForNewCreate(): void
    {
        this.searchFormUpdated();
        this.setState({ showSingleBookingConfirmationModal: false });
    }

    public seriesBookingEditRoute(nodeId: string, bookingId: string, spaceId: string): void
    {
        this.props.history.push(`/flex-my-bookings/${nodeId}/mybooking/${bookingId}/${spaceId}`)
    }

    public bookAsSoonAsPossibleSelected(checked: boolean): void
    {
        this.setState({ bookSoonAsPossibleChecked: checked, fromTime: DateTime.now().plus({ minutes: 5 }), toTime: DateTime.now().plus({ hour: 1, minutes: 5 }) });
        this.searchFormUpdated();
    }

    public visitorSubmitted(submitted: boolean): void
    {
        this.setState({showAddVisitorModal : false, isVisitorSubmitted: submitted})
    }

    private bookingAttendance(): number 
    {
        const totalAttendees = this.state.bookingParties.length + this.state.bookingPartyVisitors.length;
        const bookingAttendance = (totalAttendees > 0 && this.state.bookingAttendance === 0) ? totalAttendees : this.state.bookingAttendance;
        return bookingAttendance;
    }

    private async mapViewFloorDropdownChange(floorId: number): Promise<void> 
    {
        await this.setStateAsync({ selectedFloor: floorId, loadMap: Guid.new(), availableSpaces: [], skipToken: null, });
    }

    private async viewMap(): Promise<void>
    {
        if(!this.state.showMap)
        {
            await this.setStateAsync({ skipToken: null, availableSpaces: [] }); // when loading the space for the map we need a fresh spaces call so the correct spaces are highlighted green in the map
        }

        if (this.state.selectedFloor == 0)
        {
            await this.setStateAsync({ selectedFloor: this.state.floorTypeOptions[0].value, loadMap: Guid.new() });
        }
        this.setState({ showMap: !this.state.showMap });
    }
    
    private async openVisitorDialog(): Promise<void> 
    {
       await this.setState({showAddVisitorModal : true });
    }
    private async visitorPayload(payload: string): Promise<void> 
    {
        this.setState({ visitorPayload: payload });
    }

    private async getBuildingBookingPolicies ( buildingId: number): Promise<void>
    {
        const policies = await appContext().ibssApiClientV2.v2.byNodeid.bookingpolicies.get<BuildingBookingPolicy[]>({
            nodeId: buildingId,
            top: 200,
            select: BuildingBookingPolicy,
        })

        if (policies && policies.length > 0) 
            {
                policies.map(policy => ({
                    Node_Id: policy.Node_Id,
                    Booking_Policy_Id: policy.Booking_Policy_Id,
                    Booking_Policy_Name: policy.Booking_Policy_Name,
                    Booking_Policy: {
                        PolicyType: policy.Booking_Policy.PolicyType,
                        BuildingId: policy.Booking_Policy.BuildingId,
                        FloorId: policy.Booking_Policy.FloorId,
                        Allowed_TimeRange: policy.Booking_Policy.Allowed_TimeRange,
                        Booking_Policy_Description: policy.Booking_Policy.Booking_Policy_Description,
                        BookingAttendanceControl: policy.Booking_Policy.BookingAttendanceControl,
                        BookingSlots: {
                            ExcludedDates: policy.Booking_Policy.BookingSlots.ExcludedDates,
                        },
                    },
                }));
    
                this.setState({ bookingPolicies: policies });
            }
        }
    
    public render(): JSX.Element
    {
        const { fromTime, toTime, endType, endDate, startDate, recurrencePattern, numberOfOccurences, dailyRecurrenceFrequency, dailyRecurrenceFrequencyValue, showMap } = this.state;
        const alternativeSpaces = this.state.availableAlternativeSpaces.filter(x => x.spaceId != this.state.selectedSpace.spaceId);
        const disableCheckAvailabilityBtn = (this.state.showSpaceArrangement && this.state.selectedSeatingArrangement.style == '') || (this.state.showSpaceLayout && this.state.selectedLayout == '') || this.state.checkingSpaceAvailability;

        const selectedSpaceBuildingId = parseInt(getBuildingNodeIdUsingFloorNodeId(this.state.selectedSpace.nodeId));

        const bookingDetailsForm = (
            <div style={{ overflow: 'auto' }} className="m-4">
                <div className='columnSubheading mb-3'>{this.labels.HubLabelAddBookingDetail}</div>
                {
                    !this.state.searchedRecurring && !this.state.showBookingOptionsCard && this.state.bookingType != 'multiple' &&
                    <div className="d-flex mb-1" style={{ justifyContent: 'space-between' }}>
                        <div className="bookingTimeCard">
                            <div className="TimeCardHeader">
                                {this.labels.HubLabelStart}
                            </div>
                            <div className="TimeCardText">
                                {DateTime.fromISO(this.state.searchedFromTime).toLocaleString(DateTime.TIME_24_SIMPLE)}
                            </div>
                        </div>
                        <div className="bookingTimeCard">
                            <div className="TimeCardHeader">
                                {this.labels.HubLabelEnd}
                            </div>
                            <div className="TimeCardText">
                                {DateTime.fromISO(this.state.searchedToTime).toLocaleString(DateTime.TIME_24_SIMPLE)}
                            </div>
                        </div>
                    </div>
                }
                <IbssFormControl fullWidth className='mb-3'>
                    <IbssTextField
                        value={this.state.bookingName}
                        fullWidth
                        variant='standard'
                        required
                        onChange={e => this.setState({ bookingName: e.target.value })}
                        label={this.labels.HubLabelBookingName}
                    />
                </IbssFormControl>
                <IbssFormControl fullWidth className='mb-3'>
                    <IbssTextField
                        value={this.state.bookingDescription}
                        fullWidth
                        variant='standard'
                        onChange={e => this.setState({ bookingDescription: e.target.value })}
                        label={this.labels.HubLabelBookingDescription}
                    />
                </IbssFormControl>
                <IbssFormControl fullWidth className='mb-3'>
                    {this.state.selectedSpaceBookingPolicy.bookingAttendanceControl !== BookingAttendanceControl.hideAttendance &&
                    <IbssTextField
                        value={this.bookingAttendance()}
                        type='number'
                        onChange={e => this.setState({ bookingAttendance: parseInt(e.target.value) })}
                        label={
                            <>
                                {this.labels.funcBookingAttendance_S}
                                <IbssToolTip title={this.labels.funcBookingAttendanceToolTip_L} arrow>
                                    <Box component="span" ml={2}>
                                        <InfoIcon />
                                    </Box>
                                </IbssToolTip>
                            </>
                        }
                        size="small"
                        variant="standard"
                        fullWidth
                        inputProps={{ min: 0, pattern: '[0-9]*' }}
                        required={this.state.selectedSpaceBookingPolicy.bookingAttendanceControl === BookingAttendanceControl.showAttendanceMandatory}
                    />
                    }
                </IbssFormControl>
                {
                    this.state.selectedSpace.meetingLinkAvailable == 1 &&
                    <>
                        <IbssFormControl className='mr-4 mb-3'>
                            <IbssSwitchLabel label={this.labels.HubLabelOnlineMeetingLink} defaultChecked={false} onChange={e => this.setState({ onlineMeetingLink: e.target.checked })} />
                        </IbssFormControl>
                        <hr />
                    </>
                }
                {
                    this.userHasBookOnBehalfOfRights && (this.state.selectedOwnerType == '-1' || this.state.selectedOwnerType == '0') &&
                    <EmployeeOrVisitorPicker
                        buildingId={this.state.selectedBuildingOption}
                        delegatedBy={this.state.delegatedBy}
                        favourites={this.state.favourites}
                        onSubmit={(onBehalfOf: string, onBehalfOfData: IOnBehalfOf, autoCheckin: boolean, useOnBehalfOfCostCodes: number) => this.setState({ onBehalfOf: onBehalfOf, onBehalfOfData: onBehalfOfData, autoCheckin: autoCheckin, useOnBehalfOfCostCodes: useOnBehalfOfCostCodes })}
                    />
                }
                { this.userHasBookOnBehalfAutoCheckinOptions && this.state.selectedOwnerType != '-1' && this.state.selectedOwnerType != '0' &&
                    <>
                        <IbssFormControl className='mr-4 mb-3'>
                            <IbssSwitchLabel label={`${this.labels.HubLabelSetBookingAutoCheckIn} ${this.state.userName}`} defaultChecked={this.state.autoCheckin} onChange={e => this.setState({ autoCheckin: e.target.checked })} />
                        </IbssFormControl>
                        <hr />
                    </>
                }
                { this.state.selectedOwnerType == '0' && <>
                <Button fullWidth sx={{ justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize' }} className="row attendes cp" onClick={() => this.openVisitorDialog()}>
                   <div className="icon-text-inline pl-0">
                            <>
                                <div className="d-flex space-between mr-2">
                                    <span className="space-icon-item">
                                        <div style={{ fontSize: '26px', height: '27px' }}>
                                            <IbssSvgIcon fontSize='inherit' className="mr-2" sx={{ color: (theme) => theme.palette.text.primary }}>
                                                {Icons.AddAttendeeIcon}
                                            </IbssSvgIcon>
                                        </div>
                                        <span className="space-text-item col-text" style={{ marginLeft: '-12px' }}>{this.labels.HubLabelAddVisitorDetails}</span>
                                    </span>
                                </div>
                                <hr />
                            </>
                    </div>
                    <div className="d-flex">
                        <IbssSvgIcon fontSize='medium' className="" sx={{ color: (theme) => theme.palette.text.primary }}>
                            {Icons.RightArrowIcon}
                        </IbssSvgIcon>
                    </div>
                </Button>
                <hr />
                </>
                }
                {
                    parseInt(this.state.selectedSpace.capacity) > 1 &&
                    <BookingPartiesPicker onSubmit={(parties: Attendee[], visitors: Attendee[]) => this.setState({ bookingParties: parties, bookingPartyVisitors: visitors })} />
                }
                {
                    (this.localStorage.hasRight("API.Bookings.AssignCostCode") && this.localStorage.hasRight("API.Bookings.V2")) &&
                    <CostCodesPicker buildingId={selectedSpaceBuildingId} onSubmit={(costCodes: CostCodeWithAllocation[]) => this.setState({ costCodes: costCodes })} selectedCostCodes={this.state.costCodes} />
                }
                <div className='columnSubheading mb-3'>{this.labels.HubLabelAdvanceBookingsAdditionalBookingInfo}</div>
                {
                    (this.state.searchedRecurring || this.state.bookingType == 'multiple') &&
                    <div>
                        <h3 style={{ color: '#7A7B82' }}>{`${this.labels.funcAdvancedBookingAvailableDates_S} (${this.state.recurrenceOptions.filter(x => x.selected).length})`}</h3>
                        {
                            this.state.recurrenceOptions.map((option) =>
                            {
                                return (
                                    <div className="d-flex" style={{ marginBottom: '5px' }}>
                                        <div style={{ width: '50%', alignSelf: 'center' }}>
                                            <IbssCheckBox disabled={!this.state.selectedSpace.datesFound.includes(DateTime.fromISO(option.from).toFormat("yyyy-MM-dd")) && option.alternativeSpace == ''} checked={option.selected} label={DateTime.fromISO(option.from).toFormat("yyyy-MM-dd")} id={option.from} onClicked={(e: { target: { checked: boolean; }; }) => this.recurrenceOptionSelected(option.from)} />
                                        </div>
                                        {
                                            option.selected ?
                                                <div style={{ width: '50%' }}>
                                                    <div style={{ float: 'right', marginRight: '5px', marginTop: '9px' }}>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <div>
                                                                {DateTime.fromISO(option.from).toLocaleString(DateTime.TIME_24_SIMPLE)} - {DateTime.fromISO(option.to).toLocaleString(DateTime.TIME_24_SIMPLE)}
                                                            </div>
                                                            {
                                                                option.alternativeSpace.length > 0 &&
                                                                <div className="alternativeSpace">
                                                                    {this.getSpaceDetailsById(option.alternativeSpace)?.Space_Name}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div style={{ width: '50%' }}>
                                                    <div style={{ float: 'right', marginRight: '5px' }}>
                                                        <IbssButtonRedo
                                                            className="btn-back ml-3 col-text"
                                                            variant='contained'
                                                            color="secondary"
                                                            onClick={() => this.showAlternativeSpaces(option.from, option.to)}
                                                        >
                                                            {this.labels.HubLabelAlternative}
                                                        </IbssButtonRedo>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                <div className="mr-4 ml-4 mb-4">
                    <IbssButton
                        disabled={this.disableCreateBooking()}
                        className="mt-4"
                        variant="contained"
                        fullWidth
                        onClick={() => this.create()}>
                        {`${this.labels.HubButtonConfirmBooking} ${this.state.bookingType == 'recurring' ? `(${this.state.recurrenceOptions.filter(x => x.selected == true).length}/${this.state.recurrenceOptions.length})` : ''}`}
                    </IbssButton>
                </div>
                <CreateVisitorDialog
                        showCreateVisitorDialog={this.state.showAddVisitorModal}
                        closeClicked={(submitted) => this.visitorSubmitted(submitted ?? false)}
                        buildingId={this.state.selectedBuildingOption}
                        dates={this.state.selectedDates ?? null}
                        hostDetail={this.state.hostEmail}
                        getPayload={(payload) => this.visitorPayload(payload)}
                />
            </div>
        )

        const selectedBuildingName = this.state.buildingOptions.filter(x => x.value == this.state.selectedBuildingOption)[0]?.label
        const selectedFloorName = this.state.floorTypeOptions.filter(x => x.value == this.state.selectedFloor)[0]?.label
        const selectedZoneName = this.state.zoneOptions.filter(x => x.value == this.state.selectedZone)[0]?.label
        return (
            <main>
                {
                    this.state.creatingBookings &&
                    <LoadingOverlay />
                }
                <div>
                    <div className="rightPanel-main-content">
                        {
                            this.state.creatingSeries &&
                            <ViewSeries
                                bookingDetails={this.state.seriesBookingDetails}
                                creatingSeries={this.state.creatingSeries}
                                seriesCreationCompleted={this.state.seriesCreationCompleted}
                                failedSeriesBookings={this.state.failedSeriesBookings}
                                bookingDates={this.state.successfulSeriesBookings}
                                editRedirectRoute={(nodeId: string, bookingId: string, spaceId: string) => this.seriesBookingEditRoute(nodeId, bookingId, spaceId)}
                                allowTitleDescriptionEdit={false}
                            />
                        }
                        {
                            !this.state.creatingSeries &&
                            <div>
                                <ConfirmBookingDialog
                                    isScheduleView={false}
                                    show={this.state.showSingleBookingConfirmationModal}
                                    onReturnHomeClicked={() => this.props.history.push("/flex-home")}
                                    onMakeAnotherBookingClicked={() => this.resetStatesForNewCreate()}
                                    cateringReqs={this.state.selectedSpace.cateringAvailable ?? 0}
                                    buildingId={this.state.selectedBuildingOption}
                                    floorId={this.state.selectedSpace.nodeId}
                                    bookingId={this.state.createdBookingResponse.id}
                                    bookingStart={this.state.createdBookingResponse.start}
                                    bookingEnd={this.state.createdBookingResponse.end}
                                    bookingName={this.state.bookingName}
                                    spaceId={this.state.selectedSpace.spaceId}
                                    spaceName={this.state.selectedSpace.spaceName}
                                    hearingAidReqs={this.state.selectedSpace.hearingAidAvailable ?? 0}
                                    presentationAidReqs={this.state.selectedSpace.presentationAidAvailable ?? 0}
                                    isNewBooking={true}
                                    userCanAddTags={this.userCanAddTags}
                                    userCanReadTags={this.userCanReadTags}
                                />
                                <div className="cards-space-between">
                                    <Typography variant="h6" className="pageTitle">{this.labels.funcAdvancedBooking_Short}</Typography>
                                    <IbssButton
                                        disabled={this.state.availableSpaces.length == 0 || this.state.fetchingSpaces}
                                        variant="contained"
                                        onClick={() => this.viewMap()}
                                        aria-expanded={showMap}
                                    >
                                        {this.labels.HubLabelMap}
                                    </IbssButton>
                                </div>
                                {
                                    showMap && !this.state.mapFailedToLoad &&
                                    <div className="mt-3 mr-4" style={{ height: '75vh' }}>
                                        <FloorPlan
                                            key="map"
                                            mapUrls={this.state.mapUrls}
                                            loadSpaces={this.state.loadMap}
                                            onRequestSpaces={() => this.floorPlanSpacesRequested()}
                                            spaceModalClicked={spaceId => this.mapSpaceModalSelected(spaceId)}
                                            mapFailedToLoad={() => this.setState({ mapFailedToLoad: true })}
                                            floorId={this.state.selectedFloor}
                                            startTime={DateTime.fromISO(this.state.searchedFromTime).setZoneByNode(this.state.selectedBuildingOption)}
                                            endTime={DateTime.fromISO(this.state.searchedToTime).setZoneByNode(this.state.selectedBuildingOption)}
                                            enableSpaceClick={true}
                                            displayFloorDropdown={this.state.displayMapFloorDropdown}
                                            onFloorSelected={(floorId: number) => this.mapViewFloorDropdownChange(floorId)}
                                        />
                                    </div>
                                }
                                {
                                    showMap && this.state.mapFailedToLoad &&
                                    <Alert key="mapFailedToLoad" title={this.labels.HubmapFailedToLoad} text={this.labels.HubLabelUsingTheListView} />
                                }
                                <SelectMultipleDatesModal onClose={() => this.setState({ showMultipleDateSelectModal: false })} selectedDates={this.state.blockBookingDates} onAdd={(from, to) => this.addDateToBlockBookings(from, to)} open={this.state.showMultipleDateSelectModal} />
                                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <div className="recurring-card-flex">
                                        <Grid xs={4} minWidth={370}>
                                            <div id="column1" aria-label="search criteria column" className="m-3">
                                                <Card className="recurring-card">
                                                    {/* Column 1 - Criteria */}
                                                    <div className="m-4">
                                                        <Typography variant="h5" className='columnHeading'>{this.labels.funcAdvancedBookingsRefineSearch_S}</Typography>
                                                        <hr />
                                                        {/* { COMMENTED OUT UNTIL API ALLOWS FOR WORK TYPE
                                                        !this.state.addRecurrenceChecked &&
                                                        <div>
                                                            <div className='columnSubheading mb-3'>{this.labels.HubLabelWhatDoYouWantToBook}</div>
                                                            <IbssFormControl className='mb-3' fullWidth>
                                                                <IbssInputDropDown
                                                                    error={this.state.selectedWorkTypeError}
                                                                    value={this.state.selectedWorkType}
                                                                    inputLabel={this.labels.HubLabelworkType}
                                                                    id='workTypeSelect'
                                                                    options={this.state.workTypeOptions}
                                                                    onChange={e => this.workTypeChanged(e.target.value)}
                                                                />
                                                            </IbssFormControl>
                                                        </div>
                                                    } */}
                                                        <div>
                                                            <div className='columnSubheading mb-3'>{this.labels.funcAdvancedBookingsWho_L}</div>
                                                            <IbssFormControl fullWidth>
                                                                <IbssInputDropDown
                                                                    value={this.state.selectedOwnerType}
                                                                    inputLabel={this.labels.funcBookingOwner_S}
                                                                    id='ownerTypeSelect'
                                                                    options={this.state.ownerTypeOptions}
                                                                    onChange={e => this.ownerTypeChanged(e.target.value)}
                                                                />
                                                            </IbssFormControl>
                                                        </div>
                                                        <hr />
                                                        <div className='mb-2 d-flex justify-content-between'>
                                                            <div className="d-flex">
                                                                <div className="columnSubheading" style={{ alignContent: 'center' }}>{this.labels.funcAdvancedBookingsWhere_L}</div>
                                                                {
                                                                    (selectedBuildingName || selectedFloorName || selectedZoneName) && !this.state.showLocationFormFields &&
                                                                    <div style={{ marginLeft: '14px', alignContent: 'center' }}>
                                                                        <IbssToolTip title={this.labels.funcAdvancedBookingWhereTooltip_Message} arrow >
                                                                            <Box component="span" mr={1.5}>
                                                                                <InfoIcon />
                                                                            </Box>
                                                                        </IbssToolTip>
                                                                    </div>
                                                                }
                                                            </div>
                                                            {
                                                                (selectedBuildingName || selectedFloorName || selectedZoneName) && !this.state.showLocationFormFields &&
                                                                <IbssButton disabled={this.state.fetchingSpaces} onClick={() => this.setState({ showLocationFormFields: true })}>{this.labels.HubButtonEdit}</IbssButton>
                                                            }
                                                        </div>
                                                        {
                                                            (selectedBuildingName || selectedFloorName || selectedZoneName) && !this.state.showLocationFormFields ?
                                                                <div className="d-flex flex-wrap">
                                                                    {
                                                                        selectedBuildingName &&
                                                                        <IbssChip sx={{ margin: '5px' }} label={selectedBuildingName} />
                                                                    }
                                                                    {
                                                                        selectedFloorName &&
                                                                        <IbssChip sx={{ margin: '5px' }} label={selectedFloorName} />
                                                                    }
                                                                    {
                                                                        selectedZoneName &&
                                                                        <IbssChip sx={{ margin: '5px' }} label={selectedZoneName} />
                                                                    }
                                                                </div>
                                                                :
                                                                <div>
                                                                    <IbssFormControl className='mt-1 mb-3' fullWidth>
                                                                        <IbssInputDropDown
                                                                            disabled={this.state.fetchingSpaces}
                                                                            error={this.state.buildingSelectError}
                                                                            value={this.state.selectedBuildingOption}
                                                                            inputLabel={this.labels.HubLabelBuilding}
                                                                            id='buildingSelect'
                                                                            options={this.state.buildingOptions}
                                                                            onChange={e => this.buildingSelected(e.target.value)}
                                                                        />
                                                                    </IbssFormControl>
                                                                    <IbssFormControl className='mb-3' fullWidth>
                                                                        <IbssInputDropDown
                                                                            value={this.state.selectedFloor}
                                                                            disabled={this.state.selectedBuildingOption == 0 || this.state.fetchingSpaces}
                                                                            inputLabel={this.labels.HubLabelFloor}
                                                                            id='floorSelect'
                                                                            options={[{ label: this.labels.HubLabelAny, value: 0 }, ...this.state.floorTypeOptions]}
                                                                            onChange={e => this.floorSelected(e.target.value)}
                                                                        />
                                                                    </IbssFormControl>
                                                                    <IbssFormControl fullWidth>
                                                                        <IbssInputDropDown
                                                                            value={this.state.selectedZone}
                                                                            disabled={this.state.selectedFloor == 0 || this.state.fetchingSpaces}
                                                                            inputLabel={this.labels.HubLabelZone}
                                                                            id='zoneSelect'
                                                                            options={[{ label: this.labels.HubLabelAny, value: 'any' }, ...this.state.zoneOptions]}
                                                                            onChange={e => this.zoneChanged(e.target.value)}
                                                                        />
                                                                    </IbssFormControl>
                                                                </div>
                                                        }
                                                        <hr />
                                                        <div>
                                                            <div className='columnSubheading mb-3'>{this.labels.funcAdvancedBookingsWhat_L}</div>
                                                            <IbssFormControl fullWidth>
                                                                <IbssInputDropDown
                                                                    disabled={this.state.fetchingSpaces}
                                                                    error={this.state.spaceTypeError}
                                                                    value={this.state.selectedSpaceType}
                                                                    inputLabel={this.labels.HubLabelSpaceType}
                                                                    id='spaceTypeSelect'
                                                                    options={this.state.spaceTypeOptions}
                                                                    onChange={e => this.spaceTypeChanged(e.target.value)}
                                                                />
                                                            </IbssFormControl>
                                                        </div>
                                                        {
                                                            this.state.showCapacityField &&
                                                            <IbssFormControl fullWidth className='mt-3'>
                                                                <IbssTextField
                                                                    disabled={this.state.fetchingSpaces}
                                                                    value={this.state.capacity}
                                                                    fullWidth
                                                                    label={this.labels.HubLabelCapacity}
                                                                    id='capacityTextfield'
                                                                    type="number"
                                                                    onChange={e => this.capacityUpdated(e.target.value)}
                                                                />
                                                            </IbssFormControl>
                                                        }
                                                        <hr />
                                                        <p className='columnSubheading mb-3'>{this.labels.funcAdvancedBookingsWhen_L} { this.state.userBypassingPolicies && (this.state.selectedOwnerType == '-1' || this.state.selectedOwnerType == '0') && <><mark style={{ background: 'unset' }}>-</mark><mark style={{ color: 'red', background: 'unset' }}> {this.labels.funcBypassingPolicies_Message}</mark></>}</p>
                                                        <div className="d-flex flex-wrap">
                                                            {
                                                                this.state.showBookAsapSwitch &&
                                                                <Box>
                                                                    <IbssFormControl fullWidth className='mr-4 mb-3'>
                                                                        <Box display={"flex"} alignItems={'center'}>
                                                                            <Box>
                                                                                <IbssRadioButton
                                                                                    value={this.state.bookingType}
                                                                                    horizontal
                                                                                    valueChanged={() => this.bookingTypeUpdated('singular')}
                                                                                    option={
                                                                                        [
                                                                                            { label: this.labels.funcAdvancedBookingsSingular_S, value: 'singular', labelplacement: 'end', disabled: this.state.fetchingSpaces }
                                                                                        ]
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                            <IbssToolTip title={this.labels.funcAsSoonAsPossible_D} arrow >
                                                                                <Box component="span" mr={1.5}>
                                                                                    <InfoIcon />
                                                                                </Box>
                                                                            </IbssToolTip>
                                                                        </Box>
                                                                    </IbssFormControl>
                                                                </Box>
                                                            }
                                                            {
                                                                this.state.showAddRecurrenceSwitch &&
                                                                <Box>
                                                                    <IbssFormControl className='mr-4 mb-3' fullWidth>
                                                                        <Box display={"flex"} alignItems={'center'}>
                                                                            <Box>
                                                                                <IbssRadioButton
                                                                                    value={this.state.bookingType}
                                                                                    horizontal
                                                                                    valueChanged={() => this.bookingTypeUpdated('recurring')}
                                                                                    option={
                                                                                        [
                                                                                            { label: this.labels.funcAdvancedBookingRecurring_S, value: 'recurring', labelplacement: 'end', disabled: this.state.fetchingSpaces }
                                                                                        ]
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                            <IbssToolTip title={this.labels.funcAddRecurrence_D} arrow >
                                                                                <Box component="span" mr={1.5}>
                                                                                    <InfoIcon />
                                                                                </Box>
                                                                            </IbssToolTip>
                                                                        </Box>
                                                                    </IbssFormControl>
                                                                </Box>
                                                            }
                                                            {
                                                                this.state.showBookMultipleSwitch &&
                                                                <Box>
                                                                    <IbssFormControl className='mr-4 mb-3' fullWidth>
                                                                        <Box display={"flex"} alignItems={'center'}>
                                                                            <Box>
                                                                                <IbssRadioButton
                                                                                    value={this.state.bookingType}
                                                                                    horizontal
                                                                                    valueChanged={() => this.bookingTypeUpdated('multiple')}
                                                                                    option={
                                                                                        [
                                                                                            { label: this.labels.funcAdvancedBookingMultiple_S, value: 'multiple', labelplacement: 'end', disabled: this.state.fetchingSpaces }
                                                                                        ]
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                            <IbssToolTip title={this.labels.funcAdvancedBookingsMultipleBookingsInfo_Message} arrow >
                                                                                <Box component="span" mr={1.5}>
                                                                                    <InfoIcon />
                                                                                </Box>
                                                                            </IbssToolTip>
                                                                        </Box>
                                                                    </IbssFormControl>
                                                                </Box>
                                                            }
                                                        </div>
                                                        {
                                                            this.state.bookingType == 'multiple' &&
                                                            <Box>
                                                                <div className="d-flex justify-content-between mb-1 mt-1" style={{ alignItems: 'center' }}>
                                                                    <div>{this.labels.funcAdvancedBookingAddDateTimes_Message}</div>
                                                                    <IbssIconButton
                                                                        color="primary"
                                                                        className="ml-1"
                                                                        aria-label="add"
                                                                        onClick={() => this.setState({ showMultipleDateSelectModal: true })}
                                                                    >
                                                                        <Add />
                                                                    </IbssIconButton>
                                                                </div>
                                                                <div className="mb-1">
                                                                    {this.state.blockBookingDates.map(x =>
                                                                    {
                                                                        return (
                                                                            <div key={x.from.toISO()} className="d-flex justify-content-between">
                                                                                <div className="d-flex">
                                                                                    <IbssSvgIcon sx={{ paddingTop: '2px', paddingRight: '10px' }} fontSize="small">
                                                                                        {Icons.TimeIcon}
                                                                                    </IbssSvgIcon>
                                                                                    <div>
                                                                                        {x.to.toLocaleString(DateTime.DATE_FULL)}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    {x.from.toLocaleString(DateTime.TIME_24_SIMPLE)} - {x.to.toLocaleString(DateTime.TIME_24_SIMPLE)}
                                                                                    <div className="ml-2" style={{ height: '20px', width: '20px', paddingTop: '5px' }} onClick={() => this.removeBlockBookingDate(x.from)}>
                                                                                        <CloseIcon />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    )}
                                                                </div>
                                                                <hr />
                                                            </Box>
                                                        }
                                                        {
                                                            this.state.bookingType != 'multiple' &&
                                                            <Grid container>
                                                                {
                                                                    this.state.bookingType != 'recurring' &&
                                                                    <Grid item xs={this.state.bookSoonAsPossibleChecked ? 6 : 12} sx={{ alignContent: 'center' }}>
                                                                        <div>
                                                                            <IbssCheckBox
                                                                                disabled={this.state.fetchingSpaces}
                                                                                label={this.labels.HubLabelAsSoonAsPossible}
                                                                                checked={this.state.bookSoonAsPossibleChecked}
                                                                                onClicked={(e: { target: { checked: boolean; }; }) => this.bookAsSoonAsPossibleSelected(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </Grid>
                                                                }
                                                                {
                                                                    !this.state.bookSoonAsPossibleChecked &&
                                                                    <Grid item xs={12}>
                                                                        <div>

                                                                            <IbssFormControl fullWidth className='mb-2'>
                                                                                <IbssDatePicker
                                                                                    disabled={this.state.fetchingSpaces}
                                                                                    disablePast
                                                                                    label={this.labels.HubLabelDate}
                                                                                    value={startDate}
                                                                                    onChange={e => this.startDateChanged(e?.toJSDate() as Date)}
                                                                                    slotProps={{ textField: { size: 'medium' } }}
                                                                                    shouldDisableDate={(date) => this.bookingSlotHelper.disableExcludedDates(date, this.state.bookingPolicies, this.state.selectedFloor)}
                                                                                />
                                                                            </IbssFormControl>
                                                                        </div>
                                                                    </Grid>
                                                                }
                                                                {
                                                                    !this.state.bookSoonAsPossibleChecked &&
                                                                    <Grid item xs={6}>
                                                                        <Box style={{ alignSelf: 'center', marginRight: '5px' }}>
                                                                            <div style={{ fontSize: '20px' }}>
                                                                                <IbssTimePicker
                                                                                    disabled={this.state.fetchingSpaces}
                                                                                    className='ibss-timepicker'
                                                                                    value={fromTime}
                                                                                    label={this.labels.HubLabelFrom}
                                                                                    onChange={time => this.fromTimeUpdated(dayjs(time?.toJSDate()) as (Dayjs | null))}
                                                                                    ampm={false}
                                                                                    minutesStep={1}
                                                                                    slotProps={{ textField: { fullWidth: true } }}
                                                                                />
                                                                            </div>
                                                                        </Box>
                                                                    </Grid>
                                                                }
                                                                <Grid item xs={6}>
                                                                    <div style={{ alignSelf: 'center' }}>
                                                                        <div style={{ fontSize: '23px' }}>
                                                                            <IbssTimePicker
                                                                                disabled={this.state.fetchingSpaces}
                                                                                className='ibss-timepicker'
                                                                                value={toTime}
                                                                                label={this.labels.HubLabelTo}
                                                                                onChange={time => this.toTimeUpdated(dayjs(time?.toJSDate()) as (Dayjs | null))}
                                                                                ampm={false}
                                                                                minutesStep={1}
                                                                                slotProps={{ textField: { fullWidth: true } }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                        {
                                                            this.state.bookingType == 'recurring' &&
                                                            <div>
                                                                <hr />
                                                                <IbssFormControl className='mb-3' fullWidth>
                                                                    <IbssInputDropDown
                                                                        disabled={this.state.fetchingSpaces}
                                                                        error={this.state.noRecurrencePatternSelected}
                                                                        value={recurrencePattern}
                                                                        inputLabel={this.labels.HubLabelRecurrencePattern}
                                                                        id='recurrencePatternSelect'
                                                                        options={[{ label: this.labels.HubLabelDaily, value: 'daily' }, { label: this.labels.HubLabelWeekly, value: 'weekly' }, { label: this.labels.HubLabelMonthly, value: 'monthly' },]}
                                                                        onChange={e => this.recurrencePatternUpdated(e.target.value)}
                                                                    />
                                                                </IbssFormControl>
                                                                {
                                                                    recurrencePattern == 'daily' &&
                                                                    <div>
                                                                        <p className={`subText ${this.state.noDailyRecurrenceFrequencySelected ? 'text-danger' : ''}`}>{this.labels.HubLabelRepeatEvery}</p>
                                                                        <div className='ml-3 behaviours-radioBtns d-flex'>
                                                                            <div style={{ paddingTop: '8px' }}>
                                                                                <IbssRadioButton
                                                                                    value={dailyRecurrenceFrequency}
                                                                                    horizontal
                                                                                    valueChanged={(value: string) =>
                                                                                    {
                                                                                        this.setState({ dailyRecurrenceFrequency: value });
                                                                                        this.searchFormUpdated();
                                                                                    }}
                                                                                    option={[{ label: this.labels.HubLabelEvery, value: 'every', labelplacement: 'end', disabled: this.state.fetchingSpaces }]}
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <IbssFormControl className='mb-3'>
                                                                                    <IbssTextField
                                                                                        disabled={dailyRecurrenceFrequency !== 'every' || this.state.fetchingSpaces}
                                                                                        value={dailyRecurrenceFrequencyValue}
                                                                                        type='number'
                                                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, width: '75px' }}
                                                                                        onChange={e =>
                                                                                        {
                                                                                            this.setState({ dailyRecurrenceFrequencyValue: (parseInt(e.target.value) < 1 || e.target.value == '') ? 1 : parseInt(e.target.value) > 364 ? 364 : parseInt(e.target.value) });
                                                                                            this.searchFormUpdated();
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                </IbssFormControl>
                                                                            </div>
                                                                            <div style={{ margin: '16px 0px 0px 15px' }}>
                                                                                {this.labels.HubLabelRecurringSpaceDays}
                                                                            </div>
                                                                        </div>
                                                                        <div className='ml-3 behaviours-radioBtns d-flex'>
                                                                            <div style={{ paddingTop: '8px' }}>
                                                                                <IbssRadioButton
                                                                                    value={dailyRecurrenceFrequency}
                                                                                    horizontal
                                                                                    valueChanged={(value: string) =>
                                                                                    {
                                                                                        this.setState({ dailyRecurrenceFrequency: value })
                                                                                        this.searchFormUpdated();
                                                                                    }
                                                                                    }
                                                                                    option={[{ label: this.labels.HubLabelEveryWeekday, value: 'everyWeekday', labelplacement: 'end', disabled: this.state.fetchingSpaces }]}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.weeklyRecurrenceError && recurrencePattern == 'weekly' &&
                                                                    <p className="subText text-danger" style={{ margin: 0 }}>{this.labels.HubLabelPleaseSelectOption}</p>
                                                                }
                                                                {
                                                                    recurrencePattern == 'weekly' &&
                                                                    <div className="ml-2 mr-2" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                        <IbssCheckBox disabled={this.state.fetchingSpaces} label={this.labels.HubLabelMonday} id={this.labels.HubLabelMonday} onClicked={(e: { target: { checked: boolean; }; }) => { this.setState({ daysSelected: { ...this.state.daysSelected, monday: e.target.checked } }); this.searchFormUpdated(); }} />
                                                                        <IbssCheckBox disabled={this.state.fetchingSpaces} label={this.labels.HubLabelTuesday} id={this.labels.HubLabelTuesday} onClicked={(e: { target: { checked: boolean; }; }) => { this.setState({ daysSelected: { ...this.state.daysSelected, tuesday: e.target.checked } }); this.searchFormUpdated(); }} />
                                                                        <IbssCheckBox disabled={this.state.fetchingSpaces} label={this.labels.HubLabelWednesday} id={this.labels.HubLabelWednesday} onClicked={(e: { target: { checked: boolean; }; }) => { this.setState({ daysSelected: { ...this.state.daysSelected, wednesday: e.target.checked } }); this.searchFormUpdated(); }} />
                                                                        <IbssCheckBox disabled={this.state.fetchingSpaces} label={this.labels.HubLabelThursday} id={this.labels.HubLabelThursday} onClicked={(e: { target: { checked: boolean; }; }) => { this.setState({ daysSelected: { ...this.state.daysSelected, thursday: e.target.checked } }); this.searchFormUpdated(); }} />
                                                                        <IbssCheckBox disabled={this.state.fetchingSpaces} label={this.labels.HubLabelFriday} id={this.labels.HubLabelFriday} onClicked={(e: { target: { checked: boolean; }; }) => { this.setState({ daysSelected: { ...this.state.daysSelected, friday: e.target.checked } }); this.searchFormUpdated(); }} />
                                                                        <IbssCheckBox disabled={this.state.fetchingSpaces} label={this.labels.HubLabelSaturday} id={this.labels.HubLabelSaturday} onClicked={(e: { target: { checked: boolean; }; }) => { this.setState({ daysSelected: { ...this.state.daysSelected, saturday: e.target.checked } }); this.searchFormUpdated(); }} />
                                                                        <IbssCheckBox disabled={this.state.fetchingSpaces} label={this.labels.HubLabelSunday} id={this.labels.HubLabelSunday} onClicked={(e: { target: { checked: boolean; }; }) => { this.setState({ daysSelected: { ...this.state.daysSelected, sunday: e.target.checked } }); this.searchFormUpdated(); }} />
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.noMonthlyRecurrenceOptionSelected && recurrencePattern == 'monthly' &&
                                                                    <p className="subText text-danger" style={{ marginTop: 0 }}>{this.labels.HubLabelPleaseSelectOption}</p>
                                                                }
                                                                {
                                                                    recurrencePattern == 'monthly' &&
                                                                    <div>
                                                                        <div className='ml-3 behaviours-radioBtns d-flex' style={{ flexWrap: 'wrap' }}>
                                                                            <div style={{ paddingTop: '8px' }}>
                                                                                <IbssRadioButton
                                                                                    value={this.state.monthlyRecurrenceFrequencyOption}
                                                                                    horizontal
                                                                                    valueChanged={(value: string) => { this.setState({ monthlyRecurrenceFrequencyOption: value }); this.searchFormUpdated(); }}
                                                                                    option={[{ label: this.labels.HublabelDay, value: 'multipleDays', labelplacement: 'end', disabled: this.state.fetchingSpaces }]}
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <IbssFormControl className='mb-3'>
                                                                                    <IbssTextField
                                                                                        error={this.state.monthlyRecurrenceDayValueError}
                                                                                        disabled={this.state.monthlyRecurrenceFrequencyOption !== 'multipleDays' || this.state.fetchingSpaces}
                                                                                        value={this.state.monthlyRecurrenceDayValue}
                                                                                        type='number'
                                                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, width: '63px' }}
                                                                                        onChange={e => { this.setState({ monthlyRecurrenceDayValue: parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value) > 31 ? 31 : parseInt(e.target.value) }); this.searchFormUpdated(); }}
                                                                                    />
                                                                                </IbssFormControl>
                                                                            </div>
                                                                            <div style={{ margin: '16px 15px 0px 15px' }}>
                                                                                {this.labels.HubLabelOfEvery}
                                                                            </div>
                                                                            <div>
                                                                                <IbssFormControl className='mb-3'>
                                                                                    <IbssTextField
                                                                                        error={this.state.monthlyRecurrenceMonthValueError}
                                                                                        disabled={this.state.monthlyRecurrenceFrequencyOption !== 'multipleDays' || this.state.fetchingSpaces}
                                                                                        value={this.state.monthlyRecurrenceMonthValue}
                                                                                        type='number'
                                                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, width: '63px' }}
                                                                                        onChange={e => { this.setState({ monthlyRecurrenceMonthValue: parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value) > 52 ? 52 : parseInt(e.target.value) }); this.searchFormUpdated(); }}
                                                                                    />
                                                                                </IbssFormControl>
                                                                            </div>
                                                                            <div style={{ margin: '16px 0px 0px 15px' }}>
                                                                                {this.labels.HubLabelMonths}
                                                                            </div>
                                                                        </div>
                                                                        <div className='ml-3 behaviours-radioBtns d-flex' style={{ flexWrap: 'wrap' }}>
                                                                            <div style={{ paddingTop: '8px' }}>
                                                                                <IbssRadioButton
                                                                                    value={this.state.monthlyRecurrenceFrequencyOption}
                                                                                    horizontal
                                                                                    valueChanged={(value: string) => { this.setState({ monthlyRecurrenceFrequencyOption: value }); this.searchFormUpdated(); }}
                                                                                    option={[{ label: this.labels.HubLabelThe, value: 'specificDays', labelplacement: 'end', disabled: this.state.fetchingSpaces }]}
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <IbssFormControl className='mb-3 mr-1' sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}>
                                                                                    <IbssInputDropDown
                                                                                        error={this.state.noMonthlyRecurrenceFrequencyValueSelected}
                                                                                        minWidth={100}
                                                                                        disabled={this.state.monthlyRecurrenceFrequencyOption !== 'specificDays' || this.state.fetchingSpaces}
                                                                                        value={this.state.monthlyRecurrenceFrequencyDropdownValue}
                                                                                        id="dayFrequencyDropdown"
                                                                                        options={[
                                                                                            { label: this.labels.HubLabelFirst, value: 'first' },
                                                                                            { label: this.labels.HubLabelSecond, value: 'second' },
                                                                                            { label: this.labels.HubLabelThird, value: 'third' },
                                                                                            { label: this.labels.HubLabelLast, value: 'last' },
                                                                                        ]}
                                                                                        onChange={e => { this.setState({ monthlyRecurrenceFrequencyDropdownValue: e.target.value }); this.searchFormUpdated(); }}
                                                                                    />
                                                                                </IbssFormControl>
                                                                            </div>
                                                                            <div>
                                                                                <IbssFormControl className='mb-3' sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}>
                                                                                    <IbssInputDropDown
                                                                                        error={this.state.noMonthlyRecurrenceDayValueSelected}
                                                                                        minWidth={100}
                                                                                        disabled={this.state.monthlyRecurrenceFrequencyOption !== 'specificDays' || this.state.fetchingSpaces}
                                                                                        value={this.state.monthlyRecurrenceDayDropdownValue}
                                                                                        id="weekdayDropdown"
                                                                                        options={[
                                                                                            { label: this.labels.HubLabelMonday, value: 'monday' },
                                                                                            { label: this.labels.HubLabelTuesday, value: 'tuesday' },
                                                                                            { label: this.labels.HubLabelWednesday, value: 'wednesday' },
                                                                                            { label: this.labels.HubLabelThursday, value: 'thursday' },
                                                                                            { label: this.labels.HubLabelFriday, value: 'friday' },
                                                                                            { label: this.labels.HubLabelSaturday, value: 'saturday' },
                                                                                            { label: this.labels.HubLabelSunday, value: 'sunday' },
                                                                                        ]}
                                                                                        onChange={e => { this.setState({ monthlyRecurrenceDayDropdownValue: e.target.value }); this.searchFormUpdated(); }}
                                                                                    />
                                                                                </IbssFormControl>
                                                                            </div>
                                                                            <div style={{ margin: '16px 0px 0px 15px' }}>
                                                                                {this.labels.HubLabelOf}
                                                                            </div>
                                                                            <div style={{ margin: '16px 15px 0px 5px' }}>
                                                                                {this.labels.HubLabelEvery.toLocaleLowerCase()}
                                                                            </div>
                                                                            <div>
                                                                                <IbssFormControl className='mb-3'>
                                                                                    <IbssTextField
                                                                                        error={this.state.monthlyRecurrenceEveryMonthValueError}
                                                                                        disabled={this.state.monthlyRecurrenceFrequencyOption !== 'specificDays' || this.state.fetchingSpaces}
                                                                                        value={this.state.monthlyRecurrenceEveryMonthValue}
                                                                                        type='number'
                                                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, width: '63px' }}
                                                                                        onChange={e => { this.setState({ monthlyRecurrenceEveryMonthValue: parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value) > 52 ? 52 : parseInt(e.target.value) }); this.searchFormUpdated(); }}
                                                                                    />
                                                                                </IbssFormControl>
                                                                            </div>
                                                                            <div style={{ margin: '16px 0px 0px 15px' }}>
                                                                                {this.labels.HubLabelMonths}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <p className={`subText ${this.state.noEndTypeSelected ? 'text-danger' : ''}`}>{this.labels.HubLabelEnds}</p>
                                                                <div className='ml-3 behaviours-radioBtns d-flex'>
                                                                    <div style={{ paddingTop: '8px', width: '30%' }}>
                                                                        <IbssRadioButton
                                                                            value={endType}
                                                                            horizontal
                                                                            valueChanged={(value: string) => { this.setState({ endType: value }); this.searchFormUpdated(); }}
                                                                            option={[{ label: this.labels.HubLabelOn, value: 'On', labelplacement: 'end', disabled: this.state.fetchingSpaces }]}
                                                                        />
                                                                    </div>
                                                                    <div style={{ width: '60%' }}>
                                                                        <IbssFormControl fullWidth className='mb-3'>
                                                                            <IbssDatePicker
                                                                                disabled={endType != 'On' || this.state.fetchingSpaces}
                                                                                disablePast
                                                                                value={endDate}
                                                                                onChange={(e) => this.endDateChanged(e?.toJSDate() as Date)}
                                                                                slotProps={{ textField: { error: this.state.startGreaterThanRecurringEnd, size: 'medium' } }}
                                                                            />
                                                                        </IbssFormControl>
                                                                    </div>
                                                                </div>
                                                                <div className='ml-3 behaviours-radioBtns d-flex'>
                                                                    <div style={{ paddingTop: '8px', width: '30%' }}>
                                                                        <IbssRadioButton
                                                                            value={endType}
                                                                            horizontal
                                                                            valueChanged={(value: string) => { this.setState({ endType: value }); this.searchFormUpdated(); }}
                                                                            option={[{ label: this.labels.HubLabelAfter, value: 'After', labelplacement: 'end', disabled: this.state.fetchingSpaces }]}
                                                                        />
                                                                    </div>
                                                                    <div style={{ width: '60%' }}>
                                                                        <IbssFormControl fullWidth className='mb-3'>
                                                                            <IbssTextField
                                                                                disabled={recurrencePattern == '' || endType != 'After' || this.state.fetchingSpaces}
                                                                                value={numberOfOccurences}
                                                                                fullWidth
                                                                                variant='standard'
                                                                                type='number'
                                                                                onChange={e => { this.setState({ numberOfOccurences: parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value) > 52 ? 52 : parseInt(e.target.value) }); this.searchFormUpdated(); }}
                                                                                label={this.labels.HubLabelOccurrences}
                                                                            />
                                                                        </IbssFormControl>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            Object.values(this.state.selectedSpaceConfig).includes(true) &&
                                                            <IbssButton
                                                                className="mb-1"
                                                                fullWidth
                                                                onClick={() => this.setState({ showMoreSearchOptions: !this.state.showMoreSearchOptions })}>
                                                                {this.state.showMoreSearchOptions ? '- ' + this.labels.HubLabelShowLessSearchOptions : '+ ' + this.labels.HubLabelShowMoreSearchOptions}
                                                            </IbssButton>
                                                        }
                                                        {
                                                            this.state.showMoreSearchOptions &&
                                                            <div>
                                                                {/* COMMENTED OUT BELOW UNTIL DECISION MADE ON WHERE TO MAP VALUES TO */}
                                                                {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                {
                                                                    this.state.selectedSpaceConfig.showTemperature &&
                                                                    <IbssFormControl className='mb-3' fullWidth>
                                                                        <IbssInputDropDown
                                                                            value={this.state.selectedTemperature}
                                                                            inputLabel={this.labels.HubLabelTemperature}
                                                                            id='temperatureSelect'
                                                                            options={
                                                                                [
                                                                                    { label: this.labels.HubLabelAny, value: 'any' },
                                                                                    { label: this.labels.HubLabelNormal, value: 'normal' },
                                                                                    { label: this.labels.HubLabelCooler, value: 'cooler' },
                                                                                    { label: this.labels.HubLabelWarmer, value: 'warmer' },
                                                                                ]
                                                                            }
                                                                            onChange={e => this.setState({ selectedTemperature: e.target.value })}
                                                                        />
                                                                    </IbssFormControl>
                                                                }
                                                                {
                                                                    this.state.selectedSpaceConfig.showNoise &&
                                                                    <IbssFormControl className='mb-3' fullWidth>
                                                                        <IbssInputDropDown
                                                                            value={this.state.selectedSound}
                                                                            inputLabel={this.labels.HubLabelSound}
                                                                            id='soundSelect'
                                                                            options={
                                                                                [
                                                                                    { label: this.labels.HubLabelAny, value: 'any' },
                                                                                    { label: this.labels.HubLabelNormal, value: 'normal' },
                                                                                    { label: this.labels.HubLabelSilent, value: 'silent' },
                                                                                    { label: this.labels.HubLabelQuiet, value: 'quiet' },
                                                                                ]
                                                                            }
                                                                            onChange={e => this.setState({ selectedSound: e.target.value })}
                                                                        />
                                                                    </IbssFormControl>
                                                                }
                                                            </div> */}
                                                                {
                                                                    this.state.bookingType != 'recurring' && this.state.showLinkedSpaceSwitch &&
                                                                    <IbssFormControl className='mr-4 mt-3' fullWidth disabled={this.state.fetchingSpaces}>
                                                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={'center'}>
                                                                            <Box>
                                                                                <IbssSwitchLabel label={this.labels.HubLabelLinkedSpaces} defaultChecked={false} onChange={e => this.linkedSpaceToggleSelected(e.target.checked)} />
                                                                            </Box>
                                                                            <IbssToolTip title={this.labels.funcLinkedSpaces_D} arrow >
                                                                                <Box component="span" mr={1.5}>
                                                                                    <InfoIcon />
                                                                                </Box>
                                                                            </IbssToolTip>
                                                                        </Box>
                                                                    </IbssFormControl>
                                                                }
                                                                {this.state.selectedSpaceConfig.showAV &&
                                                                    <div>
                                                                        <p className='subText'>{this.labels.HubLabelSpaceFeatures}:</p>
                                                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                            <IbssFormControl disabled={this.state.fetchingSpaces} className='mr-4'><IbssSwitchLabel label={this.labels.HubLabeltvAv} defaultChecked={false} onChange={e => { this.setState({ avChecked: e.target.checked }); this.searchFormUpdated(); }} /></IbssFormControl>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {(this.state.selectedSpaceConfig.showCatering || this.state.selectedSpaceConfig.showHearingAid || this.state.selectedSpaceConfig.showPresentationAid) &&
                                                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={'center'}>
                                                                        <Box>
                                                                            <p className='subText'>{this.labels.HubLabelAddOns}:</p>
                                                                        </Box>
                                                                        <IbssToolTip title={this.labels.funcAddOns_D} arrow >
                                                                            <Box component="span" mr={1.5}>
                                                                                <InfoIcon />
                                                                            </Box>
                                                                        </IbssToolTip>
                                                                    </Box>
                                                                }
                                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                    {this.state.selectedSpaceConfig.showCatering &&
                                                                        <IbssFormControl disabled={this.state.fetchingSpaces} className='mr-4'><IbssSwitchLabel label={this.labels.HubMenuCatering} defaultChecked={false} onChange={e => { this.setState({ cateringChecked: e.target.checked }); this.searchFormUpdated(); }} /></IbssFormControl>
                                                                    }
                                                                    {this.state.selectedSpaceConfig.showHearingAid &&
                                                                        <IbssFormControl disabled={this.state.fetchingSpaces} className='mr-4'><IbssSwitchLabel label={this.labels.HubLabelHearingAid} defaultChecked={false} onChange={e => { this.setState({ hearingAidChecked: e.target.checked }); this.searchFormUpdated(); }} /></IbssFormControl>
                                                                    }
                                                                    {this.state.selectedSpaceConfig.showPresentationAid &&
                                                                        <IbssFormControl disabled={this.state.fetchingSpaces} className='mr-4'><IbssSwitchLabel label={this.labels.HubLabelPresentationAid} defaultChecked={false} onChange={e => { this.setState({ presentationAidChecked: e.target.checked }); this.searchFormUpdated(); }} /></IbssFormControl>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </Card>
                                                <Card style={{ height: '80px', marginTop: '2px', borderRadius: '0px' }}>
                                                    <div className="mr-4 ml-4">
                                                        <IbssButton
                                                            size="large"
                                                            className="mt-3"
                                                            variant="contained"
                                                            fullWidth
                                                            disabled={this.state.fetchingSpaces}
                                                            onClick={() => this.search()}>
                                                            {this.labels.HubLabelSearch}
                                                        </IbssButton>
                                                        <Box sx={visuallyHidden}>
                                                            <Typography aria-live="polite">
                                                                {this.state.availableSpaces.length > 0 ? `${this.state.availableSpaces.length} ${this.labels.funcResultsFound_S}` : `${this.labels.funcNoResultsFound_S}`}
                                                            </Typography>
                                                        </Box>
                                                    </div>
                                                </Card>
                                            </div>
                                        </Grid>
                                        <Grid xs={4} minWidth={370}>
                                            <div id="column2" aria-label="search results column" className="m-3">
                                                {
                                                    this.state.showSearchResultsColumn ?
                                                        <div id="column2" aria-label="search results column" className="m-3">
                                                            <Card
                                                                onScroll={() => this.spaceSearchScroll()}
                                                                ref={this.spaceResultsContainer}
                                                                style={{
                                                                    height: 'calc(100vh - 196px)',
                                                                    overflow: 'auto',
                                                                    borderRadius: '8px'
                                                                }}
                                                            >
                                                                {/* Column 2 - Results */}
                                                                {
                                                                    this.state.fetchingSpaces ?
                                                                        <div className="m-4" style={{ position: 'relative' }}>
                                                                            <SpaceSkeleton />
                                                                            <SpaceSkeleton />
                                                                            <SpaceSkeleton />
                                                                        </div>
                                                                        :
                                                                        <div className="m-4">
                                                                            <Typography variant="h5" className='columnHeading mb-3'>{this.labels.funcAdvancedBookingsSpace_L} {this.state.userName} </Typography>
                                                                            <hr />
                                                                            <Typography variant="h5" className='columnHeading mb-3'>{this.labels.HubLabelSelectASpace}</Typography>
                                                                            <Typography variant="body2" className='columnSubheading mb-3'>{this.labels.HubLabelSelectASpaceSubheading}</Typography>
                                                                            {
                                                                                this.state.availableSpaces?.length > 0 ?
                                                                                    <>
                                                                                        {
                                                                                            this.state.availableSpaces.map((space, index) =>
                                                                                            {
                                                                                                return (
                                                                                                    <div key={space.spaceId} id={`space-card-${space.spaceId}`} onClick={() => this.spaceSelected(space)} onKeyDown={(e) => e.keyCode == 32 ? this.spaceSelected(space) : {}} tabIndex={0} role="presentation" aria-label={`Space option: ${space.spaceName}`}>
                                                                                                        <RecurringSpaceCard
                                                                                                            {...space}
                                                                                                            hideDateTimeRange={this.state.bookingType == 'multiple'}
                                                                                                            selected={this.state.selectedSpace.spaceId == space.spaceId}
                                                                                                            bestFit={false} //set to false until paginated api results allows ordering by most available space
                                                                                                            buildingId={this.state.selectedBuildingOption}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        {
                                                                                            this.state.skipToken && this.state.skipToken.length > 0 &&
                                                                                            <div style={{ position: 'relative', padding: '20px' }}>
                                                                                                <Spinner />
                                                                                            </div>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    this.getResultsColumnMessage()
                                                                            }
                                                                        </div>
                                                                }
                                                            </Card>
                                                        </div>
                                                        :
                                                        <div style={{ border: '1px dashed darkgrey', borderRadius: '8px', height: 'calc(100vh - 196px)', textAlign: 'center' }}>
                                                            <div style={{ position: 'relative', top: '42%', color: 'darkgrey', fontSize: '80px' }}>2</div>
                                                        </div>
                                                }
                                            </div>
                                        </Grid>
                                        <Grid xs={4} minWidth={370}>
                                            <div id="column3" aria-label="confirm booking column" className="m-3">
                                                {/* Column 3 - Confirm booking */}
                                                {
                                                    this.state.showConfirmBookingColumn ?
                                                        <div>
                                                            <Card style={{ borderRadius: '8px', marginBottom: '20px', overflow: 'auto' }}>
                                                                {
                                                                    this.state.isLinkedSpace && this.state.showBookingOptionsCard && !this.state.searchedRecurring &&
                                                                    <div>
                                                                        <div className='columnHeading m-4'>{this.labels.HubLabelConfirmBookingDetails}</div>
                                                                        <Accordion
                                                                            style={{ margin: '0px' }}
                                                                            expanded={this.state.accordionBookingOptionsExpanded}
                                                                            onChange={() => this.setState({ accordionBookingOptionsExpanded: !this.state.accordionBookingOptionsExpanded })}
                                                                        >
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1bh-content"
                                                                                id="panel1bh-header"
                                                                            >
                                                                                <div style={{ width: '100%', marginTop: '5px', marginLeft: '7px' }}>
                                                                                    <div style={{ fontFamily: 'Source Sans Pro', fontSize: '20px' }}>{this.labels.funcBookingCreateBookingOptions_L}</div>
                                                                                </div>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <div className="mr-4 ml-4">
                                                                                    <div className='columnSubheading mb-3'>{this.labels.funcBookingCreateChooseLayout_D}</div>
                                                                                    <div className="d-flex mb-1">
                                                                                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', borderRadius: '8px', border: '1px solid #DFE2E7', width: '100%' }}>
                                                                                            <div className="setupResetTimeCard">
                                                                                                <div>
                                                                                                    <div className="TimeCardHeader">
                                                                                                        {this.labels.dataBookingCreateSetup_S}
                                                                                                    </div>
                                                                                                    <div className="TimeCardText">
                                                                                                        {this.state.selectedSeatingArrangement.style != '' ? DateTime.fromISO(this.state.searchedFromTime).minus({ minutes: this.state.selectedSeatingArrangement.setup }).toLocaleString(DateTime.TIME_24_SIMPLE) : '-'}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="bookingTimeCard" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                                                <div>
                                                                                                    <div className="TimeCardHeader">
                                                                                                        {this.labels.HubLabelStart}
                                                                                                    </div>
                                                                                                    <div className="TimeCardText">
                                                                                                        {DateTime.fromISO(this.state.searchedFromTime).toLocaleString(DateTime.TIME_24_SIMPLE)}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <div className="TimeCardHeader">
                                                                                                        {this.labels.HubLabelEnd}
                                                                                                    </div>
                                                                                                    <div className="TimeCardText">
                                                                                                        {DateTime.fromISO(this.state.searchedToTime).toLocaleString(DateTime.TIME_24_SIMPLE)}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="setupResetTimeCard">
                                                                                                <div>
                                                                                                    <div className="TimeCardHeader">
                                                                                                        {this.labels.dataBookingCreateReset_S}
                                                                                                    </div>
                                                                                                    <div className="TimeCardText">
                                                                                                        {this.state.selectedSeatingArrangement.style != '' ? DateTime.fromISO(this.state.searchedToTime).plus({ minutes: this.state.selectedSeatingArrangement.breakdown }).toLocaleString(DateTime.TIME_24_SIMPLE) : '-'}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {this.state.showSpaceLayout && (
                                                                                        <>
                                                                                            <Button sx={{ marginTop: '2%', justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize' }} fullWidth onClick={() => this.setState({ showLayoutSeatingModal: true })}>
                                                                                                <div className="icon-text-inline pl-0">
                                                                                                    <div style={{ fontSize: '26px', height: '19px', marginTop: '-11px' }}>
                                                                                                        <IbssSvgIcon fontSize='inherit' className="mr-2" sx={{ width: '18px', marginRight: '12px', color: (theme) => theme.palette.text.primary }}>
                                                                                                            {Icons.SpaceLayoutIcon}
                                                                                                        </IbssSvgIcon>
                                                                                                    </div>
                                                                                                    <span style={{ marginLeft: '-3px' }} className="space-text-item col-text">{this.labels.HubLabelLinkedSpaces}</span>
                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <span className="space-text-item mr-3">{this.state.selectedLayoutName}</span>
                                                                                                    <IbssSvgIcon fontSize='medium' className="mr-2" sx={{ color: (theme) => theme.palette.text.primary }}>
                                                                                                        {Icons.RightArrowIcon}
                                                                                                    </IbssSvgIcon>
                                                                                                </div>
                                                                                            </Button>
                                                                                            <hr />
                                                                                        </>
                                                                                    )}

                                                                                    {this.state.showSpaceArrangement && (
                                                                                        <>
                                                                                            <Button sx={{ justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize' }} fullWidth onClick={() => this.setState({ showLayoutSeatingModal: true })}>
                                                                                                <div className="icon-text-inline pl-0">
                                                                                                    <div style={{ fontSize: '26px', height: '19px', marginTop: '-11px' }}>
                                                                                                        <IbssSvgIcon fontSize='inherit' className="mr-2" sx={{ width: '18px', marginRight: '12px', color: (theme) => theme.palette.text.primary }}>
                                                                                                            {Icons.SeatingArrangementIcon}
                                                                                                        </IbssSvgIcon>
                                                                                                    </div>
                                                                                                    <span style={{ marginLeft: '-3px' }} className="space-text-item col-text">{this.labels.HubLabelLayouts}<span className="text-danger">*</span> </span>
                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <span className="space-text-item mr-3">{this.state.selectedSeatingArrangement?.style}</span>
                                                                                                    <IbssSvgIcon fontSize='medium' className="mr-2" sx={{ color: (theme) => theme.palette.text.primary }}>
                                                                                                        {Icons.RightArrowIcon}
                                                                                                    </IbssSvgIcon>
                                                                                                </div>
                                                                                            </Button>
                                                                                            <hr />
                                                                                        </>
                                                                                    )}
                                                                                    <div className="mr-4 ml-4">
                                                                                        <IbssButton
                                                                                            disabled={disableCheckAvailabilityBtn}
                                                                                            fullWidth
                                                                                            variant="contained"
                                                                                            onClick={() => this.checkLinkedSpaceAvailability()}
                                                                                        >
                                                                                            {
                                                                                                this.state.spaceAvailabilityStatus == 'error' &&
                                                                                                <IbssSvgIcon fontSize='medium' className="mr-2" sx={{ color: (theme) => theme.palette.text.primary }}>
                                                                                                    <CancelIcon color="error" />
                                                                                                </IbssSvgIcon>
                                                                                            }
                                                                                            {
                                                                                                this.state.spaceAvailabilityStatus == 'success' &&
                                                                                                <IbssSvgIcon fontSize='medium' className="mr-2" sx={{ color: (theme) => theme.palette.text.primary }}>
                                                                                                    <CheckCircleIcon color="success" />
                                                                                                </IbssSvgIcon>
                                                                                            }
                                                                                            {this.labels.funcBookingCreateCheckAvailability_S}
                                                                                        </IbssButton>
                                                                                    </div>
                                                                                    <SpaceArrangementsDialog
                                                                                        spaceId={this.state.selectedLayout}
                                                                                        modalOpen={this.state.showLayoutSeatingModal}
                                                                                        spaceSetup={this.state.selectedSpace.spaceSetup}
                                                                                        onSave={(seating: ISpaceArrangement, layout: string, layoutName: string) => this.updateLayoutSeating(seating, layout, layoutName)}
                                                                                        onClose={() => this.setState({ showLayoutSeatingModal: false })} spaceLayout={this.state.selectedSpace.spaceLayout}
                                                                                        selectedLayoutStyle={this.state.selectedSeatingArrangement?.style}
                                                                                    />
                                                                                </div>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.isLinkedSpace && !this.state.searchedRecurring && this.state.bookingType != 'multiple' ?
                                                                        <Accordion
                                                                            style={{ margin: '0px' }}
                                                                            expanded={this.state.accordionBookingDetailsExpanded && this.state.spaceAvailabilityStatus == 'success'}
                                                                            onChange={() => this.setState({ accordionBookingDetailsExpanded: !this.state.accordionBookingDetailsExpanded })}
                                                                        >
                                                                            <AccordionSummary
                                                                                expandIcon={
                                                                                    this.state.spaceAvailabilityStatus == 'success' ?
                                                                                        <ExpandMoreIcon />
                                                                                        :
                                                                                        <IbssSvgIcon sx={{ marginTop: '3px' }} fontSize='medium'>
                                                                                            {Icons.LockIcon}
                                                                                        </IbssSvgIcon>
                                                                                }
                                                                                aria-controls="panel1bh-content"
                                                                                id="panel1bh-header"
                                                                            >
                                                                                <div style={{ width: '100%', marginTop: '5px', marginLeft: '7px' }}>
                                                                                    <div style={{ fontFamily: 'Source Sans Pro', fontSize: '20px' }}>{this.labels.HubLabelBookingDetails}</div>
                                                                                </div>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <div>
                                                                                    {bookingDetailsForm}
                                                                                </div>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                        :
                                                                        <div>
                                                                            <div className='columnHeading m-4'>{this.labels.HubLabelBookingDetails}</div>
                                                                            {bookingDetailsForm}
                                                                        </div>
                                                                }
                                                            </Card>
                                                        </div>
                                                        :
                                                        this.getCreateBookingColumnMessage()
                                                }
                                                <IbssDialog
                                                    aria-modal="true"
                                                    aria-label="setup reset unavailable modal"
                                                    open={this.state.showSetupResetUnavailableModal}
                                                    fullWidth
                                                    header=
                                                    {
                                                        <>
                                                            <label className="modal-heading">{this.labels.funcBookingCreateSetupReset_L}</label>
                                                        </>
                                                    }
                                                    dialogContent=
                                                    {
                                                        <div>
                                                            <div>
                                                                {this.labels.funcBookingCreateSetupError_D}
                                                            </div>
                                                            <br />
                                                            <div>
                                                                {this.labels.funcBookingCreateSetupErrorSuggestion_D}
                                                            </div>
                                                        </div>
                                                    }
                                                    footer=
                                                    {
                                                        <IbssButton
                                                            disabled={disableCheckAvailabilityBtn}
                                                            variant="contained"
                                                            onClick={() => this.setState({ showSetupResetUnavailableModal: false })}
                                                        >
                                                            {this.labels.HubLabelOk}
                                                        </IbssButton>
                                                    }
                                                    onClose={() => this.setState({ showSetupResetUnavailableModal: false })}
                                                />
                                            </div>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Modal show={this.state.showAlternativeSpaceModal != ''} onHide={() => this.setState({ showAlternativeSpaceModal: '', alternativeSpacesSkipToken: null })}>
                                    <div aria-label="alternative space modal">
                                        <Modal.Header>
                                            <Modal.Title>{this.labels.HubLabelPickAlternativeSpace}</Modal.Title>
                                            <button type="button" className="close" onClick={() => this.setState({ showAlternativeSpaceModal: '', alternativeSpacesSkipToken: null })} aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </Modal.Header>
                                        <div style={{ paddingTop: '5px', paddingLeft: '38px', paddingRight: '38px' }} className="mb-4">
                                            {this.labels.HubLabelAlternativeSpaceText}
                                            <br />
                                            <br />
                                            {this.labels.HubLabelDate}: {DateTime.fromISO(this.state.showAlternativeSpaceModal).toLocaleDateString()} | {this.labels.HubLabelTime}: {DateTime.fromISO(this.state.selectedAlternativeDate.from).toLocaleTimeString()} - {DateTime.fromISO(this.state.selectedAlternativeDate.to).toLocaleTimeString()} | {this.labels.HubLabelCapacity}: {this.state.capacity} |
                                        </div>
                                        <div>
                                            {
                                                this.state.fetchingAlternativeSpaces && !this.state.lazyLoadingAlternativeSpaces ?
                                                    <div style={{ height: '200px' }}>
                                                        <Spinner />
                                                    </div>
                                                    :
                                                    <div onScroll={() => this.alternativeSpaceSearchScroll()} ref={this.alternativeSpaceResultsContainer} style={{ display: 'flex', overflow: 'auto', marginLeft: '37px' }}>
                                                        {
                                                            alternativeSpaces.length == 0 ?
                                                                <div style={{ width: '100%', textAlign: 'center', marginBottom: '10px' }}>
                                                                    <img className="mt-2" alt="NoSpacesImage" src="/images/NoSpaceSearchReults.svg" />
                                                                    <div className="noResultsHeading">{this.labels.HubLabelNoSpaceOptions}</div>
                                                                    <div className="noResultsSubText mt-1">{this.labels.HubLabelNoSpaceOptionsSubText}</div>
                                                                </div>
                                                                :
                                                                alternativeSpaces.map(space =>
                                                                {
                                                                    return (
                                                                        <div>
                                                                            <SpaceCard
                                                                                imageUrl={space.imageUrl}
                                                                                nodeId={this.state.selectedBuildingOption}
                                                                                floorName={space.floor}
                                                                                zone={space.zone}
                                                                                spaceId={space.spaceId}
                                                                                spaceName={space.spaceName}
                                                                                spaceType={space.spaceType}
                                                                                spaceTypeLabel={space.spaceTypeLabel}
                                                                                spaceCapacity={parseInt(space.capacity)}
                                                                                requiresCatering={space.cateringAvailable}
                                                                                requiresAV={space.requiresAV}
                                                                                requiresHearing={space.hearingAidAvailable}
                                                                                requiresPresentation={space.presentationAidAvailable}
                                                                                spaceSetup={space.spaceSetup}
                                                                                showBookingOwner={false}
                                                                                bookingStart={DateTime.fromISO(this.state.startDate.toLocaleString())}
                                                                                bookingEnd={DateTime.fromISO(this.state.endDate.toLocaleString())}
                                                                                bookingOwnerName=""
                                                                                bookingShareLocation={false}
                                                                                showNoBookingMessage={false}
                                                                                onClick={() => this.setState({ alternativeSpaceHighlighted: space.spaceId })}
                                                                                border={true}
                                                                                selected={space.spaceId == this.state.alternativeSpaceHighlighted}
                                                                            />
                                                                        </div>
                                                                    )
                                                                })
                                                        }
                                                        {
                                                            this.state.alternativeSpacesSkipToken && this.state.alternativeSpacesSkipToken.length > 0 &&
                                                            <div style={{ position: 'relative', padding: '20px', marginRight: '20px' }}>
                                                                <Spinner />
                                                            </div>
                                                        }
                                                    </div>
                                            }
                                        </div>
                                        <Modal.Footer>
                                            <div style={{ textAlign: 'center' }}>
                                                <IbssButton
                                                    style={{ height: '45px', minWidth: '100px' }}
                                                    variant="contained"
                                                    disabled={alternativeSpaces.length == 0 ? false : this.state.alternativeSpaceHighlighted == ''}
                                                    onClick={() => alternativeSpaces.length == 0 ? this.setState({ showAlternativeSpaceModal: '' }) : this.alternativeSpaceSelected()}
                                                >
                                                    {alternativeSpaces.length == 0 ? this.labels.HubLabelSkipOccurrence : this.labels.HubLabelUseSpaceSelected}
                                                </IbssButton>
                                            </div>
                                        </Modal.Footer>
                                    </div>
                                </Modal>
                            </div>
                        }
                    </div>
                </div>
            </main>
        );
    }
}

export default CreateRecurringBookings;

export class SpaceSkeleton extends Component
{
    public render(): JSX.Element
    {
        return (
            <Card className="mb-1">
                <div>
                    <Skeleton variant="rectangular" width={'100%'} height={130} />
                    <div style={{ width: '100%' }}>
                        <Skeleton sx={{ margin: '15px', marginBottom: '10px' }} variant="rectangular" width={'70%'} height={10} />
                        <Skeleton sx={{ margin: '15px' }} variant="rectangular" width={'50%'} height={10} />
                    </div>
                </div>
            </Card>
        )
    }
}

export interface IState
{
    selectedBuildingOption: number;
    buildingOptions: Array<IListOption<number>>;
    selectedHoursLength: number;
    selectedFloor: number;
    floorTypeOptions: Array<IListOption<number>>;
    zoneOptions: IZoneOptions[];
    selectedZone: string;
    workTypeOptions: Array<IListOption<string>>;
    selectedWorkType: string;
    spaceTypeOptions: Array<IListOption<string>>;
    selectedSpaceType: string;
    capacity: string;
    fromTime: DateTime;
    toTime: DateTime;
    startDate: DateTime;
    recurrencePattern: string;
    endType: string;
    endDate: DateTime;
    numberOfOccurences: number;
    daysSelected: ISelectedWeekDays;
    dailyRecurrenceFrequency: string;
    dailyRecurrenceFrequencyValue: number;
    monthlyRecurrenceFrequencyOption: string;
    monthlyRecurrenceDayValue: number;
    monthlyRecurrenceMonthValue: number;
    monthlyRecurrenceFrequencyDropdownValue: string;
    monthlyRecurrenceDayDropdownValue: string;
    monthlyRecurrenceEveryMonthValue: number;
    showMoreSearchOptions: boolean;
    bookSoonAsPossibleChecked: boolean;
    avChecked: boolean;
    cateringChecked: boolean;
    hearingAidChecked: boolean;
    presentationAidChecked: boolean;
    showSearchResultsColumn: boolean;
    fetchingSpaces: boolean;
    availableSpaces: IAvailableSpace[];
    mapViewSpaces: IAvailableSpace[];
    selectedSpace: IAvailableSpace;
    showConfirmBookingColumn: boolean;
    bookingName: string;
    bookingDescription: string;
    onlineMeetingLink: boolean;
    recurrenceOptions: IRecurrenceOption[];
    showAlternativeSpaceModal: string;
    alternativeSpaceHighlighted: string;
    spaces: Space[];
    bookingParties: Attendee[];
    bookingPartyVisitors: Attendee[];
    costCodes: CostCodeWithAllocation[];
    onBehalfOf: string;
    useOnBehalfOfCostCodes: number;
    onBehalfOfData: IOnBehalfOf;
    availableAlternativeSpaces: IAvailableSpace[];
    fetchingAlternativeSpaces: boolean;
    selectedSpaceConfig: ISelectedSpaceConfig;
    userEndOfDayPref: string;
    buildingSelectError: boolean;
    startGreaterThanRecurringEnd: boolean;
    noEndTypeSelected: boolean;
    spaceTypeError: boolean;
    noRecurrencePatternSelected: boolean;
    noDailyRecurrenceFrequencySelected: boolean;
    weeklyRecurrenceError: boolean;
    noMonthlyRecurrenceOptionSelected: boolean;
    noMonthlyRecurrenceFrequencyValueSelected: boolean;
    noMonthlyRecurrenceDayValueSelected: boolean;
    monthlyRecurrenceDayValueError: boolean;
    monthlyRecurrenceMonthValueError: boolean;
    monthlyRecurrenceEveryMonthValueError: boolean;
    selectedWorkTypeError: boolean;
    mapUrls: IMapUrl[];
    showMap: boolean;
    mapFailedToLoad: boolean;
    loadMap: Guid;
    userHasSearched: boolean;
    searchedFromTime: string;
    searchedToTime: string;
    searchedStartDate: DateTime;
    searchedRecurring: boolean
    creatingBookings: boolean;
    linkedSpacesSelected: boolean;
    showLayoutSeatingModal: boolean;
    showSpaceArrangement: boolean;
    showSpaceLayout: boolean;
    selectedSeatingArrangement: ISpaceArrangement;
    selectedLayout: string;
    isLinkedSpace: boolean;
    spaceAvailabilityStatus: string;
    showSetupResetUnavailableModal: boolean;
    checkingSpaceAvailability: boolean;
    showBookingOptionsCard: boolean
    selectedLayoutName: string;
    autoCheckin: boolean;
    accordionBookingOptionsExpanded: boolean;
    accordionBookingDetailsExpanded: boolean;
    accordionSelectedSpaceDetailsExpanded: boolean;
    selectedSpaceBookingPolicy: ISpacePolicy;
    fetchingSpacePolicy: boolean;
    skipToken: null | string;
    alternativeSpacesSkipToken: null | string;
    lazyLoading: boolean;
    lazyLoadingAlternativeSpaces: boolean;
    selectedAlternativeDate: { from: string, to: string };
    showAddRecurrenceSwitch: boolean;
    showLinkedSpaceSwitch: boolean;
    showBookAsapSwitch: boolean;
    showBookMultipleSwitch: boolean;
    showCapacityField: boolean;
    blockBookingDates: IBlockBooking[];
    showMultipleDateSelectModal: boolean;
    creatingSeries: boolean;
    seriesBookingDetails: ISeriesBookingDetails;
    successfulSeriesBookings: ISeriesBooking[];
    seriesCreationCompleted: boolean;
    failedSeriesBookings: ISeriesBooking[];
    showSingleBookingConfirmationModal: boolean;
    createdBookingResponse: ICreatedBookingResponse;
    delegatedBy: IDelegate[];
    favourites: IFavouriteUser[];
    showLocationFormFields: boolean;
    bookingType: 'singular' | 'multiple' | 'recurring';
    selectedSpaceInfo: ISpaceInfoView[];
    fetchingSpaceInfo: boolean;
    ownerTypeOptions: InputOption[],
    selectedOwnerType: string;
    showAddVisitorModal: boolean;
    userName: string;
    isVisitorSubmitted: boolean;
    bookingAttendance: number;
    selectedOwner: IDelegate | null;
    displayMapFloorDropdown: boolean;
    selectedDates: ISelectedDates | null;
    hostEmail: string;
    visitorPayload: string | null;
    userBypassingPolicies: boolean;
    bookingPolicies: IBookingPolicy[];
}

export interface IZoneOptions
{
    label: string;
    value: string;
    zoneId: number
}

export interface ISpacePolicy
{
    policyId: string;
    description: string;
    setup: number;
    teardown: number;
    bookingAttendanceControl: string;
}

export interface ICreatedBookingResponse
{
    id: string;
    start: string;
    end: string;
}

export interface IListOption<TValue>
{
    label: string;
    value: TValue;
}

export interface ISelectedWeekDays
{
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
}

export interface IOccurrenceDates
{
    start: string;
    end: string;
}

export interface IBlockBooking
{
    from: DateTime;
    to: DateTime;
}

export interface IAvailableSpace
{
    nodeId: number;
    spaceId: string;
    imageUrl: string;
    spaceName: string;
    spaceType: string;
    capacity: string;
    floor: string;
    zone: string;
    spaceLayout: string;
    dateRange: string;
    timeRange: string;
    requestedOccurrence: string | null;
    availableOccurrences: string | null;
    cateringAvailable: number;
    presentationAidAvailable: number;
    hearingAidAvailable: number;
    requiresAV: number;
    spaceSetup: number;
    spaceTypeLabel: string;
    datesFound: string[];
    recurringSpace: boolean;
    spaceCustomInfo: string;
    bookingPolicyId: string;
    meetingLinkAvailable: number;
}

export interface IRecurrenceOption
{
    from: string;
    to: string;
    selected: boolean;
    alternativeSpace: string;
}

export interface ISelectedSpaceConfig
{
    showAV: boolean;
    showCatering: boolean;
    showHearingAid: boolean;
    showNoise: boolean;
    showPresentationAid: boolean;
    showTemperature: boolean;
    showLinkedSpace: boolean;
}

export interface ISpaceInfoView
{
    imageUrl: string,
    title: string,
    actionUrl: string,
    availableFrom: DateTime,
    expiryDate: DateTime,
    spaceInfoMessage: string,
}
