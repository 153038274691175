import { Component } from "react";
import { Card } from "react-bootstrap";
import { appContext } from "../../../AppContext";
import { Constants } from "../../../Common/Constants";
import "./RecurringSpaceCard.scss";
import { Icons } from '../../../Common/AllsvgIcons';
import IbssSvgIcon from "../../Icons/SvgIcon/IbssSvgIcon";
import { Box, Grid, SvgIcon, Typography } from "@mui/material";
import IbssIconButton from "../../Buttons/IconButton/IbssIconButton";
import InfoIcon from "../../Icons/InfoIcon";
import SpaceInfoDialogWithCustomInfo from "../../Dialogs/SpaceInfoDialogWithCustomInfo/SpaceInfoDialogWithCustomInfo";

export default class SpaceCard extends Component<IProps, IState>
{
    private labels = appContext().labels;
    private appState = appContext().state;

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            lightModeTheme: false,
            showSpaceInfoDialog: false
        };
    };

    public componentWillMount(): void
    {
        this.appState.autoMap(this, i => ({ lightModeTheme: i.lightModeTheme }));
    }

    private async spaceInfoClicked(): Promise<void>
    {
        await this.setState({ showSpaceInfoDialog: true });
    }

    public render(): JSX.Element
    {
        let props = this.props;

        return (
            <div className={`mb-1 recurring-space-card ${props.selected ? 'card-selected' : ''}`} onClick={() => { }}>
                <Card className={`${props.capacity == '1' ? 'content-card-desk' : 'content-card'}`}>
                    <div style={{ position: 'relative' }}>
                        {
                            props.bestFit && props.recurringSpace &&
                            <Typography className='best-fit-label mb-3'>{this.labels.HubLabelBestFit}</Typography>
                        }
                        <Card.Img className="card-image" style={{ borderRadius: props.selected ? '0px' : '5px 5px 0px 0px' }} src={props.imageUrl ? props.imageUrl : Constants.imagePaths.get(props.spaceType)} />
                        <Box p={2}>
                            <Card.Title className="space-card__type">{props.spaceTypeLabel ? props.spaceTypeLabel : props.spaceType}</Card.Title>
                            <Box display={'flex'} justifyContent={'space-between'} mt={1}>
                                <Typography variant="h6" className="space-label">{props.spaceName ? props.spaceName : props.spaceType}</Typography>
                                <Box style={{}} className="align-icons-text">
                                    <span className="recurring-icon"><img src="/images/Sidebar_Icons/Active_icons/Attendee Icon.svg" /></span>
                                    <Typography style={{ alignSelf: 'center' }}>{props.capacity}</Typography>
                                </Box>
                            </Box>
                            <Box className="space-info">
                                <Grid container mt={1}>
                                    <Grid xs={6} className="align-icons-text">
                                        <Typography display={'flex'} alignItems={'center'} className="headings">
                                            <IbssSvgIcon fontSize="inherit" className="mr-10">
                                                {Icons.FloorIcon}
                                            </IbssSvgIcon><span className="mt-3px">{props.floor}</span></Typography>
                                    </Grid>
                                    <Grid xs={6} className="align-icons-text">
                                        <Typography display={'flex'} alignItems={'center'} className="headings">
                                            <IbssSvgIcon fontSize="inherit" className="mr-10">
                                                {Icons.ZoneIcon}
                                            </IbssSvgIcon><span className="mt-3px">{props.zone}</span></Typography>
                                    </Grid>
                                    {!this.props.hideDateTimeRange &&
                                        <>
                                            <Grid xs={6} className="align-icons-text" mt={2}>
                                                <Typography display={'flex'} alignItems={'center'} className="headings">
                                                    <IbssSvgIcon fontSize="inherit" className="mr-10">
                                                        {Icons.calenderIcon}
                                                    </IbssSvgIcon>{props.dateRange}</Typography>
                                            </Grid>
                                            <Grid xs={6} className="align-icons-text" mt={2}>
                                                <Typography display={'flex'} alignItems={'center'} className="headings">
                                                    <IbssSvgIcon fontSize="small">
                                                        {Icons.TimeIcon}
                                                    </IbssSvgIcon>{props.timeRange}</Typography>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                                <Box className="row pb-1" >
                                    <div className="col-12 mt-8 d-flex" style={{ padding: 0, paddingBottom: '5px' }}>
                                        {props.cateringAvailable === 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Restaurant (Fill).svg`} alt="icon" /></div>
                                            </div>
                                        )}

                                        {props.requiresAV === 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/ITAV (Fill).svg`} alt="icon" /></div>
                                            </div>
                                        )}

                                        {props.hearingAidAvailable === 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Hearing Aid (Fill).svg`} alt="icon" /></div>
                                            </div>
                                        )}

                                        {props.presentationAidAvailable === 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Presentation (Fill).svg`} alt="icon" /></div>
                                            </div>
                                        )}

                                        {props.spaceSetup > 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Linked Space (Fill).svg`} alt="icon" /></div>
                                            </div>
                                        )}

                                        {props.spaceSetup >= 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Seating Arrangement.svg`} alt="icon" /></div>
                                            </div>
                                        )}
                                    </div>
                                    <IbssIconButton
                                        aria-label="info"
                                        sx={{ padding: 0, color: '#6e6f77'}}
                                        onClick={() => this.spaceInfoClicked()}>
                                        <SvgIcon fontSize={'small'} component={InfoIcon} color='inherit'></SvgIcon>
                                    </IbssIconButton>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                </Card>
                <SpaceInfoDialogWithCustomInfo
                    isOpen={this.state.showSpaceInfoDialog}
                    onClose={() => this.setState({ showSpaceInfoDialog: false })}
                    spaceId={props.spaceId}
                    nodeId={props.nodeId ?? 0}
                    buildingId={props.buildingId} 
                 />
                {
                    props.recurringSpace &&
                    <div className={`d-flex ${props.capacity == '1' ? '' : 'additional-info'}`} style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ marginRight: '-13px', paddingTop: '11px' }}>
                            <IbssSvgIcon className="mr-2">
                                {Icons.RepeatIcon}
                            </IbssSvgIcon>
                        </div>
                        <div className="mr-3 availability-text">
                            {this.labels.funcAdvancedBookingAvailability_S}
                        </div>
                        <div className="ml-3 occurences">{props.availableOccurrences}/{props.requestedOccurrence} {this.labels.HubLabelOccurrences}</div>
                    </div>
                }
            </div>
        );
    }
}

export interface IProps
{
    imageUrl: string;
    spaceName: string;
    spaceType: string;
    capacity: string;
    floor: string;
    zone: string;
    spaceLayout: string;
    dateRange: string;
    timeRange: string;
    requestedOccurrence: string | null;
    availableOccurrences: string | null;
    selected: boolean;
    bestFit: boolean;
    cateringAvailable: number;
    presentationAidAvailable: number;
    hearingAidAvailable: number;
    requiresAV: number;
    spaceSetup: number;
    recurringSpace: boolean;
    spaceTypeLabel: string;
    hideDateTimeRange: boolean;
    spaceId: string;
    nodeId: number;
    buildingId: number;
}

export interface IState
{
    lightModeTheme: boolean;
    showSpaceInfoDialog: boolean;
}
